import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Booth } from '../../../swagger/models/booth';
import { BoothService } from '../../../swagger/services/booth.service';

@Component({
  selector: 'app-boothes-section',
  templateUrl: './boothes-section.component.html',
  styleUrls: ['./boothes-section.component.scss'],
})
export class BoothesSectionComponent implements OnInit {
  boothes$: Observable<Booth[]>;
  themeId = environment.themeId;
  constructor(private boothService: BoothService) {}

  ngOnInit(): void {
    this.boothes$ = this.boothService.boothListList({ theme: this.themeId }).pipe(
      map((results) =>
        results.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
      )
    );
  }
}
