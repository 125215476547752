/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventType } from '../models/event-type';
@Injectable({
  providedIn: 'root',
})
class EventTypeService extends __BaseService {
  static readonly eventTypeListListPath = '/event_type/list';
  static readonly eventTypeListCreatePath = '/event_type/list';
  static readonly eventTypeReadPath = '/event_type/{id}';
  static readonly eventTypeUpdatePath = '/event_type/{id}';
  static readonly eventTypePartialUpdatePath = '/event_type/{id}';
  static readonly eventTypeDeletePath = '/event_type/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EventTypeService.EventTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  eventTypeListListResponse(params: EventTypeService.EventTypeListListParams): __Observable<__StrictHttpResponse<Array<EventType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventType>>;
      })
    );
  }
  /**
   * @param params The `EventTypeService.EventTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  eventTypeListList(params: EventTypeService.EventTypeListListParams): __Observable<Array<EventType>> {
    return this.eventTypeListListResponse(params).pipe(
      __map(_r => _r.body as Array<EventType>)
    );
  }

  /**
   * @param data undefined
   */
  eventTypeListCreateResponse(data: EventType): __Observable<__StrictHttpResponse<EventType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventType>;
      })
    );
  }
  /**
   * @param data undefined
   */
  eventTypeListCreate(data: EventType): __Observable<EventType> {
    return this.eventTypeListCreateResponse(data).pipe(
      __map(_r => _r.body as EventType)
    );
  }

  /**
   * @param id undefined
   */
  eventTypeReadResponse(id: string): __Observable<__StrictHttpResponse<EventType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventType>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventTypeRead(id: string): __Observable<EventType> {
    return this.eventTypeReadResponse(id).pipe(
      __map(_r => _r.body as EventType)
    );
  }

  /**
   * @param params The `EventTypeService.EventTypeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventTypeUpdateResponse(params: EventTypeService.EventTypeUpdateParams): __Observable<__StrictHttpResponse<EventType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/event_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventType>;
      })
    );
  }
  /**
   * @param params The `EventTypeService.EventTypeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventTypeUpdate(params: EventTypeService.EventTypeUpdateParams): __Observable<EventType> {
    return this.eventTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as EventType)
    );
  }

  /**
   * @param params The `EventTypeService.EventTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventTypePartialUpdateResponse(params: EventTypeService.EventTypePartialUpdateParams): __Observable<__StrictHttpResponse<EventType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/event_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventType>;
      })
    );
  }
  /**
   * @param params The `EventTypeService.EventTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventTypePartialUpdate(params: EventTypeService.EventTypePartialUpdateParams): __Observable<EventType> {
    return this.eventTypePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as EventType)
    );
  }

  /**
   * @param id undefined
   */
  eventTypeDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/event_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventTypeDelete(id: string): __Observable<null> {
    return this.eventTypeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EventTypeService {

  /**
   * Parameters for eventTypeListList
   */
  export interface EventTypeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for eventTypeUpdate
   */
  export interface EventTypeUpdateParams {
    id: string;
    data: EventType;
  }

  /**
   * Parameters for eventTypePartialUpdate
   */
  export interface EventTypePartialUpdateParams {
    id: string;
    data: EventType;
  }
}

export { EventTypeService }
