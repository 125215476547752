<div class="location" id="location">
    <div class="location__container container">
        <h2 class="location__header location__title">Место <span>проведения</span></h2>
        <div class="location__text">
            <div>МОСКВА, ЛЕНИНСКАЯ СЛОБОДА&nbsp;УЛ., 26С11</div>
            <div>БЛИЖАЙШЕЕ МЕТРО: АВТОЗАВОДСКАЯ</div>
        </div>
        <div class="location__group">
            <div class="location__column">
                <mat-tab-group [selectedIndex]="activeTab" class="location__map-toggles" animationDuration="0ms"
                    mat-align-tabs="center" md-no-ink-bar>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="location__toggle button button--magenta">
                                <span class="text">
                                    НА АВТОМОБИЛЕ
                                </span>
                            </div>
                        </ng-template>
                        <div class="location__map" id="map">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A26196d2075d0337585782261380189345e1e878a75ba96cd0e08f542d1272b4d&amp;source=constructor"
                                width="100%" height="545" frameborder="0"></iframe>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="location__toggle button button--darkmagenta">
                                <span class="text">
                                    ОБЩЕСТВЕННЫМ ТРАНСПОРТОМ
                                </span>
                            </div>
                        </ng-template>
                        <div class="location__map">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aadaabd2ab5d448e115f66af7fdc5875f2819ef9a7599f38f72cb3cab9619ba70&amp;source=constructor"
                                width="100%" height="545" frameborder="0"></iframe>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>