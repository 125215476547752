<section class="how-it-was" id="how-it-was">
    <div class="container">
        <div class="how-it-was-container">
            <h2 class="section-title">Как это было</h2>
            <div class="buttons-box">
                <div class="logo-group">
                    <div class="logo"></div>
                    <p>19-22 октября 2020</p>
                </div>
                <div class="buttons-group">
                    <a [routerLink]="['/']" href="#experts" pageScroll>эксперты</a>
                    <a [routerLink]="['/']" href="#partners" pageScroll>партнеры</a>
                    <a [routerLink]="['/photo']">фото 2020</a>
                    <a href="https://pirexpo.com/events/program/exhibitions%255B%255D=68386f91-e227-11ea-9f01-000c29f15ff6"
                        target="_blank">программа 2020</a>
                </div><!-- buttons-group -->
            </div><!-- buttons-box-->
            <p class="text">На Moscow Cake Show за 4 дня прошло 28 мероприятий по разным направлениям для всестороннего
                развития кондитеров и пекарей. Ведущие эксперты, выступившие в качестве спикеров, профессионалы своего
                дела, рассказали о том, как и зачем создавать свой бренд кондитеру, раскрыли секреты вкуса и раздали
                более 40 рецептов крутых десертов и тортов участникам мастер-классов. Приятно осознавать, что такое
                количество профессионалов не оставляют без внимания такую важную, особенно в современных условиях, тему
                как ХАССП, а на интерактивных мероприятиях участники вместе с экспертами создавали цветы в разных
                техниках и пряники, которые смогли забрать с собой, и многое другое.<br><br>

                В этом году рамках экспозиции компаний для кондитеров были представлены натуральные и пищевые красители,
                сублимированные фрукты и фруктовое пюре, инвентарь и одноразовая упаковка, а также уникальная посуда и
                оборудование, в том числе для кондитерских и пекарских производств.</p>
        </div><!-- container-->
    </div><!-- how-it-was-container-->
</section>