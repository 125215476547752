/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExhibitorApplication } from '../models/exhibitor-application';
@Injectable({
  providedIn: 'root',
})
class ExhibitorApplicationService extends __BaseService {
  static readonly exhibitorApplicationListListPath = '/exhibitor_application/list';
  static readonly exhibitorApplicationListCreatePath = '/exhibitor_application/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ExhibitorApplicationService.ExhibitorApplicationListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  exhibitorApplicationListListResponse(params: ExhibitorApplicationService.ExhibitorApplicationListListParams): __Observable<__StrictHttpResponse<Array<ExhibitorApplication>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/exhibitor_application/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ExhibitorApplication>>;
      })
    );
  }
  /**
   * @param params The `ExhibitorApplicationService.ExhibitorApplicationListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  exhibitorApplicationListList(params: ExhibitorApplicationService.ExhibitorApplicationListListParams): __Observable<Array<ExhibitorApplication>> {
    return this.exhibitorApplicationListListResponse(params).pipe(
      __map(_r => _r.body as Array<ExhibitorApplication>)
    );
  }

  /**
   * @param data undefined
   */
  exhibitorApplicationListCreateResponse(data: ExhibitorApplication): __Observable<__StrictHttpResponse<ExhibitorApplication>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/exhibitor_application/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExhibitorApplication>;
      })
    );
  }
  /**
   * @param data undefined
   */
  exhibitorApplicationListCreate(data: ExhibitorApplication): __Observable<ExhibitorApplication> {
    return this.exhibitorApplicationListCreateResponse(data).pipe(
      __map(_r => _r.body as ExhibitorApplication)
    );
  }
}

module ExhibitorApplicationService {

  /**
   * Parameters for exhibitorApplicationListList
   */
  export interface ExhibitorApplicationListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }
}

export { ExhibitorApplicationService }
