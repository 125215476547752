/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TicketCode } from '../models/ticket-code';
@Injectable({
  providedIn: 'root',
})
class TicketCodeService extends __BaseService {
  static readonly ticketCodeListListPath = '/ticket_code/list';
  static readonly ticketCodeListCreatePath = '/ticket_code/list';
  static readonly ticketCodeReadPath = '/ticket_code/{id}';
  static readonly ticketCodeUpdatePath = '/ticket_code/{id}';
  static readonly ticketCodePartialUpdatePath = '/ticket_code/{id}';
  static readonly ticketCodeDeletePath = '/ticket_code/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TicketCodeService.TicketCodeListListParams` containing the following parameters:
   *
   * - `usage`: usage
   *
   * - `ticket_type`: ticket_type
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketCodeListListResponse(params: TicketCodeService.TicketCodeListListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<TicketCode>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.usage != null) __params = __params.set('usage', params.usage.toString());
    if (params.ticketType != null) __params = __params.set('ticket_type', params.ticketType.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_code/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<TicketCode>}>;
      })
    );
  }
  /**
   * @param params The `TicketCodeService.TicketCodeListListParams` containing the following parameters:
   *
   * - `usage`: usage
   *
   * - `ticket_type`: ticket_type
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketCodeListList(params: TicketCodeService.TicketCodeListListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<TicketCode>}> {
    return this.ticketCodeListListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<TicketCode>})
    );
  }

  /**
   * @param data undefined
   */
  ticketCodeListCreateResponse(data: TicketCode): __Observable<__StrictHttpResponse<TicketCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket_code/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketCode>;
      })
    );
  }
  /**
   * @param data undefined
   */
  ticketCodeListCreate(data: TicketCode): __Observable<TicketCode> {
    return this.ticketCodeListCreateResponse(data).pipe(
      __map(_r => _r.body as TicketCode)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket code.
   */
  ticketCodeReadResponse(id: number): __Observable<__StrictHttpResponse<TicketCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_code/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketCode>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket code.
   */
  ticketCodeRead(id: number): __Observable<TicketCode> {
    return this.ticketCodeReadResponse(id).pipe(
      __map(_r => _r.body as TicketCode)
    );
  }

  /**
   * @param params The `TicketCodeService.TicketCodeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket code.
   *
   * - `data`:
   */
  ticketCodeUpdateResponse(params: TicketCodeService.TicketCodeUpdateParams): __Observable<__StrictHttpResponse<TicketCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ticket_code/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketCode>;
      })
    );
  }
  /**
   * @param params The `TicketCodeService.TicketCodeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket code.
   *
   * - `data`:
   */
  ticketCodeUpdate(params: TicketCodeService.TicketCodeUpdateParams): __Observable<TicketCode> {
    return this.ticketCodeUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketCode)
    );
  }

  /**
   * @param params The `TicketCodeService.TicketCodePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket code.
   *
   * - `data`:
   */
  ticketCodePartialUpdateResponse(params: TicketCodeService.TicketCodePartialUpdateParams): __Observable<__StrictHttpResponse<TicketCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ticket_code/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketCode>;
      })
    );
  }
  /**
   * @param params The `TicketCodeService.TicketCodePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket code.
   *
   * - `data`:
   */
  ticketCodePartialUpdate(params: TicketCodeService.TicketCodePartialUpdateParams): __Observable<TicketCode> {
    return this.ticketCodePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketCode)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket code.
   */
  ticketCodeDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ticket_code/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket code.
   */
  ticketCodeDelete(id: number): __Observable<null> {
    return this.ticketCodeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TicketCodeService {

  /**
   * Parameters for ticketCodeListList
   */
  export interface TicketCodeListListParams {

    /**
     * usage
     */
    usage?: string;

    /**
     * ticket_type
     */
    ticketType?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for ticketCodeUpdate
   */
  export interface TicketCodeUpdateParams {

    /**
     * A unique integer value identifying this ticket code.
     */
    id: number;
    data: TicketCode;
  }

  /**
   * Parameters for ticketCodePartialUpdate
   */
  export interface TicketCodePartialUpdateParams {

    /**
     * A unique integer value identifying this ticket code.
     */
    id: number;
    data: TicketCode;
  }
}

export { TicketCodeService }
