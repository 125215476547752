/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventFilter } from '../models/event-filter';
import { Event } from '../models/event';
import { EventCreate } from '../models/event-create';
@Injectable({
  providedIn: 'root',
})
class EventService extends __BaseService {
  static readonly eventFilterListPath = '/event/filter';
  static readonly eventListListPath = '/event/list';
  static readonly eventListCreatePath = '/event/list';
  static readonly eventReadPath = '/event/{id}';
  static readonly eventUpdatePath = '/event/{id}';
  static readonly eventPartialUpdatePath = '/event/{id}';
  static readonly eventDeletePath = '/event/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EventService.EventFilterListParams` containing the following parameters:
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `recommended`: recommended
   *
   * - `ids`: ids
   *
   * - `area_events`: area_events
   */
  eventFilterListResponse(params: EventService.EventFilterListParams): __Observable<__StrictHttpResponse<EventFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeStart != null) __params = __params.set('time_start', params.timeStart.toString());
    if (params.timeFinish != null) __params = __params.set('time_finish', params.timeFinish.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.thematics != null) __params = __params.set('thematics', params.thematics.toString());
    if (params.speakers != null) __params = __params.set('speakers', params.speakers.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.areaEvents != null) __params = __params.set('area_events', params.areaEvents.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventFilter>;
      })
    );
  }
  /**
   * @param params The `EventService.EventFilterListParams` containing the following parameters:
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `recommended`: recommended
   *
   * - `ids`: ids
   *
   * - `area_events`: area_events
   */
  eventFilterList(params: EventService.EventFilterListParams): __Observable<EventFilter> {
    return this.eventFilterListResponse(params).pipe(
      __map(_r => _r.body as EventFilter)
    );
  }

  /**
   * @param params The `EventService.EventListListParams` containing the following parameters:
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `area_events`: area_events
   */
  eventListListResponse(params: EventService.EventListListParams): __Observable<__StrictHttpResponse<Array<Event>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeStart != null) __params = __params.set('time_start', params.timeStart.toString());
    if (params.timeFinish != null) __params = __params.set('time_finish', params.timeFinish.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.thematics != null) __params = __params.set('thematics', params.thematics.toString());
    if (params.speakers != null) __params = __params.set('speakers', params.speakers.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.areaEvents != null) __params = __params.set('area_events', params.areaEvents.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Event>>;
      })
    );
  }
  /**
   * @param params The `EventService.EventListListParams` containing the following parameters:
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `area_events`: area_events
   */
  eventListList(params: EventService.EventListListParams): __Observable<Array<Event>> {
    return this.eventListListResponse(params).pipe(
      __map(_r => _r.body as Array<Event>)
    );
  }

  /**
   * @param data undefined
   */
  eventListCreateResponse(data: EventCreate): __Observable<__StrictHttpResponse<EventCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  eventListCreate(data: EventCreate): __Observable<EventCreate> {
    return this.eventListCreateResponse(data).pipe(
      __map(_r => _r.body as EventCreate)
    );
  }

  /**
   * @param id undefined
   */
  eventReadResponse(id: string): __Observable<__StrictHttpResponse<Event>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Event>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventRead(id: string): __Observable<Event> {
    return this.eventReadResponse(id).pipe(
      __map(_r => _r.body as Event)
    );
  }

  /**
   * @param params The `EventService.EventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventUpdateResponse(params: EventService.EventUpdateParams): __Observable<__StrictHttpResponse<EventCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreate>;
      })
    );
  }
  /**
   * @param params The `EventService.EventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventUpdate(params: EventService.EventUpdateParams): __Observable<EventCreate> {
    return this.eventUpdateResponse(params).pipe(
      __map(_r => _r.body as EventCreate)
    );
  }

  /**
   * @param params The `EventService.EventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventPartialUpdateResponse(params: EventService.EventPartialUpdateParams): __Observable<__StrictHttpResponse<EventCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreate>;
      })
    );
  }
  /**
   * @param params The `EventService.EventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventPartialUpdate(params: EventService.EventPartialUpdateParams): __Observable<EventCreate> {
    return this.eventPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as EventCreate)
    );
  }

  /**
   * @param id undefined
   */
  eventDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventDelete(id: string): __Observable<null> {
    return this.eventDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EventService {

  /**
   * Parameters for eventFilterList
   */
  export interface EventFilterListParams {

    /**
     * time_start
     */
    timeStart?: string;

    /**
     * time_finish
     */
    timeFinish?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * thematics
     */
    thematics?: string;

    /**
     * speakers
     */
    speakers?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * area_events
     */
    areaEvents?: string;
  }

  /**
   * Parameters for eventListList
   */
  export interface EventListListParams {

    /**
     * time_start
     */
    timeStart?: string;

    /**
     * time_finish
     */
    timeFinish?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * thematics
     */
    thematics?: string;

    /**
     * speakers
     */
    speakers?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;

    /**
     * area_events
     */
    areaEvents?: string;
  }

  /**
   * Parameters for eventUpdate
   */
  export interface EventUpdateParams {
    id: string;
    data: EventCreate;
  }

  /**
   * Parameters for eventPartialUpdate
   */
  export interface EventPartialUpdateParams {
    id: string;
    data: EventCreate;
  }
}

export { EventService }
