/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Theme } from '../models/theme';
@Injectable({
  providedIn: 'root',
})
class ThemeService extends __BaseService {
  static readonly themeListListPath = '/theme/list';
  static readonly themeListCreatePath = '/theme/list';
  static readonly themeReadPath = '/theme/{id}';
  static readonly themeUpdatePath = '/theme/{id}';
  static readonly themePartialUpdatePath = '/theme/{id}';
  static readonly themeDeletePath = '/theme/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ThemeService.ThemeListListParams` containing the following parameters:
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  themeListListResponse(params: ThemeService.ThemeListListParams): __Observable<__StrictHttpResponse<Array<Theme>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/theme/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Theme>>;
      })
    );
  }
  /**
   * @param params The `ThemeService.ThemeListListParams` containing the following parameters:
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  themeListList(params: ThemeService.ThemeListListParams): __Observable<Array<Theme>> {
    return this.themeListListResponse(params).pipe(
      __map(_r => _r.body as Array<Theme>)
    );
  }

  /**
   * @param data undefined
   */
  themeListCreateResponse(data: Theme): __Observable<__StrictHttpResponse<Theme>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/theme/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Theme>;
      })
    );
  }
  /**
   * @param data undefined
   */
  themeListCreate(data: Theme): __Observable<Theme> {
    return this.themeListCreateResponse(data).pipe(
      __map(_r => _r.body as Theme)
    );
  }

  /**
   * @param id undefined
   */
  themeReadResponse(id: string): __Observable<__StrictHttpResponse<Theme>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/theme/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Theme>;
      })
    );
  }
  /**
   * @param id undefined
   */
  themeRead(id: string): __Observable<Theme> {
    return this.themeReadResponse(id).pipe(
      __map(_r => _r.body as Theme)
    );
  }

  /**
   * @param params The `ThemeService.ThemeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  themeUpdateResponse(params: ThemeService.ThemeUpdateParams): __Observable<__StrictHttpResponse<Theme>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/theme/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Theme>;
      })
    );
  }
  /**
   * @param params The `ThemeService.ThemeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  themeUpdate(params: ThemeService.ThemeUpdateParams): __Observable<Theme> {
    return this.themeUpdateResponse(params).pipe(
      __map(_r => _r.body as Theme)
    );
  }

  /**
   * @param params The `ThemeService.ThemePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  themePartialUpdateResponse(params: ThemeService.ThemePartialUpdateParams): __Observable<__StrictHttpResponse<Theme>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/theme/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Theme>;
      })
    );
  }
  /**
   * @param params The `ThemeService.ThemePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  themePartialUpdate(params: ThemeService.ThemePartialUpdateParams): __Observable<Theme> {
    return this.themePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Theme)
    );
  }

  /**
   * @param id undefined
   */
  themeDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/theme/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  themeDelete(id: string): __Observable<null> {
    return this.themeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ThemeService {

  /**
   * Parameters for themeListList
   */
  export interface ThemeListListParams {

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'order' | '-order';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for themeUpdate
   */
  export interface ThemeUpdateParams {
    id: string;
    data: Theme;
  }

  /**
   * Parameters for themePartialUpdate
   */
  export interface ThemePartialUpdateParams {
    id: string;
    data: Theme;
  }
}

export { ThemeService }
