<div class="b-photo-gallery">
  <div class="b-photo-gallery__container">
    <div class="b-photo-gallery__content">
      <ngx-tabset (onSelect)="onTabChange($event)">
        <ngx-tab [tabTitle]="tabs[0].year" lightbox-group [active]="pageNow(2019)">
          <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="loadMore()"
            [alwaysCallback]="true">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
        <ngx-tab [tabTitle]="tabs[1].year" lightbox-group [active]="pageNow(2020)">
          <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="loadMore()"
            [alwaysCallback]="true">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
        <ngx-tab [tabTitle]="tabs[2].year" lightbox-group [active]="pageNow(2021)">
          <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="loadMore()">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
        <ngx-tab [tabTitle]="tabs[3].year" lightbox-group [active]="pageNow(2022)">
          <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="loadMore()">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
        <ngx-tab [tabTitle]="tabs[4].year" lightbox-group [active]="pageNow(2023)">
          <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="300" (scrolled)="loadMore()">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
        <ngx-tab [tabTitle]="tabs[5].year" lightbox-group [active]="pageNow(2024)">
          <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="300" (scrolled)="loadMore()">
            <ng-container *ngFor="let photo of album">
              <img src="{{photo['url_z']}}" role="presentation" lightbox backgroundOpacity="0.5"
                closeButtonText="Закрыть" [hideThumbnail]="false" counter="true" />
            </ng-container>
          </div>
        </ngx-tab>
      </ngx-tabset>
    </div>
  </div>