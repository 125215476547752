import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventType } from '../swagger/models/event-type';
import { Person } from '../swagger/models/person';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  cache = {};
  API_URL = environment.apiV2_url;
  constructor(private http: HttpClient) {}

  getEventsListByStage(themeId: string): Observable<any> {
    const url = `${this.API_URL}/api/v1/event/list?theme=${themeId}`;

    const eventType$ = this.getEventTypes();
    const eventByStage$ = this.http.get<any[]>(url);

    return forkJoin([eventType$, eventByStage$]).pipe(
      map(([eventType, eventByStage]) => {
        return eventByStage.map((result) => {
          let event_type = eventType.find((event_type) => event_type['id'] === result['id']);
          if (!event_type) {
            event_type = {
              id: result['id'],
              name: 'undefined',
            };
          }

          const speakers: Person[] = result['speakers'].map((el) => {
            return {
              id: el.id,
              name: '',
            };
          });

          return {
            id: result['id'],
            name: result['name'],
            description: result['description'],
            area_events: result['area_events'],
            speakers,
            time_start: this.time1CToDate(result['time_start']),
            time_finish: this.time1CToDate(result['time_finish']),
            area: {},
          };
        });
      }),
      switchMap((results) => {
        let participants_ids = [];
        let areas_ids = [];

        results.map((result) => {
          result.area_events.map((id) => areas_ids.push(id));

          result.speakers.map((el) => {
            participants_ids.push(el.id);
          });
        });

        areas_ids = areas_ids.filter((v, i, a) => a.indexOf(v) === i).filter((item) => typeof item === 'number');
        const speakers_request$ = this.http.get<any[]>(`${this.API_URL}/api/v1/person/list?theme=${themeId}&ids=${participants_ids}`);
        const areas_request$ = this.http.get<any[]>(`${this.API_URL}/api/v1/area/list?ids=${areas_ids}`);

        return combineLatest([speakers_request$, areas_request$]).pipe(
          take(1),
          map((data) =>
            results.map((result) => {
              const arrayList = data[0].concat(data[1]);

              const area = arrayList.filter((a) => a.id === result.area_events[0]);

              if (area.length === 1) {
                Object.assign(result['area'], area[0]);
              }

              result.speakers.map((speaker) => {
                const data_speaker = arrayList.filter((data_speaker) => data_speaker.id === speaker.id);
                if (data_speaker.length === 1) {
                  Object.assign(speaker, data_speaker[0]);
                }
                return speaker;
              });
              return result;
            })
          )
        );
      })
    );
  }

  getEventTypes(): Observable<EventType[]> {
    if (!this.cache['event_types']) {
      return this.http.get<any[]>(`${this.API_URL}/api/v1/event_type/list`).pipe(
        map((res) => {
          return res.map((el) => {
            return {
              name: el['Description'],
              id: el['id'],
            };
          });
        }),
        tap((data) => {
          this.cache['event_types'] = data;
        })
      );
    } else {
      return new Observable<EventType[]>((observer) => {
        observer.next(this.cache['event_types']);
        observer.complete();
      });
    }
  }

  time1CToDate = (date) => {
    let str: any;

    if (date) {
      // 2017-10-09T14:30:00
      str = dayjs(date).toDate();
    }

    return str;
  };
}
