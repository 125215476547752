<section class="main">
  <div class="main-content">
    <div class="banner">
      <div class="container">
        <div class="banner__image"><h1>MOSCOW CAKE SHOW - Форум для кондитеров и пекарей</h1></div>
        <!--<div class="banner__buttons">
           <a class="banner__link" href="https://b24-150f9z.bitrix24.site/crm_form_ux97r/">Стать партнёром</a>
          <a class="banner__link banner__link--ticket" href="https://docs.google.com/forms/d/1Ys-zVRjT8jwPqsh7XqgFZxExTKb-mYEBG8e-MpVypgs/edit">Стать экспертом</a>
          <a id="pirexpo-ticket-widget" data-pirexpo-widget-shop-id="2" class="banner__link banner__link--ticket">
            <span class="ticket-text">Купить билет</span>
            <span class="ticket-icon">14+</span>
          </a>
          <a class="banner__link" href="https://cakeshow.ru/assets/files/program2023.pdf">Программа 2023</a>
        </div>-->
      </div>
      <!-- container -->
    </div>
    <div class="description">
      <div class="container">
        <div class="left-side">
          <p class="description__title">3&nbsp;дня, 3&nbsp;площадки, 50&nbsp;МЕРОПРИЯТИЙ от ЛУЧШИХ&nbsp;экспертов</p>
          <ul class="description__links"> <!--
            <li class="description__link-item description__link-item--button">
              <a class="description__button button" [routerLink]="['/program']" routerLinkActive="router-link-active">Программа</a>
            </li>-->
            <li class="description__link-item">
              <a href="https://zen.yandex.ru/moscowcakeshow" target="_blank"><i class="description__icon description__icon--zen"></i></a>
            </li>
            <li class="description__link-item">
              <a href="https://vk.com/moscowcakeshow" target="_blank"><i class="description__icon description__icon--vk"></i></a>
            </li>
            <li class="description__link-item">
              <a href="https://ok.ru/group/64253252862200" target="_blank"><i class="description__icon description__icon--ok"></i></a>
            </li>
          </ul>
          <!-- b-nav__social-links-->
        </div>
        <div class="right-side">
          <p class="description__text">РАСТИТЕ, ЗНАКОМЬТЕСЬ, ОБЩАЙТЕСЬ, ЗАДАВАЙТЕ ВОПРОСЫ, НАХОДИТЕ ПАРТНЕРОВ!</p>
          <a href="https://b24-150f9z.bitrix24.site/crm_form_ux97r/" class="description__exponent button button--darkmagenta"
            ><span class="text">СТАТЬ ПАРТНЕРОМ</span></a
          >
          <a href="https://docs.google.com/forms/d/1CACF_md48RyOShfkvmbqhpewhm3oWrbnoEiJWwTw1-c/edit" class="description__exponent button button--magenta"
            ><span class="text">СТАТЬ СПИКЕРОМ</span></a
          >
        </div>
      </div>
    </div>
    <div class="visitors">
      <div class="container">
        <h2 class="visitors__title">
          Для кого:
        </h2>
        <ul class="visitors__list">
          <li>
            <span>ШЕФ-КОНДИТЕРЫ</span> <br />
            кондитеры ресторанов, кафе и кондитерских, шеф-пекари/пекари, технологи предприятий
          </li>
          <li>
            <span>ВЛАДЕЛЬЦЫ </span> <br />
            те, кто только задумываются о кондитерском стартапе, управляющие, шеф-повара
          </li>
          <li>
            <span>САМОЗАНЯТЫЕ</span> <br>
            кондитеры, преподающие в школах студиях; студенты профильных учебных заведений
          </li>
        </ul>
      </div>
    </div>
    <div class="photoBlock">
      <div class="container">
        <h2 class="photoBlock__title">
          Фотоотчеты
        </h2>
        <p class="photoBlock__desc">
          Посмотрите как это было
        </p>
        <a href="https://cakeshow.ru/assets/files/postrelease2024.pdf" class="button button--orange">
          <span class="text">
            Релиз 2024
          </span>
        </a>
        <div class="photos">
          <a class="photo empty" [routerLink]="['/photo']" [queryParams]="{year: '2024'}" routerLinkActive="router-link-active"> </a>
          <a class="photo photo2024" [routerLink]="['/photo']" [queryParams]="{year: '2024'}" routerLinkActive="router-link-active"> <span>2024</span> </a>
          <a class="photo photo2023" [routerLink]="['/photo']" [queryParams]="{year: '2023'}" routerLinkActive="router-link-active"> <span>2023</span> </a>
        </div>
        <a [routerLink]="['/photo']" routerLinkActive="router-link-active" class="photoBlock__link">
          Смотреть все
        </a>
      </div>
    </div>
  </div>
  <!-- main-content-->
</section>
<!-- main -->
