<div class="b-program-page">
    <div class="b-program-page__container container" *ngIf="events$ | async">
        <div class="b-program-page__title">
            <div class="b-program-page__column">программа мероприятий</div>
            <div class="b-program-page__column">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="b-program-page__back-button button button--orange">
                    <span>вернуться</span>
                </a>
                <a href="https://cakeshow.ru/assets/files/program2024.pdf" class="b-program-page__back-button button button--darkmagenta">
                    <span>Скачать программу</span>
                </a>
            </div>
        </div>
        <div class="b-program-page__paragraph">
            <div class="b-program-page__text">
                <p>13-15 мая 2024</p>
            </div>
        </div>
        <div class="b-program-page__filter-buttons">
            <div class="b-program-page__day-filter b-program-page__filter">
                <div class="b-program-page__filter-text">дата</div>
                <a routerLink="/program" [queryParams]="{ day: null }" queryParamsHandling="merge"
                    class="b-program-page__all-filter" [ngClass]="{ 'filter-default': !day }">все дни</a>
                <button mat-button [matMenuTriggerFor]="dayMenu">
                    <span class="b-program-page__select-filter" *ngIf="!day">выбрать</span>
                    <span class="b-program-page__filter-active" *ngIf="day">{{ day }} мая</span>
                </button>
                <mat-menu #dayMenu="matMenu">
                    <a routerLink="/program" [queryParams]="{ day: day }" queryParamsHandling="merge"
                        *ngFor="let day of days" mat-menu-item>{{ day }} мая</a>
                </mat-menu>
            </div>
            <div class="b-program-page__area-filter b-program-page__filter">
                <div class="b-program-page__filter-text">площадки</div>
                <a class="b-program-page__all-filter" routerLink="/program" [queryParams]="{ area: null }"
                    queryParamsHandling="merge" [ngClass]="{ 'filter-default': !area.id }">все</a>
                <button mat-button [matMenuTriggerFor]="areaMenu">
                    <span class="b-program-page__select-filter" *ngIf="!area.id">выбрать</span>
                    <span class="b-program-page__filter-active" *ngIf="area.id">{{ area.name }}</span>
                </button>
                <mat-menu #areaMenu="matMenu">
                    <a routerLink="/program" [queryParams]="{ area: area['id'] }" queryParamsHandling="merge"
                        *ngFor="let area of areas" mat-menu-item>{{ area['name'] }}</a>
                </mat-menu>
            </div>
            <div class="b-program-page__expert-filter b-program-page__filter">
                <div class="b-program-page__filter-text">эксперты</div>
                <a class="b-program-page__all-filter" routerLink="/program" [queryParams]="{ expert: null }"
                    queryParamsHandling="merge" [ngClass]="{ 'filter-default': !expert.id }">все</a>
                <button mat-button [matMenuTriggerFor]="expertMenu">
                    <span class="b-program-page__select-filter" *ngIf="!expert.id">выбрать</span>
                    <span class="b-program-page__filter-active" *ngIf="expert.id">{{ expert.name }}</span>
                </button>
                <mat-menu #expertMenu="matMenu">
                    <a routerLink="/program" [queryParams]="{ expert: expert['id'] }" queryParamsHandling="merge"
                        *ngFor="let expert of experts" mat-menu-item>{{ expert['firstname'] }} {{ expert['secondname']
                        }}</a>
                </mat-menu>
            </div>
        </div>
        <div class="b-program-page__table">
            <ul class="b-program-page__header">
                <li class="b-program-page__header-item">дата и время</li>
                <li class="b-program-page__header-item">тема</li>
                <li class="b-program-page__header-item">эксперт</li>
                <li class="b-program-page__header-item">площадка</li>
            </ul>
            <div *ngFor="let day of daysFiltered">
                <div class="b-program-page__date">
                    <div class="b-program-page__number">{{ day }}</div>
                    <div class="b-program-page__month">&nbsp;мая</div>
                </div>
                <div class="b-program-page__info">
                    <div *ngFor="let event of filteringEventsByDays[day]" class="b-program-page__line">
                        <div class="b-program-page__info-item">{{ event['time_string'] }}</div>
                        <div class="b-program-page__info-item b-program-page__info-item--event-link">
                            <!-- <a href="https://pirexpo.com/event/{{ event['id'] }}" target="_blank"> -->
                            <div>{{ event['name'] }}</div>
                            <!-- </a> -->
                            <div *ngIf="event['description']">
                                <app-theme-tooltip class="b-program-page__program-tooltip" [value]="event"></app-theme-tooltip>
                            </div>
                        </div>
                        <div class="b-program-page__info-item">
                            <div class="b-program-page__participant" *ngFor="let expert of event['speakers']">
                                <div class="b-program-page__participant-name b-program-page__info-item--link">
                                    <div> {{ expert['firstname'] }}
                                        {{expert['secondname']}} </div>
                                    <app-tooltip class="b-program-page__expert-tooltip" [value]="expert"></app-tooltip>
                                </div>
                                <div class="b-program-page__participant-status">{{ expert['status'] }}</div>
                            </div>
                        </div>
                        <div class="b-program-page__info-item b-program-page__info-item--link">{{ event['area']['name']
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>