<div class="b-posts-all" *ngIf="posts$ | async as posts" id="b-posts-all">
    <div class="b-posts-all__container container">
        <div class="b-posts-all__title">
            <div class="b-posts-all__column">НОВОСТИ</div>
            <div class="b-posts-all__column">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="b-posts-all__back-button button button--orange">
                    <span>вернуться</span>
                </a>
            </div>
        </div>
        <!-- b-posts-all__header -->
        <div class="b-posts-all__list">
            <a class="b-posts-all__post" *ngFor="let post of posts" (click)="openPopup(post)">
                <img *ngIf="post.image" loading="lazy" class="b-posts-all__image"
                    src="{{ post.image }}" alt="{{ post.name }}" />
                <div class="b-posts-all__name">{{ post.title }}</div>
            </a>
        </div><!-- b-posts-all__list -->
    </div><!-- b-posts-all__container -->
</div><!-- b-posts-all -->