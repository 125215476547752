/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TicketTemplate } from '../models/ticket-template';
@Injectable({
  providedIn: 'root',
})
class TicketTemplateService extends __BaseService {
  static readonly ticketTemplateListListPath = '/ticket_template/list';
  static readonly ticketTemplateListCreatePath = '/ticket_template/list';
  static readonly ticketTemplateReadPath = '/ticket_template/{id}';
  static readonly ticketTemplateUpdatePath = '/ticket_template/{id}';
  static readonly ticketTemplatePartialUpdatePath = '/ticket_template/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TicketTemplateService.TicketTemplateListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketTemplateListListResponse(params: TicketTemplateService.TicketTemplateListListParams): __Observable<__StrictHttpResponse<Array<TicketTemplate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_template/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TicketTemplate>>;
      })
    );
  }
  /**
   * @param params The `TicketTemplateService.TicketTemplateListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketTemplateListList(params: TicketTemplateService.TicketTemplateListListParams): __Observable<Array<TicketTemplate>> {
    return this.ticketTemplateListListResponse(params).pipe(
      __map(_r => _r.body as Array<TicketTemplate>)
    );
  }

  /**
   * @param data undefined
   */
  ticketTemplateListCreateResponse(data: TicketTemplate): __Observable<__StrictHttpResponse<TicketTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket_template/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketTemplate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  ticketTemplateListCreate(data: TicketTemplate): __Observable<TicketTemplate> {
    return this.ticketTemplateListCreateResponse(data).pipe(
      __map(_r => _r.body as TicketTemplate)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket template.
   */
  ticketTemplateReadResponse(id: number): __Observable<__StrictHttpResponse<TicketTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_template/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketTemplate>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket template.
   */
  ticketTemplateRead(id: number): __Observable<TicketTemplate> {
    return this.ticketTemplateReadResponse(id).pipe(
      __map(_r => _r.body as TicketTemplate)
    );
  }

  /**
   * @param params The `TicketTemplateService.TicketTemplateUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket template.
   *
   * - `data`:
   */
  ticketTemplateUpdateResponse(params: TicketTemplateService.TicketTemplateUpdateParams): __Observable<__StrictHttpResponse<TicketTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ticket_template/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketTemplate>;
      })
    );
  }
  /**
   * @param params The `TicketTemplateService.TicketTemplateUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket template.
   *
   * - `data`:
   */
  ticketTemplateUpdate(params: TicketTemplateService.TicketTemplateUpdateParams): __Observable<TicketTemplate> {
    return this.ticketTemplateUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketTemplate)
    );
  }

  /**
   * @param params The `TicketTemplateService.TicketTemplatePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket template.
   *
   * - `data`:
   */
  ticketTemplatePartialUpdateResponse(params: TicketTemplateService.TicketTemplatePartialUpdateParams): __Observable<__StrictHttpResponse<TicketTemplate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ticket_template/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketTemplate>;
      })
    );
  }
  /**
   * @param params The `TicketTemplateService.TicketTemplatePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket template.
   *
   * - `data`:
   */
  ticketTemplatePartialUpdate(params: TicketTemplateService.TicketTemplatePartialUpdateParams): __Observable<TicketTemplate> {
    return this.ticketTemplatePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketTemplate)
    );
  }
}

module TicketTemplateService {

  /**
   * Parameters for ticketTemplateListList
   */
  export interface TicketTemplateListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for ticketTemplateUpdate
   */
  export interface TicketTemplateUpdateParams {

    /**
     * A unique integer value identifying this ticket template.
     */
    id: number;
    data: TicketTemplate;
  }

  /**
   * Parameters for ticketTemplatePartialUpdate
   */
  export interface TicketTemplatePartialUpdateParams {

    /**
     * A unique integer value identifying this ticket template.
     */
    id: number;
    data: TicketTemplate;
  }
}

export { TicketTemplateService }
