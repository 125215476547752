/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FAQ } from '../models/faq';
@Injectable({
  providedIn: 'root',
})
class FaqService extends __BaseService {
  static readonly faqListListPath = '/faq/list';
  static readonly faqListCreatePath = '/faq/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FaqService.FaqListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  faqListListResponse(params: FaqService.FaqListListParams): __Observable<__StrictHttpResponse<Array<FAQ>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/faq/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FAQ>>;
      })
    );
  }
  /**
   * @param params The `FaqService.FaqListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  faqListList(params: FaqService.FaqListListParams): __Observable<Array<FAQ>> {
    return this.faqListListResponse(params).pipe(
      __map(_r => _r.body as Array<FAQ>)
    );
  }

  /**
   * @param data undefined
   */
  faqListCreateResponse(data: FAQ): __Observable<__StrictHttpResponse<FAQ>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/faq/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FAQ>;
      })
    );
  }
  /**
   * @param data undefined
   */
  faqListCreate(data: FAQ): __Observable<FAQ> {
    return this.faqListCreateResponse(data).pipe(
      __map(_r => _r.body as FAQ)
    );
  }
}

module FaqService {

  /**
   * Parameters for faqListList
   */
  export interface FaqListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }
}

export { FaqService }
