/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Compilation } from '../models/compilation';
import { CompilationCreate } from '../models/compilation-create';
import { CompilationDetail } from '../models/compilation-detail';
@Injectable({
  providedIn: 'root',
})
class CompilationService extends __BaseService {
  static readonly compilationListListPath = '/compilation/list';
  static readonly compilationListCreatePath = '/compilation/list';
  static readonly compilationReadPath = '/compilation/{id}';
  static readonly compilationUpdatePath = '/compilation/{id}';
  static readonly compilationPartialUpdatePath = '/compilation/{id}';
  static readonly compilationDeletePath = '/compilation/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CompilationService.CompilationListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  compilationListListResponse(params: CompilationService.CompilationListListParams): __Observable<__StrictHttpResponse<Array<Compilation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/compilation/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Compilation>>;
      })
    );
  }
  /**
   * @param params The `CompilationService.CompilationListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  compilationListList(params: CompilationService.CompilationListListParams): __Observable<Array<Compilation>> {
    return this.compilationListListResponse(params).pipe(
      __map(_r => _r.body as Array<Compilation>)
    );
  }

  /**
   * @param data undefined
   */
  compilationListCreateResponse(data: CompilationCreate): __Observable<__StrictHttpResponse<CompilationCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/compilation/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompilationCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  compilationListCreate(data: CompilationCreate): __Observable<CompilationCreate> {
    return this.compilationListCreateResponse(data).pipe(
      __map(_r => _r.body as CompilationCreate)
    );
  }

  /**
   * @param id undefined
   */
  compilationReadResponse(id: string): __Observable<__StrictHttpResponse<CompilationDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/compilation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompilationDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  compilationRead(id: string): __Observable<CompilationDetail> {
    return this.compilationReadResponse(id).pipe(
      __map(_r => _r.body as CompilationDetail)
    );
  }

  /**
   * @param params The `CompilationService.CompilationUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  compilationUpdateResponse(params: CompilationService.CompilationUpdateParams): __Observable<__StrictHttpResponse<CompilationCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/compilation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompilationCreate>;
      })
    );
  }
  /**
   * @param params The `CompilationService.CompilationUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  compilationUpdate(params: CompilationService.CompilationUpdateParams): __Observable<CompilationCreate> {
    return this.compilationUpdateResponse(params).pipe(
      __map(_r => _r.body as CompilationCreate)
    );
  }

  /**
   * @param params The `CompilationService.CompilationPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  compilationPartialUpdateResponse(params: CompilationService.CompilationPartialUpdateParams): __Observable<__StrictHttpResponse<CompilationCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/compilation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompilationCreate>;
      })
    );
  }
  /**
   * @param params The `CompilationService.CompilationPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  compilationPartialUpdate(params: CompilationService.CompilationPartialUpdateParams): __Observable<CompilationCreate> {
    return this.compilationPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as CompilationCreate)
    );
  }

  /**
   * @param id undefined
   */
  compilationDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/compilation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  compilationDelete(id: string): __Observable<null> {
    return this.compilationDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CompilationService {

  /**
   * Parameters for compilationListList
   */
  export interface CompilationListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for compilationUpdate
   */
  export interface CompilationUpdateParams {
    id: string;
    data: CompilationCreate;
  }

  /**
   * Parameters for compilationPartialUpdate
   */
  export interface CompilationPartialUpdateParams {
    id: string;
    data: CompilationCreate;
  }
}

export { CompilationService }
