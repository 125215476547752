import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NewsService } from '../../swagger/services/news.service';
import { Location } from '@angular/common';
import { News } from '../../swagger/models/news';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PostPopupComponent } from '../../components/ui/popup/post-popup/post-popup.component';
import { map } from 'rxjs/operators';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-all-posts',
  templateUrl: './all-posts.component.html',
  styleUrls: ['./all-posts.component.scss'],
})
export class AllPostsComponent implements OnInit {
  API_URL = environment.api_url;
  exhibition = environment.exhibition_id;
  themeId = environment.themeId;
  queryParam: string;
  posts$: Observable<News[]>;
  statusOpenPopup = false;
  constructor( private route: ActivatedRoute, private newsService: NewsService, private location: Location, private dialog: MatDialog) {}

  ngOnInit(): void {

    this.posts$ = this.newsService.newsListList({ theme: this.themeId }).pipe(
      map((posts) => {
        posts.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          }
        });
        posts.forEach(post => {
          this.route.queryParams.subscribe((params) => {
            this.queryParam = params['news'];
            if (this.queryParam!=undefined) {
              if (post.id.toString()==this.queryParam&&!this.statusOpenPopup)
              {
                this.statusOpenPopup = true;
                this.openPopup(post);
              }
            }
          });
        });
        return posts;
        
  
      })
    );
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  openPopup(post: News) {
      this.statusOpenPopup = true;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = post;
      dialogConfig.panelClass = 'post-popup';
      let dialogRef = this.dialog.open(PostPopupComponent, dialogConfig);
  }

  backButton(): void {
    document.location.href=('');
  }
}
