/* MODULES */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { TabsModule } from 'ngx-tabset';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';
/*  LAYOUT */
import { NavigationComponent } from './components/layout/navigation/navigation.component';
import { FooterComponent } from './components/layout/footer/footer.component';
/* COMPONENTS */
import { AppComponent } from './app.component';
import { MainComponent } from './components/page-sections/main-section/main-section.component';
import { GetSectionComponent } from './components/page-sections/get-section/get-section.component';
import { ExpertsInSweetSectionComponent } from './components/page-sections/experts-in-sweet-section/experts-in-sweet-section.component';
import { ProgramSectionComponent } from './components/page-sections/program-section/program-section.component';
import { PartnersSectionComponent } from './components/page-sections/partners-section/partners-section.component';
import { ArtClassSectionComponent } from './components/page-sections/art-class-section/art-class-section.component';
import { ExpertsSectionComponent } from './components/page-sections/experts-section/experts-section.component';
import { HowItWasComponent } from './components/page-sections/how-it-was/how-it-was.component';
import { PriceSectionComponent } from './components/page-sections/price-section/price-section.component';
import { WorldSectionComponent } from './components/page-sections/world-section/world-section.component';
import { WaitForYouSectionComponent } from './components/page-sections/wait-for-you-section/wait-for-you-section.component';
import { LocationComponent } from './components/page-sections/location/location.component';
import { BoothesSectionComponent } from './components/page-sections/boothes-section/boothes-section.component';
import { TicketPriceBlockComponent } from './components/ui/ticket-price-block/ticket-price-block.component';
/* TOOLTIPS */
import { TooltipComponent } from './components/ui/tooltips/expert-tooltip/tooltip.component';
import { ThemeTooltipComponent } from './components/ui/tooltips/theme-tooltip/tooltip.component';
import { BoothTooltipComponent } from './components/ui/tooltips/booth-tooltip/booth-tooltip.component';
/* POPUPS */
import { PopupComponent } from './components/ui/popup/video/popup.component';
import { ExpertPopupComponent } from './components/ui/popup/expert-popup/expert-popup.component';
import { PostPopupComponent } from './components/ui/popup/post-popup/post-popup.component';
/* PAGES*/
import { MainPageComponent } from './pages/main/main-page.component';
import { ArtClassPageComponent } from './pages/art-class/art-class-page.component';
import { PhotoGalleryComponent } from './pages/photo-gallery/photo-gallery.component';
import { AllExpertsComponent } from './pages/all-experts/all-experts.component';
import { AllPostsComponent } from './pages/all-posts/all-posts.component';
import { ProgramComponent } from './pages/program/program.component';
/* SERVICES */
import { MediaService } from './services/media.service';
import { ProgramService } from './services/program.service';
import { ProgramPopupComponent } from './components/ui/popup/program-popup/program-popup.component';
import { TicketPriceComponent } from './components/page-sections/ticket-price/ticket-price.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavigationComponent,
    GetSectionComponent,
    ExpertsInSweetSectionComponent,
    ProgramSectionComponent,
    PartnersSectionComponent,
    ArtClassPageComponent,
    MainPageComponent,
    PopupComponent,
    PhotoGalleryComponent,
    FooterComponent,
    ArtClassSectionComponent,
    ExpertsSectionComponent,
    HowItWasComponent,
    PriceSectionComponent,
    WorldSectionComponent,
    WaitForYouSectionComponent,
    ExpertPopupComponent,
    PostPopupComponent,
    AllExpertsComponent,
    AllPostsComponent,
    ProgramComponent,
    TooltipComponent,
    ThemeTooltipComponent,
    LocationComponent,
    BoothesSectionComponent,
    BoothTooltipComponent,
    ProgramPopupComponent,
    TicketPriceBlockComponent,
    TicketPriceComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'CakeshowApp' }),
    AppRoutingModule,
    NgxPageScrollModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    BrowserAnimationsModule,
    CrystalLightboxModule,
    TabsModule.forRoot(),
    InfiniteScrollModule,
    SlickCarouselModule,
    MatMenuModule,
  ],
  providers: [MediaService, ProgramService],
  bootstrap: [AppComponent],
})
export class AppModule {}
