/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SpecialOccasion } from '../models/special-occasion';
@Injectable({
  providedIn: 'root',
})
class SpecialOccasionService extends __BaseService {
  static readonly specialOccasionListListPath = '/special_occasion/list';
  static readonly specialOccasionListCreatePath = '/special_occasion/list';
  static readonly specialOccasionReadPath = '/special_occasion/{id}';
  static readonly specialOccasionUpdatePath = '/special_occasion/{id}';
  static readonly specialOccasionPartialUpdatePath = '/special_occasion/{id}';
  static readonly specialOccasionDeletePath = '/special_occasion/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SpecialOccasionService.SpecialOccasionListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  specialOccasionListListResponse(params: SpecialOccasionService.SpecialOccasionListListParams): __Observable<__StrictHttpResponse<Array<SpecialOccasion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/special_occasion/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SpecialOccasion>>;
      })
    );
  }
  /**
   * @param params The `SpecialOccasionService.SpecialOccasionListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  specialOccasionListList(params: SpecialOccasionService.SpecialOccasionListListParams): __Observable<Array<SpecialOccasion>> {
    return this.specialOccasionListListResponse(params).pipe(
      __map(_r => _r.body as Array<SpecialOccasion>)
    );
  }

  /**
   * @param data undefined
   */
  specialOccasionListCreateResponse(data: SpecialOccasion): __Observable<__StrictHttpResponse<SpecialOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/special_occasion/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialOccasion>;
      })
    );
  }
  /**
   * @param data undefined
   */
  specialOccasionListCreate(data: SpecialOccasion): __Observable<SpecialOccasion> {
    return this.specialOccasionListCreateResponse(data).pipe(
      __map(_r => _r.body as SpecialOccasion)
    );
  }

  /**
   * @param id undefined
   */
  specialOccasionReadResponse(id: string): __Observable<__StrictHttpResponse<SpecialOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/special_occasion/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialOccasion>;
      })
    );
  }
  /**
   * @param id undefined
   */
  specialOccasionRead(id: string): __Observable<SpecialOccasion> {
    return this.specialOccasionReadResponse(id).pipe(
      __map(_r => _r.body as SpecialOccasion)
    );
  }

  /**
   * @param params The `SpecialOccasionService.SpecialOccasionUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  specialOccasionUpdateResponse(params: SpecialOccasionService.SpecialOccasionUpdateParams): __Observable<__StrictHttpResponse<SpecialOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/special_occasion/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialOccasion>;
      })
    );
  }
  /**
   * @param params The `SpecialOccasionService.SpecialOccasionUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  specialOccasionUpdate(params: SpecialOccasionService.SpecialOccasionUpdateParams): __Observable<SpecialOccasion> {
    return this.specialOccasionUpdateResponse(params).pipe(
      __map(_r => _r.body as SpecialOccasion)
    );
  }

  /**
   * @param params The `SpecialOccasionService.SpecialOccasionPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  specialOccasionPartialUpdateResponse(params: SpecialOccasionService.SpecialOccasionPartialUpdateParams): __Observable<__StrictHttpResponse<SpecialOccasion>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/special_occasion/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialOccasion>;
      })
    );
  }
  /**
   * @param params The `SpecialOccasionService.SpecialOccasionPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  specialOccasionPartialUpdate(params: SpecialOccasionService.SpecialOccasionPartialUpdateParams): __Observable<SpecialOccasion> {
    return this.specialOccasionPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as SpecialOccasion)
    );
  }

  /**
   * @param id undefined
   */
  specialOccasionDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/special_occasion/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  specialOccasionDelete(id: string): __Observable<null> {
    return this.specialOccasionDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SpecialOccasionService {

  /**
   * Parameters for specialOccasionListList
   */
  export interface SpecialOccasionListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'order' | '-order';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for specialOccasionUpdate
   */
  export interface SpecialOccasionUpdateParams {
    id: string;
    data: SpecialOccasion;
  }

  /**
   * Parameters for specialOccasionPartialUpdate
   */
  export interface SpecialOccasionPartialUpdateParams {
    id: string;
    data: SpecialOccasion;
  }
}

export { SpecialOccasionService }
