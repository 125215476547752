/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Notifications } from '../models/notifications';
@Injectable({
  providedIn: 'root',
})
class InvoiceService extends __BaseService {
  static readonly invoiceNotificationsCreatePath = '/invoice/{id}/notifications';
  static readonly invoicePrintReadPath = '/invoice/{id}/print/{template_name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `InvoiceService.InvoiceNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  invoiceNotificationsCreateResponse(params: InvoiceService.InvoiceNotificationsCreateParams): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/invoice/${encodeURIComponent(String(params.id))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * @param params The `InvoiceService.InvoiceNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  invoiceNotificationsCreate(params: InvoiceService.InvoiceNotificationsCreateParams): __Observable<Notifications> {
    return this.invoiceNotificationsCreateResponse(params).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * @param params The `InvoiceService.InvoicePrintReadParams` containing the following parameters:
   *
   * - `template_name`:
   *
   * - `id`:
   */
  invoicePrintReadResponse(params: InvoiceService.InvoicePrintReadParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/invoice/${encodeURIComponent(String(params.id))}/print/${encodeURIComponent(String(params.templateName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `InvoiceService.InvoicePrintReadParams` containing the following parameters:
   *
   * - `template_name`:
   *
   * - `id`:
   */
  invoicePrintRead(params: InvoiceService.InvoicePrintReadParams): __Observable<null> {
    return this.invoicePrintReadResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module InvoiceService {

  /**
   * Parameters for invoiceNotificationsCreate
   */
  export interface InvoiceNotificationsCreateParams {
    id: string;
    data: Notifications;
  }

  /**
   * Parameters for invoicePrintRead
   */
  export interface InvoicePrintReadParams {
    templateName: string;
    id: string;
  }
}

export { InvoiceService }
