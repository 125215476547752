<div class="tooltip">
  <div class="tooltip__wrapper">
    <div class="tooltip__inner">
      <div *ngIf="value.photo?.length" class="tooltip__img">
        <img *ngIf="value.photo?.length" loading="lazy" src="{{ value.photo[0]['image'] }}" alt="{{ value.name }}" />
      </div>
      <div class="tooltip__text">
        <div>{{ value.name }}</div>
      </div>
      <div *ngIf="value.description" class="tooltip__description">
        <div [innerHTML]="value.description"></div>
      </div>
    </div>
  </div>
</div>
