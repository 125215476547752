<section class="wait-for-you">
     <!--<a [routerLink]="['/program']" class="section-title title">СМОТРЕТЬ ВСЮ ПРОГРАММУ</a> -->
    <div class="container">
        <!--
        <a class="button button--white button--magenta" [routerLink]="['/program']" target="_blank">
            <span class="text">
                Смотреть программу
            </span>
        </a>
        <a class="button button--white button--magenta" href="https://docs.google.com/forms/d/1Ys-zVRjT8jwPqsh7XqgFZxExTKb-mYEBG8e-MpVypgs/edit" target="_blank"><span class="text">Стать 
                экспертом</span></a>-->
        <a class="button button--white button--darkmagenta"
            href="https://cp.unisender.com/ru/v5/subscribe-form/view/6q1mzwzrzpxackatkcrwmtdisme6b3tjcs34m6na"
            target="_blank"><span class="text">Подпишись на нас</span></a>
    </div>
</section>