/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReturnRequest } from '../models/return-request';
@Injectable({
  providedIn: 'root',
})
class ReturnRequestService extends __BaseService {
  static readonly returnRequestListListPath = '/return_request/list';
  static readonly returnRequestReadPath = '/return_request/{id}';
  static readonly returnRequestUpdatePath = '/return_request/{id}';
  static readonly returnRequestPartialUpdatePath = '/return_request/{id}';
  static readonly returnRequestConfirmCreatePath = '/return_request/{id}/confirm';
  static readonly returnRequestDeclineCreatePath = '/return_request/{id}/decline';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReturnRequestService.ReturnRequestListListParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `order_number`: order_number
   *
   * - `order_ids`: order_ids
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  returnRequestListListResponse(params: ReturnRequestService.ReturnRequestListListParams): __Observable<__StrictHttpResponse<Array<ReturnRequest>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.orderNumber != null) __params = __params.set('order_number', params.orderNumber.toString());
    if (params.orderIds != null) __params = __params.set('order_ids', params.orderIds.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.dateUpdatedTo != null) __params = __params.set('date_updated_to', params.dateUpdatedTo.toString());
    if (params.dateUpdatedFrom != null) __params = __params.set('date_updated_from', params.dateUpdatedFrom.toString());
    if (params.dateCreatedTo != null) __params = __params.set('date_created_to', params.dateCreatedTo.toString());
    if (params.dateCreatedFrom != null) __params = __params.set('date_created_from', params.dateCreatedFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/return_request/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReturnRequest>>;
      })
    );
  }
  /**
   * @param params The `ReturnRequestService.ReturnRequestListListParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `order_number`: order_number
   *
   * - `order_ids`: order_ids
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  returnRequestListList(params: ReturnRequestService.ReturnRequestListListParams): __Observable<Array<ReturnRequest>> {
    return this.returnRequestListListResponse(params).pipe(
      __map(_r => _r.body as Array<ReturnRequest>)
    );
  }

  /**
   * @param id A unique integer value identifying this return request.
   */
  returnRequestReadResponse(id: number): __Observable<__StrictHttpResponse<ReturnRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/return_request/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReturnRequest>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this return request.
   */
  returnRequestRead(id: number): __Observable<ReturnRequest> {
    return this.returnRequestReadResponse(id).pipe(
      __map(_r => _r.body as ReturnRequest)
    );
  }

  /**
   * @param params The `ReturnRequestService.ReturnRequestUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this return request.
   *
   * - `data`:
   */
  returnRequestUpdateResponse(params: ReturnRequestService.ReturnRequestUpdateParams): __Observable<__StrictHttpResponse<ReturnRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/return_request/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReturnRequest>;
      })
    );
  }
  /**
   * @param params The `ReturnRequestService.ReturnRequestUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this return request.
   *
   * - `data`:
   */
  returnRequestUpdate(params: ReturnRequestService.ReturnRequestUpdateParams): __Observable<ReturnRequest> {
    return this.returnRequestUpdateResponse(params).pipe(
      __map(_r => _r.body as ReturnRequest)
    );
  }

  /**
   * @param params The `ReturnRequestService.ReturnRequestPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this return request.
   *
   * - `data`:
   */
  returnRequestPartialUpdateResponse(params: ReturnRequestService.ReturnRequestPartialUpdateParams): __Observable<__StrictHttpResponse<ReturnRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/return_request/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReturnRequest>;
      })
    );
  }
  /**
   * @param params The `ReturnRequestService.ReturnRequestPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this return request.
   *
   * - `data`:
   */
  returnRequestPartialUpdate(params: ReturnRequestService.ReturnRequestPartialUpdateParams): __Observable<ReturnRequest> {
    return this.returnRequestPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as ReturnRequest)
    );
  }

  /**
   * @param id undefined
   */
  returnRequestConfirmCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/return_request/${encodeURIComponent(String(id))}/confirm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  returnRequestConfirmCreate(id: string): __Observable<null> {
    return this.returnRequestConfirmCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  returnRequestDeclineCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/return_request/${encodeURIComponent(String(id))}/decline`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  returnRequestDeclineCreate(id: string): __Observable<null> {
    return this.returnRequestDeclineCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReturnRequestService {

  /**
   * Parameters for returnRequestListList
   */
  export interface ReturnRequestListListParams {

    /**
     * status
     */
    status?: string;

    /**
     * order_number
     */
    orderNumber?: string;

    /**
     * order_ids
     */
    orderIds?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;

    /**
     * date_updated_to
     */
    dateUpdatedTo?: string;

    /**
     * date_updated_from
     */
    dateUpdatedFrom?: string;

    /**
     * date_created_to
     */
    dateCreatedTo?: string;

    /**
     * date_created_from
     */
    dateCreatedFrom?: string;
  }

  /**
   * Parameters for returnRequestUpdate
   */
  export interface ReturnRequestUpdateParams {

    /**
     * A unique integer value identifying this return request.
     */
    id: number;
    data: ReturnRequest;
  }

  /**
   * Parameters for returnRequestPartialUpdate
   */
  export interface ReturnRequestPartialUpdateParams {

    /**
     * A unique integer value identifying this return request.
     */
    id: number;
    data: ReturnRequest;
  }
}

export { ReturnRequestService }
