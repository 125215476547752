<section class="world">
    <div class="vector-1"></div>
    <div class="vector-2"></div>
    <div class="container">
        <h2 class="world-title section-title">
            Мир стал другим
        </h2>
        <p class="world-text">
            Этот год сильно повлиял на нашу жизнь, работу и эмоциональное состояние, но не стоит забывать, что важно уметь быстро адаптироваться под новые условия и определять свою новую роль в них. <br><br>
            Кондитерские изделия и хлеб – это то, что поддерживает и помогает в любые времена. 
            <br><br>
            Сообществу как никогда необходимо сплотиться, чтобы поддерживать и вдохновлять друг друга.
        </p>
        <div class="world-thesis">
            <h3 class="world-thesis-title">
                Что мы можем предложить нашему сообществу в этот непростой период?
            </h3>
            <p class="world-thesis-text desktop-block">
                Раскрыть важные темы, которые сегодня беспокоят всех, без исключения:
            </p>
            <p class="world-thesis-text mobile-block">
                Раскрыть важные темы, которые сегодня беспокоят всех, без исключения. Объедениться и общаться в живую на фестивале.
            </p>
        </div>
        <ul class="world-list">
            <li>
                <h4>ИМПОРТОЗАМЕЩЕНИЕ</h4>
                Чем заменяем импортные ингредиенты и как используем с преимуществом для себя
            </li>
            <li>
                <h4>ОПЫТ КОЛЛЕГ</h4>
Опыт и эффективные решения коллег помогут найти твердые решения и ответят на многие вопросы
            </li>
            <li>
                <h4>НОВАЯ ЭКОНОМИКА РАБОТЫ</h4>
                
Как оптимизировать себестоимость и настроить работу в новых условиях
            </li>
            <li>
                <h4>ОТКРЫВШИЕСЯ ПЕРСПЕКТИВЫ</h4>
                
Как их использовать, в каком направлении развиваться и станет ли для вас это точкой роста
            </li>
            <li>
                <h4>МОТИВАЦИЯ И ЛИЧНОЕ РАЗВИТИЕ</h4>
                
Вдохновляемся, обновляем расстановку приоритетов, экспериментируем, двигаемся к успеху
            </li>
            <li>
                <h4>ВЗАИМОДЕЙСТВИЕ</h4>
                
с новыми поставщиками, продуктом, с новыми и старыми знакомыми, рекомендации, обсуждение, новые идеи и полезный нетворкинг

            </li>
            <li>
                <h4>НОВАЯ КОММУНИКАЦИЯ</h4>
                
                Как и где продвигаться теперь, какие каналы будут эффективными
                
            </li>
            <li>
                <h4>ИНВЕСТИРОВАНИЕ<br>В СЕБЯ</h4>
                
Инвестировать в себя, в свое развитие и обучение, чтобы работать, сохранять и улучшать свое личное благосостояние

            </li>
            <li>
                <h4>ЕДИНСТВО И ТРАДИЦИИ</h4>
                
Вместе мы сохраним и продолжим традиции, найдем моральную поддержку и решение проблем. Наступило время общаться вживую

            </li>
        </ul>
    </div><!-- container -->
</section>