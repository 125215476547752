<mat-dialog-content class="mat-typography popup">
    <button mat-dialog-close class="mat-button-close">
        <svg enable-background="new 0 0 512.001 512.001" version="1.1" viewBox="0 0 512 512" xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m284.29 256 221.86-221.86c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0l-221.86 221.86-221.86-221.86c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.86 221.86-221.86 221.86c-7.811 7.811-7.811 20.475 0 28.285 3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857l221.86-221.86 221.86 221.86c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285l-221.86-221.86z" />
        </svg>
    </button>
    <div class="popup-content">
        <div class="imageBlock">
            <div class="post-img">
                <img *ngIf="post.image" loading="lazy" src="{{ post.image }}"
                    alt="{{ post.name }}" />
            </div>
        </div>
        <div class="textBlock">
            <p class="post-name">{{post.title}}</p>
            <p class="post-status">{{post.date}}</p>
            <p class="post-info" [innerHTML]="post.description"></p>
            <p class="post-info">Источник: <br>
                <a
                style="color: #b2a1ff"
                rel="noopener"
                href="{{ post['source_url'] }}"
                target="_blank"
                >{{ post['source_url'] }}</a
              >
            </p>
        </div>
        
        <!--<span>{{post.event_speakers}}</span>-->
    </div>
</mat-dialog-content>