<section class="price" id="price">
  <div class="container price__container blockWithShadow">
    <h2 class="section-title price__title">Что входит в билет:</h2>
    <ul class="price__list">
      <li>
        Все мероприятия программы в течение 3-х дней работы фестиваля 13-15 мая 2024г. с 10:00 до 18:00: это 45+ событий внутри проекта при участии более 50 именитых экспертов.
      </li><li>
        Свободное посещение всех 3-х локаций, где можно посетить любое из мероприятий программы и спланировать свой график, исходя из личных профессиональных интересов.
      </li><li>
        Практические мастер-классы, семинары, бизнес-разборы – лучшие знания от успешных практиков индустрии, которые применимы в работе. Известные эксперты проведут мероприятия на самые актуальные темы, которые будут полезны как кондитерам ресторанов, так и самозанятым.
      </li><li>
        Ежегодная ожидаемая встреча с единомышленниками и коллегами по цеху. Погружение в кондитерскую среду с первых минут пребывания на площадке. Атмосфера праздника и свободного общения, где эксперты также посещают мероприятия коллег и всегда готовы ответить вопросы или сфотографироваться.
      </li><li>
        Большая программа мероприятий от экспонентов, где можно познакомиться ближе с топовыми продуктами, узнать подробности применения новинок, снять «сливки» и получить новые актуальные знания.
      </li><li>
        Каждый участник проекта получит все рецепты экспертов, которые проведут мастер-классы в рамках форума. Настоящее руководство к действию и трендовые продающиеся десерты от «гуру» кондитерского искусства!
      </li><li>
        И внимание! Сюрприз от организаторов! Глобальный розыгрыш подарков от партнёров, экспонентов и экспертов проекта. Пока держим в секрете, но обязательно в ближайшее время анонсируем «вкусные» призы. *Будет доступно только обладателям входных билетов.
      </li>
    </ul>
  </div>
</section>
