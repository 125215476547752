/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PhotoAlbum } from '../models/photo-album';
import { PhotoCollection } from '../models/photo-collection';
@Injectable({
  providedIn: 'root',
})
class PhotoService extends __BaseService {
  static readonly photoAlbumListListPath = '/photo/album/list';
  static readonly photoAlbumReadPath = '/photo/album/{photoset_id}';
  static readonly photoCollectionListPath = '/photo/collection';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param photoset_id ids of albums
   */
  photoAlbumListListResponse(photosetId?: string): __Observable<__StrictHttpResponse<Array<PhotoAlbum>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (photosetId != null) __params = __params.set('photoset_id', photosetId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/photo/album/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PhotoAlbum>>;
      })
    );
  }
  /**
   * @param photoset_id ids of albums
   */
  photoAlbumListList(photosetId?: string): __Observable<Array<PhotoAlbum>> {
    return this.photoAlbumListListResponse(photosetId).pipe(
      __map(_r => _r.body as Array<PhotoAlbum>)
    );
  }

  /**
   * @param params The `PhotoService.PhotoAlbumReadParams` containing the following parameters:
   *
   * - `photoset_id`:
   *
   * - `per_page`: items per page
   *
   * - `page`: page number
   */
  photoAlbumReadResponse(params: PhotoService.PhotoAlbumReadParams): __Observable<__StrictHttpResponse<PhotoAlbum>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/photo/album/${encodeURIComponent(String(params.photosetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PhotoAlbum>;
      })
    );
  }
  /**
   * @param params The `PhotoService.PhotoAlbumReadParams` containing the following parameters:
   *
   * - `photoset_id`:
   *
   * - `per_page`: items per page
   *
   * - `page`: page number
   */
  photoAlbumRead(params: PhotoService.PhotoAlbumReadParams): __Observable<PhotoAlbum> {
    return this.photoAlbumReadResponse(params).pipe(
      __map(_r => _r.body as PhotoAlbum)
    );
  }

  /**
   * @param params The `PhotoService.PhotoCollectionListParams` containing the following parameters:
   *
   * - `flatten_tree`: flattern the tree, only if collection id not none
   *
   * - `collection_id`: collection id
   */
  photoCollectionListResponse(params: PhotoService.PhotoCollectionListParams): __Observable<__StrictHttpResponse<Array<PhotoCollection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.flattenTree != null) __params = __params.set('flatten_tree', params.flattenTree.toString());
    if (params.collectionId != null) __params = __params.set('collection_id', params.collectionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/photo/collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PhotoCollection>>;
      })
    );
  }
  /**
   * @param params The `PhotoService.PhotoCollectionListParams` containing the following parameters:
   *
   * - `flatten_tree`: flattern the tree, only if collection id not none
   *
   * - `collection_id`: collection id
   */
  photoCollectionList(params: PhotoService.PhotoCollectionListParams): __Observable<Array<PhotoCollection>> {
    return this.photoCollectionListResponse(params).pipe(
      __map(_r => _r.body as Array<PhotoCollection>)
    );
  }
}

module PhotoService {

  /**
   * Parameters for photoAlbumRead
   */
  export interface PhotoAlbumReadParams {
    photosetId: string;

    /**
     * items per page
     */
    perPage: number;

    /**
     * page number
     */
    page: number;
  }

  /**
   * Parameters for photoCollectionList
   */
  export interface PhotoCollectionListParams {

    /**
     * flattern the tree, only if collection id not none
     */
    flattenTree?: boolean;

    /**
     * collection id
     */
    collectionId?: string;
  }
}

export { PhotoService }
