/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TicketType } from '../models/ticket-type';
@Injectable({
  providedIn: 'root',
})
class TicketTypeService extends __BaseService {
  static readonly ticketTypeListListPath = '/ticket_type/list';
  static readonly ticketTypeListCreatePath = '/ticket_type/list';
  static readonly ticketTypeReadPath = '/ticket_type/{id}';
  static readonly ticketTypeUpdatePath = '/ticket_type/{id}';
  static readonly ticketTypePartialUpdatePath = '/ticket_type/{id}';
  static readonly ticketTypeDeletePath = '/ticket_type/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TicketTypeService.TicketTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketTypeListListResponse(params: TicketTypeService.TicketTypeListListParams): __Observable<__StrictHttpResponse<Array<TicketType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TicketType>>;
      })
    );
  }
  /**
   * @param params The `TicketTypeService.TicketTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  ticketTypeListList(params: TicketTypeService.TicketTypeListListParams): __Observable<Array<TicketType>> {
    return this.ticketTypeListListResponse(params).pipe(
      __map(_r => _r.body as Array<TicketType>)
    );
  }

  /**
   * @param data undefined
   */
  ticketTypeListCreateResponse(data: TicketType): __Observable<__StrictHttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketType>;
      })
    );
  }
  /**
   * @param data undefined
   */
  ticketTypeListCreate(data: TicketType): __Observable<TicketType> {
    return this.ticketTypeListCreateResponse(data).pipe(
      __map(_r => _r.body as TicketType)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket type.
   */
  ticketTypeReadResponse(id: number): __Observable<__StrictHttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketType>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket type.
   */
  ticketTypeRead(id: number): __Observable<TicketType> {
    return this.ticketTypeReadResponse(id).pipe(
      __map(_r => _r.body as TicketType)
    );
  }

  /**
   * @param params The `TicketTypeService.TicketTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket type.
   *
   * - `data`:
   */
  ticketTypeUpdateResponse(params: TicketTypeService.TicketTypeUpdateParams): __Observable<__StrictHttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ticket_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketType>;
      })
    );
  }
  /**
   * @param params The `TicketTypeService.TicketTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket type.
   *
   * - `data`:
   */
  ticketTypeUpdate(params: TicketTypeService.TicketTypeUpdateParams): __Observable<TicketType> {
    return this.ticketTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketType)
    );
  }

  /**
   * @param params The `TicketTypeService.TicketTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket type.
   *
   * - `data`:
   */
  ticketTypePartialUpdateResponse(params: TicketTypeService.TicketTypePartialUpdateParams): __Observable<__StrictHttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ticket_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketType>;
      })
    );
  }
  /**
   * @param params The `TicketTypeService.TicketTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket type.
   *
   * - `data`:
   */
  ticketTypePartialUpdate(params: TicketTypeService.TicketTypePartialUpdateParams): __Observable<TicketType> {
    return this.ticketTypePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketType)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket type.
   */
  ticketTypeDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ticket_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket type.
   */
  ticketTypeDelete(id: number): __Observable<null> {
    return this.ticketTypeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TicketTypeService {

  /**
   * Parameters for ticketTypeListList
   */
  export interface TicketTypeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for ticketTypeUpdate
   */
  export interface TicketTypeUpdateParams {

    /**
     * A unique integer value identifying this ticket type.
     */
    id: number;
    data: TicketType;
  }

  /**
   * Parameters for ticketTypePartialUpdate
   */
  export interface TicketTypePartialUpdateParams {

    /**
     * A unique integer value identifying this ticket type.
     */
    id: number;
    data: TicketType;
  }
}

export { TicketTypeService }
