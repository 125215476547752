<div class="b-partners-section">
  <div class="b-partners-section__container container" id="partners">
    <div class="b-partners-section__title">Партнеры 2024</div>
    <div class="b-partners-section__content b-partners-section__partners-content">
      
      <div class="b-partners-section__block" *ngIf="partners$ | async">
        <div class="b-partners-section__block-content b-partners-section__commercial">
          <!--<div class="b-partners-section__block-title">
            коммерческие
            партнеры
          </div>-->
          <ng-container *ngFor="let partner of partners">
            <div *ngIf="partner.logo" class="b-partners-section__block-icon">
              <a href="{{ partner.website }}" target="_blank" class="b-partners-section__partner-logo">
                <img src="{{ partner.logo.image }}" alt="{{ partner.name }}">
              </a>
              <div class="b-partners-section__partner-status">
                {{ partner.status }}
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="infoPartners.length" class="b-partners-section__block-content b-partners-section__info">
          <!--
          <div class="b-partners-section__block-title">
            генеральный информационный партнер
          </div>
          <ng-container *ngFor="let partner of infoBestPartners">
            <div *ngIf="partner.logo" class="b-partners-section__block-icon b-partners-section__block-icon--best">
              <a href="{{ partner.website }}" target="_blank" class="b-partners-section__partner-logo">
                <img src="{{ partner.logo.image }}" alt="{{ partner.name }}">
              </a>
            </div>
          </ng-container>
          -->
          <div class="b-partners-section__title b-partners-section__title--center">
            Информационные 
            партнеры 2024
          </div>
          <ng-container *ngFor="let partner of infoPartners">
            <div *ngIf="partner.logo" class="b-partners-section__block-icon">
              <a href="{{ partner.website }}" target="_blank" class="b-partners-section__partner-logo">
                <img src="{{ partner.logo.image }}" alt="{{ partner.name }}">
              </a>
              <div class="b-partners-section__partner-status">
                {{ partner.status }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="b-boothes-section" id="boothes">
      <div class="b-partners-section__content b-boothes-section__content">
        <div class="b-partners-section__title b-partners-section__title--center">Экспоненты 2024</div>
        <div class="b-partners-section__block b-boothes-section__block" *ngIf="boothes$ | async as boothes">
          <div class="b-partners-section__block-content">
            <ng-container *ngFor="let booth of boothes">
              <div *ngIf="booth.participation_booths[0].logo" class="b-partners-section__block-icon">
                <a href="{{ booth.participation_booths[0].website }}" target="_blank"
                  class="b-partners-section__partner-logo">
                  <img src="{{ booth.participation_booths[0].logo.image }}" alt="{{ booth.participation_booths[0].name }}">
                </a>
                <div class="b-partners-section__partner-status">
                  {{ booth.participation_booths[0].name }}
                </div>
                <app-booth-tooltip class="b-partners-section__tooltip" [booth]="booth"></app-booth-tooltip>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="b-boothes-section" id="boothes">
      <div class="b-partners-section__content b-boothes-section__content">
        <div class="b-partners-section__title">Информационные партнеры</div>
        <div class="b-partners-section__block b-boothes-section__block"  *ngIf="partners$ | async">
          <div class="b-boothes-section__block-content">
            <ng-container *ngFor="let partner of infoPartners">
              <div *ngIf="partner.logo" class="b-boothes-section__block-icon">
                <a href="{{ partner.website }}" target="_blank" class="b-partners-section__partner-logo">
                  <img src="{{ partner.logo.image }}" alt="{{ partner.name }}" class="b-partners-section__partner-image">
                </a>
                <div class="b-partners-section__partner-status">
                  {{ partner.status }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</div>