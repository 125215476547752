import { Component, Input, OnInit } from '@angular/core';
import { Booth } from '../../../../swagger/models/booth';

@Component({
  selector: 'app-booth-tooltip',
  templateUrl: './booth-tooltip.component.html',
  styleUrls: ['./booth-tooltip.component.scss'],
})
export class BoothTooltipComponent implements OnInit {
  @Input() booth: Booth;
  constructor() {}

  ngOnInit(): void {}
}
