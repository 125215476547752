import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-art-class-section',
  templateUrl: './art-class-section.component.html',
  styleUrls: ['./art-class-section.component.scss'],
})
export class ArtClassSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
