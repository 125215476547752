<div class="art-class-section" id="art-class">
  <div class="art-class-section__container">
    <div class="art-class-section__header">
      <div class="art-class-section__logo-container">
        <div class="art-class-section__logo academy-logo"></div>
        <div class="art-class-section__logo cakeshow-logo"></div>
      </div>
      <div class="art-class-section__header-title">
        <p>площадка</p>
        <h2>арт-класс</h2>
      </div>
    </div>
    <p class="art-class-section__text">
      В рамках проекта Moscow Cake Show состоялся Чемпионат России по кулинарии и сервису в категории кондитерское
      искусство, который прошел в трех номинациях в формате арт-класс. В этом году философия заданий позволила
      погрузиться в мир будущего и увидеть трансляцию, пожалуй, самой волнующей темы для всего человечества -
      экологии. По итогам было вручено 12 медалей разной степени ценности.
    </p>
    <p class="art-class-section__text">Поздравляем призеров Чемпионата:
    </p>
    <div class="art-class-section__themes">
      <p>Золотые медали получили:</p>
      <ul>
        <li>Подгорнова Дарья, в номинации «D2. Одноярусный торт»</li>
        <li>Лемешко Наталья номинация «D3. 3D торт»</li>
        <li>Омарова Патимат в номинации «D3. 3D торт»</li>
      </ul>
      <p>Серебряные медали получили:</p>
      <ul>
        <li>Фадеева Кристина в номинации «D3. 3D торт»</li>
        <li>Васильева Юлия в номинации «D3. 3D торт»</li>
        <li>Толстых Ирина номинация «D3. 3D торт»</li>
        <li> Озерова Мария в номинации «D1. Арт-работа»</li>
        <li>Дакаева Нагима номинация «D1. Арт-работа»</li>
        <li>Багадурова Заира в номинации «D1. Арт-работа»</li>
        <li>Нездойминова Екатерина номинация «D3. 3D торт»</li>
      </ul>
      <p>Бронзовые медали получили:</p>
      <ul>
        <li>Васильева Юлия номинация «D4. Свадебный торт»</li>
        <li>Рамазанова Изумруд в номинации «D4. Свадебный торт»</li>
      </ul>
    </div>
    <div class="art-class-section__buttons">
      <a class="art-class-section__button" [routerLink]="['/photo']">фото-отчет 2020</a>
    </div>
  </div>
  <div class="art-class-section__cake-img"></div>
</div>