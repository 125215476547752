import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { News } from '../../../../swagger/models/news';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as dayjs from 'dayjs';
import {Router} from "@angular/router";

@Component({
  selector: 'app-post-popup',
  templateUrl: './post-popup.component.html',
  styleUrls: ['./post-popup.component.scss'],
})
export class PostPopupComponent implements OnInit {
  constructor(private router: Router, private http: HttpClient, public dialogRed: MatDialogRef<PostPopupComponent>, @Inject(MAT_DIALOG_DATA) public post: News) {}

  API_URL = environment.apiV2_url;
  themes = new Array;
  area_events: any;
  queryParam: string;
  

  ngOnInit(): void {
    this.post.date = dayjs(this.post.date).format('DD.MM.YYYY');
    this.router.navigateByUrl('/all-posts?news='+this.post.id);
  }
  ngOnDestroy(): void {
    this.router.navigateByUrl('/all-posts');
  }
}

