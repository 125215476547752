/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BestPrice } from '../models/best-price';
import { BestPriceCreate } from '../models/best-price-create';
@Injectable({
  providedIn: 'root',
})
class BestpriceService extends __BaseService {
  static readonly bestpriceListListPath = '/bestprice/list';
  static readonly bestpriceListCreatePath = '/bestprice/list';
  static readonly bestpriceReadPath = '/bestprice/{id}';
  static readonly bestpriceUpdatePath = '/bestprice/{id}';
  static readonly bestpricePartialUpdatePath = '/bestprice/{id}';
  static readonly bestpriceDeletePath = '/bestprice/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BestpriceService.BestpriceListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  bestpriceListListResponse(params: BestpriceService.BestpriceListListParams): __Observable<__StrictHttpResponse<Array<BestPrice>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/bestprice/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BestPrice>>;
      })
    );
  }
  /**
   * @param params The `BestpriceService.BestpriceListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  bestpriceListList(params: BestpriceService.BestpriceListListParams): __Observable<Array<BestPrice>> {
    return this.bestpriceListListResponse(params).pipe(
      __map(_r => _r.body as Array<BestPrice>)
    );
  }

  /**
   * @param data undefined
   */
  bestpriceListCreateResponse(data: BestPriceCreate): __Observable<__StrictHttpResponse<BestPriceCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/bestprice/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BestPriceCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  bestpriceListCreate(data: BestPriceCreate): __Observable<BestPriceCreate> {
    return this.bestpriceListCreateResponse(data).pipe(
      __map(_r => _r.body as BestPriceCreate)
    );
  }

  /**
   * @param id undefined
   */
  bestpriceReadResponse(id: string): __Observable<__StrictHttpResponse<BestPrice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/bestprice/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BestPrice>;
      })
    );
  }
  /**
   * @param id undefined
   */
  bestpriceRead(id: string): __Observable<BestPrice> {
    return this.bestpriceReadResponse(id).pipe(
      __map(_r => _r.body as BestPrice)
    );
  }

  /**
   * @param params The `BestpriceService.BestpriceUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  bestpriceUpdateResponse(params: BestpriceService.BestpriceUpdateParams): __Observable<__StrictHttpResponse<BestPrice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/bestprice/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BestPrice>;
      })
    );
  }
  /**
   * @param params The `BestpriceService.BestpriceUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  bestpriceUpdate(params: BestpriceService.BestpriceUpdateParams): __Observable<BestPrice> {
    return this.bestpriceUpdateResponse(params).pipe(
      __map(_r => _r.body as BestPrice)
    );
  }

  /**
   * @param params The `BestpriceService.BestpricePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  bestpricePartialUpdateResponse(params: BestpriceService.BestpricePartialUpdateParams): __Observable<__StrictHttpResponse<BestPriceCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/bestprice/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BestPriceCreate>;
      })
    );
  }
  /**
   * @param params The `BestpriceService.BestpricePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  bestpricePartialUpdate(params: BestpriceService.BestpricePartialUpdateParams): __Observable<BestPriceCreate> {
    return this.bestpricePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BestPriceCreate)
    );
  }

  /**
   * @param id undefined
   */
  bestpriceDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/bestprice/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  bestpriceDelete(id: string): __Observable<null> {
    return this.bestpriceDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BestpriceService {

  /**
   * Parameters for bestpriceListList
   */
  export interface BestpriceListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for bestpriceUpdate
   */
  export interface BestpriceUpdateParams {
    id: string;
    data: BestPrice;
  }

  /**
   * Parameters for bestpricePartialUpdate
   */
  export interface BestpricePartialUpdateParams {
    id: string;
    data: BestPriceCreate;
  }
}

export { BestpriceService }
