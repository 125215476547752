import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expert-section',
  templateUrl: './experts-in-sweet-section.component.html',
  styleUrls: ['./experts-in-sweet-section.component.scss'],
})
export class ExpertsInSweetSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
