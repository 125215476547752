/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Booth } from '../models/booth';
import { BoothCreate } from '../models/booth-create';
import { BoothDetail } from '../models/booth-detail';
@Injectable({
  providedIn: 'root',
})
class BoothService extends __BaseService {
  static readonly boothListListPath = '/booth/list';
  static readonly boothListCreatePath = '/booth/list';
  static readonly boothReadPath = '/booth/{id}';
  static readonly boothUpdatePath = '/booth/{id}';
  static readonly boothPartialUpdatePath = '/booth/{id}';
  static readonly boothDeletePath = '/booth/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoothService.BoothListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  boothListListResponse(params: BoothService.BoothListListParams): __Observable<__StrictHttpResponse<Array<Booth>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Booth>>;
      })
    );
  }
  /**
   * @param params The `BoothService.BoothListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  boothListList(params: BoothService.BoothListListParams): __Observable<Array<Booth>> {
    return this.boothListListResponse(params).pipe(
      __map(_r => _r.body as Array<Booth>)
    );
  }

  /**
   * @param data undefined
   */
  boothListCreateResponse(data: BoothCreate): __Observable<__StrictHttpResponse<Booth>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/booth/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Booth>;
      })
    );
  }
  /**
   * @param data undefined
   */
  boothListCreate(data: BoothCreate): __Observable<Booth> {
    return this.boothListCreateResponse(data).pipe(
      __map(_r => _r.body as Booth)
    );
  }

  /**
   * @param id undefined
   */
  boothReadResponse(id: string): __Observable<__StrictHttpResponse<BoothDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  boothRead(id: string): __Observable<BoothDetail> {
    return this.boothReadResponse(id).pipe(
      __map(_r => _r.body as BoothDetail)
    );
  }

  /**
   * @param params The `BoothService.BoothUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothUpdateResponse(params: BoothService.BoothUpdateParams): __Observable<__StrictHttpResponse<BoothDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/booth/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothDetail>;
      })
    );
  }
  /**
   * @param params The `BoothService.BoothUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothUpdate(params: BoothService.BoothUpdateParams): __Observable<BoothDetail> {
    return this.boothUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothDetail)
    );
  }

  /**
   * @param params The `BoothService.BoothPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothPartialUpdateResponse(params: BoothService.BoothPartialUpdateParams): __Observable<__StrictHttpResponse<BoothDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/booth/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothDetail>;
      })
    );
  }
  /**
   * @param params The `BoothService.BoothPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothPartialUpdate(params: BoothService.BoothPartialUpdateParams): __Observable<BoothDetail> {
    return this.boothPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothDetail)
    );
  }

  /**
   * @param id undefined
   */
  boothDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/booth/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  boothDelete(id: string): __Observable<null> {
    return this.boothDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BoothService {

  /**
   * Parameters for boothListList
   */
  export interface BoothListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for boothUpdate
   */
  export interface BoothUpdateParams {
    id: string;
    data: BoothCreate;
  }

  /**
   * Parameters for boothPartialUpdate
   */
  export interface BoothPartialUpdateParams {
    id: string;
    data: BoothCreate;
  }
}

export { BoothService }
