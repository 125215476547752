import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupComponent } from '../../ui/popup/video/popup.component';
@Component({
  selector: 'app-main',
  templateUrl: './main-section.component.html',
  styleUrls: ['./main-section.component.scss'],
})
export class MainComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  openPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'video-popup';
    this.dialog.open(PopupComponent, dialogConfig);
  }


  ngOnInit() {}
}
