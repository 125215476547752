import { Component } from '@angular/core';

@Component({
  selector: 'app-ticket-price',
  templateUrl: './ticket-price.component.html',
  styleUrls: ['./ticket-price.component.scss'],
})
export class TicketPriceComponent {
  prices = [
    { price: 'price1', date: 'до 15 ноября' },/*
    { price: 'price2', date: 'С 15 ноября по 25 января' },
    { price: 'price3', date: 'С 26 января по 25 марта' },
    { price: 'price4', date: 'С 26 марта по 25 апреля' },
    { price: 'price5', date: 'С 26 апреля по 15 мая' }*/
  ];
}
