/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PromocodeList } from '../models/promocode-list';
@Injectable({
  providedIn: 'root',
})
class PromocodeListService extends __BaseService {
  static readonly promocodeListListListPath = '/promocode_list/list';
  static readonly promocodeListListCreatePath = '/promocode_list/list';
  static readonly promocodeListReadPath = '/promocode_list/{id}';
  static readonly promocodeListUpdatePath = '/promocode_list/{id}';
  static readonly promocodeListPartialUpdatePath = '/promocode_list/{id}';
  static readonly promocodeListDeletePath = '/promocode_list/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PromocodeListService.PromocodeListListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  promocodeListListListResponse(params: PromocodeListService.PromocodeListListListParams): __Observable<__StrictHttpResponse<Array<PromocodeList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/promocode_list/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PromocodeList>>;
      })
    );
  }
  /**
   * @param params The `PromocodeListService.PromocodeListListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  promocodeListListList(params: PromocodeListService.PromocodeListListListParams): __Observable<Array<PromocodeList>> {
    return this.promocodeListListListResponse(params).pipe(
      __map(_r => _r.body as Array<PromocodeList>)
    );
  }

  /**
   * @param data undefined
   */
  promocodeListListCreateResponse(data: PromocodeList): __Observable<__StrictHttpResponse<PromocodeList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/promocode_list/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromocodeList>;
      })
    );
  }
  /**
   * @param data undefined
   */
  promocodeListListCreate(data: PromocodeList): __Observable<PromocodeList> {
    return this.promocodeListListCreateResponse(data).pipe(
      __map(_r => _r.body as PromocodeList)
    );
  }

  /**
   * @param id A unique integer value identifying this promocode list.
   */
  promocodeListReadResponse(id: number): __Observable<__StrictHttpResponse<PromocodeList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/promocode_list/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromocodeList>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this promocode list.
   */
  promocodeListRead(id: number): __Observable<PromocodeList> {
    return this.promocodeListReadResponse(id).pipe(
      __map(_r => _r.body as PromocodeList)
    );
  }

  /**
   * @param params The `PromocodeListService.PromocodeListUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode list.
   *
   * - `data`:
   */
  promocodeListUpdateResponse(params: PromocodeListService.PromocodeListUpdateParams): __Observable<__StrictHttpResponse<PromocodeList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/promocode_list/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromocodeList>;
      })
    );
  }
  /**
   * @param params The `PromocodeListService.PromocodeListUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode list.
   *
   * - `data`:
   */
  promocodeListUpdate(params: PromocodeListService.PromocodeListUpdateParams): __Observable<PromocodeList> {
    return this.promocodeListUpdateResponse(params).pipe(
      __map(_r => _r.body as PromocodeList)
    );
  }

  /**
   * @param params The `PromocodeListService.PromocodeListPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode list.
   *
   * - `data`:
   */
  promocodeListPartialUpdateResponse(params: PromocodeListService.PromocodeListPartialUpdateParams): __Observable<__StrictHttpResponse<PromocodeList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/promocode_list/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromocodeList>;
      })
    );
  }
  /**
   * @param params The `PromocodeListService.PromocodeListPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode list.
   *
   * - `data`:
   */
  promocodeListPartialUpdate(params: PromocodeListService.PromocodeListPartialUpdateParams): __Observable<PromocodeList> {
    return this.promocodeListPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as PromocodeList)
    );
  }

  /**
   * @param id A unique integer value identifying this promocode list.
   */
  promocodeListDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/promocode_list/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this promocode list.
   */
  promocodeListDelete(id: number): __Observable<null> {
    return this.promocodeListDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PromocodeListService {

  /**
   * Parameters for promocodeListListList
   */
  export interface PromocodeListListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for promocodeListUpdate
   */
  export interface PromocodeListUpdateParams {

    /**
     * A unique integer value identifying this promocode list.
     */
    id: number;
    data: PromocodeList;
  }

  /**
   * Parameters for promocodeListPartialUpdate
   */
  export interface PromocodeListPartialUpdateParams {

    /**
     * A unique integer value identifying this promocode list.
     */
    id: number;
    data: PromocodeList;
  }
}

export { PromocodeListService }
