/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GreetingFilter } from '../models/greeting-filter';
import { Greeting } from '../models/greeting';
import { GreetingCreate } from '../models/greeting-create';
@Injectable({
  providedIn: 'root',
})
class GreetingService extends __BaseService {
  static readonly greetingFilterListPath = '/greeting/filter';
  static readonly greetingListListPath = '/greeting/list';
  static readonly greetingListCreatePath = '/greeting/list';
  static readonly greetingReadPath = '/greeting/{id}';
  static readonly greetingCreatePath = '/greeting/{id}';
  static readonly greetingUpdatePath = '/greeting/{id}';
  static readonly greetingPartialUpdatePath = '/greeting/{id}';
  static readonly greetingDeletePath = '/greeting/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GreetingService.GreetingFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `ids`: ids
   */
  greetingFilterListResponse(params: GreetingService.GreetingFilterListParams): __Observable<__StrictHttpResponse<GreetingFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/greeting/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GreetingFilter>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `ids`: ids
   */
  greetingFilterList(params: GreetingService.GreetingFilterListParams): __Observable<GreetingFilter> {
    return this.greetingFilterListResponse(params).pipe(
      __map(_r => _r.body as GreetingFilter)
    );
  }

  /**
   * @param params The `GreetingService.GreetingListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  greetingListListResponse(params: GreetingService.GreetingListListParams): __Observable<__StrictHttpResponse<Array<Greeting>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/greeting/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Greeting>>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  greetingListList(params: GreetingService.GreetingListListParams): __Observable<Array<Greeting>> {
    return this.greetingListListResponse(params).pipe(
      __map(_r => _r.body as Array<Greeting>)
    );
  }

  /**
   * @param data undefined
   */
  greetingListCreateResponse(data: GreetingCreate): __Observable<__StrictHttpResponse<GreetingCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/greeting/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GreetingCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  greetingListCreate(data: GreetingCreate): __Observable<GreetingCreate> {
    return this.greetingListCreateResponse(data).pipe(
      __map(_r => _r.body as GreetingCreate)
    );
  }

  /**
   * @param params The `GreetingService.GreetingReadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  greetingReadResponse(params: GreetingService.GreetingReadParams): __Observable<__StrictHttpResponse<Array<Greeting>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/greeting/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Greeting>>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingReadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  greetingRead(params: GreetingService.GreetingReadParams): __Observable<Array<Greeting>> {
    return this.greetingReadResponse(params).pipe(
      __map(_r => _r.body as Array<Greeting>)
    );
  }

  /**
   * @param params The `GreetingService.GreetingCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingCreateResponse(params: GreetingService.GreetingCreateParams): __Observable<__StrictHttpResponse<Greeting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/greeting/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Greeting>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingCreate(params: GreetingService.GreetingCreateParams): __Observable<Greeting> {
    return this.greetingCreateResponse(params).pipe(
      __map(_r => _r.body as Greeting)
    );
  }

  /**
   * @param params The `GreetingService.GreetingUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingUpdateResponse(params: GreetingService.GreetingUpdateParams): __Observable<__StrictHttpResponse<Greeting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/greeting/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Greeting>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingUpdate(params: GreetingService.GreetingUpdateParams): __Observable<Greeting> {
    return this.greetingUpdateResponse(params).pipe(
      __map(_r => _r.body as Greeting)
    );
  }

  /**
   * @param params The `GreetingService.GreetingPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingPartialUpdateResponse(params: GreetingService.GreetingPartialUpdateParams): __Observable<__StrictHttpResponse<Greeting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/greeting/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Greeting>;
      })
    );
  }
  /**
   * @param params The `GreetingService.GreetingPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  greetingPartialUpdate(params: GreetingService.GreetingPartialUpdateParams): __Observable<Greeting> {
    return this.greetingPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Greeting)
    );
  }

  /**
   * @param id undefined
   */
  greetingDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/greeting/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  greetingDelete(id: string): __Observable<null> {
    return this.greetingDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GreetingService {

  /**
   * Parameters for greetingFilterList
   */
  export interface GreetingFilterListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for greetingListList
   */
  export interface GreetingListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for greetingRead
   */
  export interface GreetingReadParams {
    id: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for greetingCreate
   */
  export interface GreetingCreateParams {
    id: string;
    data: Greeting;
  }

  /**
   * Parameters for greetingUpdate
   */
  export interface GreetingUpdateParams {
    id: string;
    data: Greeting;
  }

  /**
   * Parameters for greetingPartialUpdate
   */
  export interface GreetingPartialUpdateParams {
    id: string;
    data: Greeting;
  }
}

export { GreetingService }
