/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BoothType } from '../models/booth-type';
@Injectable({
  providedIn: 'root',
})
class BoothTypeService extends __BaseService {
  static readonly boothTypeListListPath = '/booth_type/list';
  static readonly boothTypeListCreatePath = '/booth_type/list';
  static readonly boothTypeReadPath = '/booth_type/{id}';
  static readonly boothTypeUpdatePath = '/booth_type/{id}';
  static readonly boothTypePartialUpdatePath = '/booth_type/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoothTypeService.BoothTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  boothTypeListListResponse(params: BoothTypeService.BoothTypeListListParams): __Observable<__StrictHttpResponse<Array<BoothType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BoothType>>;
      })
    );
  }
  /**
   * @param params The `BoothTypeService.BoothTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  boothTypeListList(params: BoothTypeService.BoothTypeListListParams): __Observable<Array<BoothType>> {
    return this.boothTypeListListResponse(params).pipe(
      __map(_r => _r.body as Array<BoothType>)
    );
  }

  /**
   * @param data undefined
   */
  boothTypeListCreateResponse(data: BoothType): __Observable<__StrictHttpResponse<BoothType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/booth_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothType>;
      })
    );
  }
  /**
   * @param data undefined
   */
  boothTypeListCreate(data: BoothType): __Observable<BoothType> {
    return this.boothTypeListCreateResponse(data).pipe(
      __map(_r => _r.body as BoothType)
    );
  }

  /**
   * @param id A unique integer value identifying this booth type.
   */
  boothTypeReadResponse(id: number): __Observable<__StrictHttpResponse<BoothType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothType>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this booth type.
   */
  boothTypeRead(id: number): __Observable<BoothType> {
    return this.boothTypeReadResponse(id).pipe(
      __map(_r => _r.body as BoothType)
    );
  }

  /**
   * @param params The `BoothTypeService.BoothTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth type.
   *
   * - `data`:
   */
  boothTypeUpdateResponse(params: BoothTypeService.BoothTypeUpdateParams): __Observable<__StrictHttpResponse<BoothType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/booth_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothType>;
      })
    );
  }
  /**
   * @param params The `BoothTypeService.BoothTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth type.
   *
   * - `data`:
   */
  boothTypeUpdate(params: BoothTypeService.BoothTypeUpdateParams): __Observable<BoothType> {
    return this.boothTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothType)
    );
  }

  /**
   * @param params The `BoothTypeService.BoothTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth type.
   *
   * - `data`:
   */
  boothTypePartialUpdateResponse(params: BoothTypeService.BoothTypePartialUpdateParams): __Observable<__StrictHttpResponse<BoothType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/booth_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothType>;
      })
    );
  }
  /**
   * @param params The `BoothTypeService.BoothTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth type.
   *
   * - `data`:
   */
  boothTypePartialUpdate(params: BoothTypeService.BoothTypePartialUpdateParams): __Observable<BoothType> {
    return this.boothTypePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothType)
    );
  }
}

module BoothTypeService {

  /**
   * Parameters for boothTypeListList
   */
  export interface BoothTypeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for boothTypeUpdate
   */
  export interface BoothTypeUpdateParams {

    /**
     * A unique integer value identifying this booth type.
     */
    id: number;
    data: BoothType;
  }

  /**
   * Parameters for boothTypePartialUpdate
   */
  export interface BoothTypePartialUpdateParams {

    /**
     * A unique integer value identifying this booth type.
     */
    id: number;
    data: BoothType;
  }
}

export { BoothTypeService }
