<footer class="b-footer" id="contacts">
  <div class="b-footer__contacts container">
    <div class="b-footer__person">
      <div class="b-footer__person-img b-footer__person-gurtseva"></div>
      <div class="b-footer__person-info">
        <b>Гурцева Валентина</b>
        <br>
        Руководитель проекта
      </div><!--
      <a href="tel:+7 495 637-94-40" class="b-footer__person-phone">+7 495 637-94-40 #&nbsp;126</a>-->
      <a href="mailto:valentina@cakeshow.ru" class="b-footer__person-email">valentina@cakeshow.ru</a>
    </div>
    <div class="b-footer__person">
      <div class="b-footer__person-img b-footer__person-kalantira"></div>
      <div class="b-footer__person-info">
        <b>
          Андрей Калантыра
        </b>
        <br>
        Руководитель отдела продаж
      </div>
      <a href="tel:+7 495 637-94-40" class="b-footer__person-phone">+7 495 637-94-40 #&nbsp;130</a>
      <a href="mailto:sale@cakeshow.ru" class="b-footer__person-email">sale@cakeshow.ru</a>
    </div>
    <div class="b-footer__person">
      <div class="b-footer__person-img b-footer__person-maksimova"></div>
      <div class="b-footer__person-info">
        <b>
          Юлия Максимова
        </b>
        <br>
        Менеджер проекта
      </div>
      <a href="tel:+7 495 637-94-40" class="b-footer__person-phone">+7 495 637-94-40 #&nbsp;128</a>
      <a href="mailto:j.maksimova@cakeshow.ru" class="b-footer__person-email">j.maksimova@cakeshow.ru</a>
    </div><!--
    <div class="b-footer__person b-footer__person-optional" >
      <div class="b-footer__subtitle">
        Аккредитация СМИ
      </div>
      <a href="mailto:orazovatanya.pmg@gmail.com" class="b-footer__person-email">orazovatanya.pmg@gmail.com</a>
    </div>
    -->
  </div>
  <div class="b-footer__container">
    <div class="b-footer__logo">
      <a href="https://pirexpo.com/" target="_blank"></a>
    </div>
    <ul class="b-footer__menu-list">
      <li class="b-footer__menu-item">
        <a href="https://cakeshow.ru/assets/files/postrelease2024.pdf">Релиз 2024</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#experts" pageScroll>Эксперты 2024</a>
      </li>
      <!--
      <li class="b-footer__menu-item">
        <a [routerLink]="['/program']" routerLinkActive="router-link-active">Программа</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#location" pageScroll>Место проведения</a>
      </li>
      <li class="b-footer__menu-item">
        <a href="/assets/files/hotels24.pdf" target="_blank">Где жить</a>
      </li>
    -->
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#partners" pageScroll>Партнеры 2024</a>
      </li> 
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#boothes" pageScroll>Экспоненты 2024</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/all-posts']" pageScroll>Новости</a>
      </li>
      <li class="b-footer__menu-item" *ngIf="!isBaseRoute()">
        <a [routerLink]="['/']" href="#contacts" pageScroll [pageScrollDuration]="700">Контакты</a>
      </li>
    </ul>
    <p class="copyright-text">&copy; Ярмарка ПИР , 1997-2024. ВСЕ ПРАВА ЗАЩИЩЕНЫ.
      <br>
      <a target="_blank" href="https://expov3.apiv2.pir.ru/media/uploads/2023/4/5/9a67e399-9099-4c71-bb40-b3a4b6b1f20f.pdf">Политика обработки персональных данных</a>
      <br>
      <a target="_blank" href="https://cakeshow.ru/assets/files/oferta.pdf">Публичная оферта</a>
    </p>
  </div>
</footer>