<div class="tooltip">
    <div class="tooltip__wrapper">
        <div class="tooltip__inner">
            <!-- <div class="tooltip__img">
                <img *ngIf="booth.participation_booths[0].logo" loading="lazy"
                    src="{{ booth.participation_booths[0].logo.image }}" alt="{{ booth.name }}" />
            </div> -->
            <div class="tooltip__text">
                <div>{{ booth.participation_booths[0].name }}</div>
            </div>
            <div class="tooltip__description" *ngIf="booth.participation_booths[0].description">
                <div [innerHTML]="booth.participation_booths[0].description"></div>
            </div>
        </div>
    </div>
</div>