<div class="b-art-class">
  <div class="b-art-class__container">
    <div class="b-art-class__content">
      <!-- <div class="b-art-class__title">
        <h1>Ждем вас на Moscow Cake Show</h1>
        <p>7-10 октября</p>
      </div> -->
      <!-- <div class="b-art-class__logo">
        <a class="b-art-class__logo-link" href="https://pirexpo.com/" target="_blank"></a>
        <p>7-10 октября</p>
        <p>Москва | Крокус Экспо</p>
      </div> -->
      <a class="b-art-class__header-title" routerLink="/"></a>

      <div class="b-art-class__text-block">
        <p class="b-art-class__text-title b-art-class__text-title--bold">чемпионату быть!</p>
        <p class="b-art-class__text">Вы уже участвовали в соревнованиях кондитеров? Или хотите попробовать свои силы?
          Ваш конёк – свадебные торты или шоколадные фигуры? Мы ждём каждого на Чемпионате России по кондитерскому
          искусству!
        </p>
        <p class="b-art-class__text"><b>7-10 октября</b> на ПИР Экспо пройдут соревнования, в котором сразятся лучшие
          кондитеры. Всего представлено 5 номинаций. Победители каждой из номинаций получат медали и награды.</p>
        <p class="b-art-class__text"><b>Тема нашей экспозиции – современное искусство.</b> Расширяйте границы вашего
          представления и покажите всем, на что вы способны!</p>
        <p class="b-art-class__sub-title">какие номинации вас ждут?
          <span class="b-art-class__mobile-image"></span>
        </p>
        <p class="b-art-class__text b-art-class__text--check"><span>Работа из карамели</span> Работа подразумевает использование не менее двух техник: сахарные
          формы, ливная, пластичная, суфле-техники.</p>
        <p class="b-art-class__text b-art-class__text--check"><span>Работа из шоколада</span> Художественная скульптура, не допускающая использование
          каркасов и поддерживающих
          конструкций!</p>
        <p class="b-art-class__text b-art-class__text--check"><span>Торт с 3D сахарными фигурами</span> Все элементы декорации торта, за исключением
          основания, должны быть изготовлены
          из съедобных материалов.</p>
        <p class="b-art-class__text b-art-class__text--check">
          <span>Свадебный торт</span> Выставляется готовый свадебный торт не менее двух ярусов. Допускается поддерживающая
          конструкция, высота – не ограничена!</p>
        <p class="b-art-class__text b-art-class__text--check">
          <span>Сахарные цветы</span> Выставляется съедобный цветочный букет или композиция из четырех различных видов цветов.
          Цветы должны быть представлены на подходящем композиции фоне.</p>
        <p class="b-art-class__text">Интересно? Тогда читайте полное описание условий во вложении и скорее подавайте
          заявки!<br>
          <b>Срок подачи заявок – до 15 сентября.</b></p>
      </div>
      <div class="b-art-class__button-container">
        <a class="b-art-class__b-button" href="../../assets/files/Формула_арт_класса.doc">Скачать условия участия</a>
        <a class="b-art-class__b-button" href="../../assets/files/Заявка_ на_ арт_класс.doc">скачать заявку</a>
      </div>
      <p class="b-art-class__text b-art-class__text--small">По всем вопросам обращайтесь к Марии Белкиной,<br>
        e-mail: <a href="mailto:m.belkina@pir.ru">m.belkina@pir.ru</a>,<br>
        тел. <a href="tel:89253388723">89253388723</a>
      </p>
    </div>
  </div>
