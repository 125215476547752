<div class="b-program-section">
  <div class="b-program-section__left-top-side"></div>
  <div class="b-program-section__left-center-side"></div>
  <div class="b-program-section__right-side"></div>
  <div class="b-program-section__right-side-mobile"></div>
  <div class="b-program-section__container">
    <div class="b-program-section__content">
      <p class="b-program-section__text-title b-program-section__text-title--bold">программа</p>
      <div class="b-program-section__list">
        <div class="b-program-section__list-item">
          <div class="b-program-section__item-text">4 площадки выставки охватывают все сферы кондитерского и пекарного бизнеса. 
            А на десерт – целый день, посвященный мастер-классам для желающих открыть свое дело – 
            все мероприятия этого дня входят в стоимость одного билета! 
        </div>
        <div class="b-program-section__list-item">
          <div class="b-program-section__item-title">Творчество</div>
          <div class="b-program-section__item-text">Главная площадка выставки. Гиганты индустрии из России и зарубежья расскажут о мировых трендах и самых популярных направлениях кондитерского искусства. Личный опыт и идеи для профессионального роста от кондитеров самых дорогих и престижных заведений.
          </div>
        </div>
        <div class="b-program-section__list-item">
          <div class="b-program-section__item-title">Вкус</div>
          <div class="b-program-section__item-text">Что придется по вкусу российскому клиенту? Мы расскажем и покажем самые эффективные методы по привлечению гостю. Одна из ключевых тем этой площадки – хлебное ремесло. Мы рассмотрим, что из себя представляет современный подход к традиционному производству выпечки и десертов.
          </div>
        </div>
        <div class="b-program-section__list-item">
          <div class="b-program-section__item-title">Бизнес</div>
          <div class="b-program-section__item-text">На чем строится кондитерский бизнес? Будь это цех или ресторан – это живой организм, который требует ежедневного внимания. Настраиваем работу команды, налаживаем продажи и учимся обновлять ассортимент. Эксперты различных сфер кондитерского бизнеса разложат по полочкам знания о внутренней кухне заведений.
          </div>
        </div>
        <div class="b-program-section__list-item">
          <div class="b-program-section__item-title">Мастерство</div>
          <div class="b-program-section__item-text">Интерактивная площадка. Профессиональные уроки по декорированию кондитерских изделий и хлеба. Здесь Вы своими руками и под чутким руководством мастеров приготовите своё блюдо и зададите все волнующие вопросы.
          </div>
        </div>
        <a class="b-program-section__b-button" href="https://pirexpo.com/events/program/exhibitions%255B%255D=d6da191e-eca4-11e8-8180-000c29f15ff6&areas%255B%255D=918e116d-9dab-11e9-be34-000c29f15ff6&areas%255B%255D=8b2b3dcd-9dab-11e9-be34-000c29f15ff6&areas%255B%255D=99d8deed-9dab-11e9-be34-000c29f15ff6&areas%255B%255D=fb9d90ad-9d95-11e9-be34-000c29f15ff6" target="_blank">подробнее</a>
      </div>
    </div>
  </div>
</div>