/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonalVideo } from '../models/personal-video';
@Injectable({
  providedIn: 'root',
})
class WebhooksService extends __BaseService {
  static readonly webhooksMasspersEventListPath = '/webhooks/masspers/event';
  static readonly webhooksMasspersVideoListListPath = '/webhooks/masspers/video/list';
  static readonly webhooksMasspersVideoListCreatePath = '/webhooks/masspers/video/list';
  static readonly webhooksMasspersVideoGenerateCreatePath = '/webhooks/masspers/video/{id}/generate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WebhooksService.WebhooksMasspersEventListParams` containing the following parameters:
   *
   * - `id`: id of video
   *
   * - `action`: action name (generate, error, open, view, click_1)
   *
   * - `message`: message of the error
   *
   * - `landing_url`: landing_url from generate action
   */
  webhooksMasspersEventListResponse(params: WebhooksService.WebhooksMasspersEventListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.action != null) __params = __params.set('action', params.action.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.landingUrl != null) __params = __params.set('landing_url', params.landingUrl.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/webhooks/masspers/event`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WebhooksService.WebhooksMasspersEventListParams` containing the following parameters:
   *
   * - `id`: id of video
   *
   * - `action`: action name (generate, error, open, view, click_1)
   *
   * - `message`: message of the error
   *
   * - `landing_url`: landing_url from generate action
   */
  webhooksMasspersEventList(params: WebhooksService.WebhooksMasspersEventListParams): __Observable<null> {
    return this.webhooksMasspersEventListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WebhooksService.WebhooksMasspersVideoListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  webhooksMasspersVideoListListResponse(params: WebhooksService.WebhooksMasspersVideoListListParams): __Observable<__StrictHttpResponse<Array<PersonalVideo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/webhooks/masspers/video/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonalVideo>>;
      })
    );
  }
  /**
   * @param params The `WebhooksService.WebhooksMasspersVideoListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  webhooksMasspersVideoListList(params: WebhooksService.WebhooksMasspersVideoListListParams): __Observable<Array<PersonalVideo>> {
    return this.webhooksMasspersVideoListListResponse(params).pipe(
      __map(_r => _r.body as Array<PersonalVideo>)
    );
  }

  /**
   * @param data undefined
   */
  webhooksMasspersVideoListCreateResponse(data: PersonalVideo): __Observable<__StrictHttpResponse<PersonalVideo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/webhooks/masspers/video/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonalVideo>;
      })
    );
  }
  /**
   * @param data undefined
   */
  webhooksMasspersVideoListCreate(data: PersonalVideo): __Observable<PersonalVideo> {
    return this.webhooksMasspersVideoListCreateResponse(data).pipe(
      __map(_r => _r.body as PersonalVideo)
    );
  }

  /**
   * @param id undefined
   */
  webhooksMasspersVideoGenerateCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/webhooks/masspers/video/${encodeURIComponent(String(id))}/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  webhooksMasspersVideoGenerateCreate(id: string): __Observable<null> {
    return this.webhooksMasspersVideoGenerateCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WebhooksService {

  /**
   * Parameters for webhooksMasspersEventList
   */
  export interface WebhooksMasspersEventListParams {

    /**
     * id of video
     */
    id: string;

    /**
     * action name (generate, error, open, view, click_1)
     */
    action: string;

    /**
     * message of the error
     */
    message?: string;

    /**
     * landing_url from generate action
     */
    landingUrl?: string;
  }

  /**
   * Parameters for webhooksMasspersVideoListList
   */
  export interface WebhooksMasspersVideoListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }
}

export { WebhooksService }
