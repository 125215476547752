/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventSpeakerType } from '../models/event-speaker-type';
@Injectable({
  providedIn: 'root',
})
class EventSpeakerTypeService extends __BaseService {
  static readonly eventSpeakerTypeListListPath = '/event_speaker_type/list';
  static readonly eventSpeakerTypeListCreatePath = '/event_speaker_type/list';
  static readonly eventSpeakerTypeReadPath = '/event_speaker_type/{id}';
  static readonly eventSpeakerTypeUpdatePath = '/event_speaker_type/{id}';
  static readonly eventSpeakerTypePartialUpdatePath = '/event_speaker_type/{id}';
  static readonly eventSpeakerTypeDeletePath = '/event_speaker_type/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  eventSpeakerTypeListListResponse(params: EventSpeakerTypeService.EventSpeakerTypeListListParams): __Observable<__StrictHttpResponse<Array<EventSpeakerType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_speaker_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventSpeakerType>>;
      })
    );
  }
  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  eventSpeakerTypeListList(params: EventSpeakerTypeService.EventSpeakerTypeListListParams): __Observable<Array<EventSpeakerType>> {
    return this.eventSpeakerTypeListListResponse(params).pipe(
      __map(_r => _r.body as Array<EventSpeakerType>)
    );
  }

  /**
   * @param data undefined
   */
  eventSpeakerTypeListCreateResponse(data: EventSpeakerType): __Observable<__StrictHttpResponse<EventSpeakerType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event_speaker_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSpeakerType>;
      })
    );
  }
  /**
   * @param data undefined
   */
  eventSpeakerTypeListCreate(data: EventSpeakerType): __Observable<EventSpeakerType> {
    return this.eventSpeakerTypeListCreateResponse(data).pipe(
      __map(_r => _r.body as EventSpeakerType)
    );
  }

  /**
   * @param id undefined
   */
  eventSpeakerTypeReadResponse(id: string): __Observable<__StrictHttpResponse<EventSpeakerType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event_speaker_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSpeakerType>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventSpeakerTypeRead(id: string): __Observable<EventSpeakerType> {
    return this.eventSpeakerTypeReadResponse(id).pipe(
      __map(_r => _r.body as EventSpeakerType)
    );
  }

  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSpeakerTypeUpdateResponse(params: EventSpeakerTypeService.EventSpeakerTypeUpdateParams): __Observable<__StrictHttpResponse<EventSpeakerType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/event_speaker_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSpeakerType>;
      })
    );
  }
  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypeUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSpeakerTypeUpdate(params: EventSpeakerTypeService.EventSpeakerTypeUpdateParams): __Observable<EventSpeakerType> {
    return this.eventSpeakerTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as EventSpeakerType)
    );
  }

  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSpeakerTypePartialUpdateResponse(params: EventSpeakerTypeService.EventSpeakerTypePartialUpdateParams): __Observable<__StrictHttpResponse<EventSpeakerType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/event_speaker_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSpeakerType>;
      })
    );
  }
  /**
   * @param params The `EventSpeakerTypeService.EventSpeakerTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSpeakerTypePartialUpdate(params: EventSpeakerTypeService.EventSpeakerTypePartialUpdateParams): __Observable<EventSpeakerType> {
    return this.eventSpeakerTypePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as EventSpeakerType)
    );
  }

  /**
   * @param id undefined
   */
  eventSpeakerTypeDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/event_speaker_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventSpeakerTypeDelete(id: string): __Observable<null> {
    return this.eventSpeakerTypeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EventSpeakerTypeService {

  /**
   * Parameters for eventSpeakerTypeListList
   */
  export interface EventSpeakerTypeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for eventSpeakerTypeUpdate
   */
  export interface EventSpeakerTypeUpdateParams {
    id: string;
    data: EventSpeakerType;
  }

  /**
   * Parameters for eventSpeakerTypePartialUpdate
   */
  export interface EventSpeakerTypePartialUpdateParams {
    id: string;
    data: EventSpeakerType;
  }
}

export { EventSpeakerTypeService }
