<header class="b-nav">
  <div class="b-nav__container">
    <nav class="b-nav__content">
      <a class="logo" routerLink="/"></a>
      <ul class="b-nav__list">
        
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#experts" pageScroll [pageScrollDuration]="700">Эксперты 2024</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#boothes" pageScroll [pageScrollDuration]="700">Экспоненты 2024</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#partners" pageScroll [pageScrollDuration]="700">Партнеры 2024</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/photo']" routerLinkActive="active">Как это было</a>
        </li>
        <!--
        -->
        <!--
        <li class="b-nav__list-item">
          <a [routerLink]="['/program']" routerLinkActive="active">Программа</a>
        </li>
        <li class="b-nav__list-item b-nav__list-item--desktop">
          <a [routerLink]="['/']" href="#location" pageScroll [pageScrollDuration]="700">Место проведения</a>
        </li>
        <li class="b-nav__list-item">
          <a href="/assets/files/hotels24.pdf" target="_blank">Где жить</a>
        </li>
        -->
        <li class="b-nav__list-item">
          <a [routerLink]="['/all-posts']" routerLinkActive="active">Новости</a>
        </li>
        <!--
        <li class="b-nav__list-item">
          <a href="https://cakeshow.ru/assets/files/cakeshow_3003.pdf">Где жить</a>
        </li>-->
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#contacts" pageScroll [pageScrollDuration]="700">Контакты</a>
        </li>
      </ul>
      <!-- b-nav__list -->
      <!--
      <div class="b-nav__phone">
        <a href="tel:+7 (495) 637-94-40">+7 (495) 637-94-40</a>
      </div>
      -->
      <div class="buy-ticket">
        <!-- <a id="timepad_twf_register_2272144"> -->
        <a id="pirexpo-ticket-widget" class="button button--magenta" data-pirexpo-widget-shop-id="2">
          <span class="ticket-text text">Купить билет</span>
          <!--<span class="ticket-icon">14+</span>-->
        </a>
      </div>
      <!-- buy-ticket -->
    </nav>
    <!-- b-nav__content -->
  </div>
  <!-- b-nav__container -->
  <div class="b-nav__container-mobile">
    <div class="b-nav__header-mobile">
      <a class="b-nav__header-title" routerLink="/"></a>
      <div class="b-nav__side-menu-wrapper" [ngClass]="{ 'active-wrapper': showMenu }">
        <div id="burger-menu-icon" class="b-nav__burger-icon" [ngClass]="{ 'is-active': showMenu }" (click)="openMenu()">
          <span class="b-nav__burger-pin"></span>
          <span class="b-nav__burger-pin"></span>
        </div>
        <div class="b-nav__side-menu" [ngClass]="{ 'active-side-menu': showMenu }">
          <ul class="b-nav__side-menu-list">
            
            <li><a [routerLink]="['/']" href="#experts" pageScroll (click)="closeMenu()">Эксперты 2024</a></li>
            <li><a [routerLink]="['/']" href="#boothes" pageScroll (click)="closeMenu()">Экспоненты 2024</a></li>
            <li><a [routerLink]="['/']" href="#partners" pageScroll (click)="closeMenu()">Партнеры 2024</a></li>
            <li><a [routerLink]="['/photo']" routerLinkActive="router-link-active" (click)="closeMenu()">Как это было</a></li>
            <!--
            <li><a [routerLink]="['/program']" routerLinkActive="router-link-active" (click)="closeMenu()">Программа</a>
            </li>
            <li><a [routerLink]="['/']" href="#location" pageScroll (click)="closeMenu()">Место проведения</a>
            <li>
              <a target="_blank" href="/assets/files/hotels24.pdf" (click)="closeMenu()">Где жить</a>
            </li>
              -->
            <li><a [routerLink]="['/all-posts']" routerLinkActive="router-link-active" (click)="closeMenu()">Новости</a></li>
            <!--
              <li><a  href="https://cakeshow.ru/assets/files/cakeshow_3003.pdf">Где жить</a>
            </li>
            -->
            <li><a href="#contacts" (click)="closeMenu()">Контакты</a></li>
          </ul>
        </div>
        <!-- b-nav__side-menu -->
      </div>
      <!-- b-nav__side-menu-wrapper -->
    </div>
    <!-- b-nav__header-mobile-->
    <div class="buy-ticket">
      <!-- <a id="timepad_twf_register_2272144"> -->
      <a id="pirexpo-ticket-widget" class="button button-magenta" data-pirexpo-widget-shop-id="2">
        <span class="ticket-text text">Купить билет</span>
        <!--<span class="ticket-icon">14+</span>-->
      </a>
    </div>
    <!-- buy-ticket -->
  </div>
  <!-- b-nav__container-mobile -->
</header>
