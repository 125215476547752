<div class="b-boothes-section">
    <div class="b-boothes-section__container" id="boothes">
        <div class="b-boothes-section__content">
            <div class="b-boothes-section__title">Экспоненты 2024</div>
            <div class="b-boothes-section__block" *ngIf="boothes$ | async as boothes">
                <div class="b-boothes-section__block-content">
                    <ng-container *ngFor="let booth of boothes">
                        <div *ngIf="booth.participation_booths[0].logo" class="b-boothes-section__block-icon">
                            <a href="{{ booth.participation_booths[0].website }}" target="_blank"
                                class="b-boothes-section__partner-logo">
                                <img src="{{ booth.participation_booths[0].logo.image }}" alt="{{ booth.participation_booths[0].name }}">
                            </a>
                            <div class="b-boothes-section__partner-status">
                                {{ booth.participation_booths[0].name }}
                            </div>
                            <app-booth-tooltip class="b-boothes-section__tooltip" [booth]="booth"></app-booth-tooltip>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>