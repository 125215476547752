<div class="tooltip">
  <div class="tooltip__wrapper">
    <div class="tooltip__inner">
      <div class="tooltip__text">
        <div>Подробнее</div>
      </div>
      <div *ngIf="value" class="tooltip__description">
        <div [innerHTML]="value.description"></div>
      </div>
    </div>
  </div>
</div>
