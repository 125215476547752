import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProgramPopupComponent } from '../../ui/popup/program-popup/program-popup.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public showMenu: boolean;
  public targetElement;
  @ViewChild('timepadButton', { read: ElementRef }) timepadButton: ElementRef;
  queryParam: string;

  openMenu() {
    this.showMenu = !this.showMenu;
    // disableBodyScroll(this.targetElement);
  }

  closeMenu() {
    this.showMenu = !this.showMenu;
    // enableBodyScroll(this.targetElement);
    // clearAllBodyScrollLocks();
  }

  isHomeRoute() {
    return this.router.url === '/' || this.router.url === '/?buyTicket=true';
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.targetElement = document.querySelector('#nav');
    }

    this.route.queryParams.subscribe((params) => {
      this.queryParam = params['buyTicket'];
      this.queryParam === 'true' && this.timepadButton ? this.getVirtualClick() : '';
    });
  }

  getVirtualClick(): void {
    setTimeout(() => {
      this.timepadButton.nativeElement.click();
    }, 1000);
  }

  public triggerScrollTo(element: string) {
    if (this.router.url !== '/') {
      this.router.navigateByUrl('/');
      this.router.events.pipe(take(15)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            document.getElementById(element).scrollIntoView({ block: 'start' });
          });
        }
      });
    } else {
      document.getElementById(element).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  openProgramPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'program-popup';
    this.dialog.open(ProgramPopupComponent, dialogConfig);
  }
}
