import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllExpertsComponent } from './pages/all-experts/all-experts.component';
import { AllPostsComponent } from './pages/all-posts/all-posts.component';
import { ArtClassPageComponent } from './pages/art-class/art-class-page.component';
import { MainPageComponent } from './pages/main/main-page.component';
import { PhotoGalleryComponent } from './pages/photo-gallery/photo-gallery.component';
import { ProgramComponent } from './pages/program/program.component';

const routes: Routes = [
  { path: '', component: MainPageComponent },
  { path: 'art-class', component: ArtClassPageComponent },
  { path: 'photo', component: PhotoGalleryComponent },
  { path: 'all-experts', component: AllExpertsComponent },
  { path: 'all-posts', component: AllPostsComponent },
  { path: 'program', component: ProgramComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
