import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExpertPopupComponent } from '../../ui/popup/expert-popup/expert-popup.component';
import { Person } from '../../../swagger/models';
import { PersonService } from '../../../swagger/services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-experts-section',
  templateUrl: './experts-section.component.html',
  styleUrls: ['./experts-section.component.scss'],
})
export class ExpertsSectionComponent implements OnInit {
  API_URL = environment.api_url;
  exhibition = environment.exhibition_id;
  themeId = environment.themeId;
  experts$: Observable<Person[]>;
  slideIndex = 6;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    // "variableWidth": true,
    cssEase: 'ease-in-out',
    fade: true,
    speed: 500,
    prevArrow: '.speaker-prev-arrow',
    nextArrow: '.speaker-next-arrow',
    asNavFor: '.dotsSlider',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: false,
          draggable: true,
          centerMode: true,
          fade: true,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          fade: true,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          fade: false,
          centerPadding: '0px',
        },
      },
    ],
  };
  slideConfigNav = {
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    focusOnSelect: true,
    asNavFor: '.photoSlider',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          draggable: true,
        },
      },
    ],
  };

  constructor(private personService: PersonService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.experts$ = this.personService.personListList({ theme: this.themeId }).pipe(
      map((experts) => {
        experts.sort((a, b) => {
          if (a.secondname < b.secondname) {
            return -1;
          }
        });
        return experts;
      })
    );
  }
  openPopup(expert: Person) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = expert;
    dialogConfig.panelClass = 'expert-popup';
    let dialogRef = this.dialog.open(ExpertPopupComponent, dialogConfig);
  }

  afterChange(event): void {
    this.slideIndex += 3;
  }
}
