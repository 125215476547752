<div class="b-get-section">
  <div class="b-get-section__left-side"></div>
  <div class="b-get-section__right-side"></div>
  <div class="b-get-section__right-side-mobile"></div>
  <div class="b-get-section__container">
    <div class="b-get-section__content">
      <p class="b-get-section__text-title">Что вы</p>
      <p class="b-get-section__text-title b-get-section__text-title--bold">
        получаете
      </p>
      <div class="b-get-section__list">
        <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">Новые рецепты и техники</div>
          <div class="b-get-section__item-text">
            Мы пригласили лучших из лучших специалистов кондитерского и
            пекарского бизнеса, которые раскроют свои секреты.
          </div>
        </div>
        <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">
            Свой собственный шедевр уже сегодня
          </div>
          <div class="b-get-section__item-text">
            На интерактивных площадках вы сможете создавать свои десерты вместе
            с мастерами, сделать работу над ошибками и получить личные
            рекомендации.
          </div>
        </div>
        <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">
            Общение на одной волне
          </div>
          <div class="b-get-section__item-text">
            Мы ожидаем более 3000 посетителей – и это не предел! Вы сможете
            обменяться опытом с единомышленниками и завяжите полезные деловые
            контакты.
          </div>
        </div>
        <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">
            Соревнования кондитерских арт-работ
          </div>
          <div class="b-get-section__item-text">
            Попытайте свои силы в профессиональных соревнованиях! Наши дипломы
            говорят о многом как гостям вашего заведения, так и коллегам.
          </div>
        </div>
        <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">
            Большой ассортимент товаров
          </div>
          <div class="b-get-section__item-text">
            Все, что может понадобиться кондитеру и пекарю, и то, чего
            невозможно найти в других местах. Самые востребованные продукты от
            поставщиков напрямую.
          </div>
        </div>
        <!-- <div class="b-get-section__list-item">
          <div class="b-get-section__item-title">
            Большой ассортимент товаров
          </div>
          <div class="b-get-section__item-text">
            Все, что может понадобиться кондитеру и пекарю, и то, чего
            невозможно найти в других магазинах. Самые востребованные продукты
            от поставщиков напрямую.
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
