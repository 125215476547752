/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { YoutubePlaylistList } from '../models/youtube-playlist-list';
import { YoutubePlaylistListItemList } from '../models/youtube-playlist-list-item-list';
@Injectable({
  providedIn: 'root',
})
class YoutubeService extends __BaseService {
  static readonly youtubePlaylistsListPath = '/youtube/playlists';
  static readonly youtubeVideosReadPath = '/youtube/videos/{playlist_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param channel_id ids of albums
   */
  youtubePlaylistsListResponse(channelId?: string): __Observable<__StrictHttpResponse<YoutubePlaylistList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (channelId != null) __params = __params.set('channel_id', channelId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/youtube/playlists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<YoutubePlaylistList>;
      })
    );
  }
  /**
   * @param channel_id ids of albums
   */
  youtubePlaylistsList(channelId?: string): __Observable<YoutubePlaylistList> {
    return this.youtubePlaylistsListResponse(channelId).pipe(
      __map(_r => _r.body as YoutubePlaylistList)
    );
  }

  /**
   * @param playlist_id undefined
   */
  youtubeVideosReadResponse(playlistId: string): __Observable<__StrictHttpResponse<YoutubePlaylistListItemList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/youtube/videos/${encodeURIComponent(String(playlistId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<YoutubePlaylistListItemList>;
      })
    );
  }
  /**
   * @param playlist_id undefined
   */
  youtubeVideosRead(playlistId: string): __Observable<YoutubePlaylistListItemList> {
    return this.youtubeVideosReadResponse(playlistId).pipe(
      __map(_r => _r.body as YoutubePlaylistListItemList)
    );
  }
}

module YoutubeService {
}

export { YoutubeService }
