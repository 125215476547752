/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnswerOption } from '../models/answer-option';
@Injectable({
  providedIn: 'root',
})
class AnswerOptionService extends __BaseService {
  static readonly answerOptionListListPath = '/answer_option/list';
  static readonly answerOptionListCreatePath = '/answer_option/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AnswerOptionService.AnswerOptionListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  answerOptionListListResponse(params: AnswerOptionService.AnswerOptionListListParams): __Observable<__StrictHttpResponse<Array<AnswerOption>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/answer_option/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnswerOption>>;
      })
    );
  }
  /**
   * @param params The `AnswerOptionService.AnswerOptionListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  answerOptionListList(params: AnswerOptionService.AnswerOptionListListParams): __Observable<Array<AnswerOption>> {
    return this.answerOptionListListResponse(params).pipe(
      __map(_r => _r.body as Array<AnswerOption>)
    );
  }

  /**
   * @param data undefined
   */
  answerOptionListCreateResponse(data: AnswerOption): __Observable<__StrictHttpResponse<AnswerOption>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/answer_option/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnswerOption>;
      })
    );
  }
  /**
   * @param data undefined
   */
  answerOptionListCreate(data: AnswerOption): __Observable<AnswerOption> {
    return this.answerOptionListCreateResponse(data).pipe(
      __map(_r => _r.body as AnswerOption)
    );
  }
}

module AnswerOptionService {

  /**
   * Parameters for answerOptionListList
   */
  export interface AnswerOptionListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }
}

export { AnswerOptionService }
