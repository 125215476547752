/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SurveyResult } from '../models/survey-result';
@Injectable({
  providedIn: 'root',
})
class SurveyResultService extends __BaseService {
  static readonly surveyResultListListPath = '/survey_result/list';
  static readonly surveyResultListCreatePath = '/survey_result/list';
  static readonly surveyResultReadPath = '/survey_result/{id}';
  static readonly surveyResultUpdatePath = '/survey_result/{id}';
  static readonly surveyResultPartialUpdatePath = '/survey_result/{id}';
  static readonly surveyResultDeletePath = '/survey_result/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SurveyResultService.SurveyResultListListParams` containing the following parameters:
   *
   * - `survey`: survey
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  surveyResultListListResponse(params: SurveyResultService.SurveyResultListListParams): __Observable<__StrictHttpResponse<Array<SurveyResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.survey != null) __params = __params.set('survey', params.survey.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/survey_result/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SurveyResult>>;
      })
    );
  }
  /**
   * @param params The `SurveyResultService.SurveyResultListListParams` containing the following parameters:
   *
   * - `survey`: survey
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  surveyResultListList(params: SurveyResultService.SurveyResultListListParams): __Observable<Array<SurveyResult>> {
    return this.surveyResultListListResponse(params).pipe(
      __map(_r => _r.body as Array<SurveyResult>)
    );
  }

  /**
   * @param data undefined
   */
  surveyResultListCreateResponse(data: SurveyResult): __Observable<__StrictHttpResponse<SurveyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/survey_result/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SurveyResult>;
      })
    );
  }
  /**
   * @param data undefined
   */
  surveyResultListCreate(data: SurveyResult): __Observable<SurveyResult> {
    return this.surveyResultListCreateResponse(data).pipe(
      __map(_r => _r.body as SurveyResult)
    );
  }

  /**
   * @param id undefined
   */
  surveyResultReadResponse(id: string): __Observable<__StrictHttpResponse<SurveyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/survey_result/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SurveyResult>;
      })
    );
  }
  /**
   * @param id undefined
   */
  surveyResultRead(id: string): __Observable<SurveyResult> {
    return this.surveyResultReadResponse(id).pipe(
      __map(_r => _r.body as SurveyResult)
    );
  }

  /**
   * @param params The `SurveyResultService.SurveyResultUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyResultUpdateResponse(params: SurveyResultService.SurveyResultUpdateParams): __Observable<__StrictHttpResponse<SurveyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/survey_result/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SurveyResult>;
      })
    );
  }
  /**
   * @param params The `SurveyResultService.SurveyResultUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyResultUpdate(params: SurveyResultService.SurveyResultUpdateParams): __Observable<SurveyResult> {
    return this.surveyResultUpdateResponse(params).pipe(
      __map(_r => _r.body as SurveyResult)
    );
  }

  /**
   * @param params The `SurveyResultService.SurveyResultPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyResultPartialUpdateResponse(params: SurveyResultService.SurveyResultPartialUpdateParams): __Observable<__StrictHttpResponse<SurveyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/survey_result/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SurveyResult>;
      })
    );
  }
  /**
   * @param params The `SurveyResultService.SurveyResultPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyResultPartialUpdate(params: SurveyResultService.SurveyResultPartialUpdateParams): __Observable<SurveyResult> {
    return this.surveyResultPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as SurveyResult)
    );
  }

  /**
   * @param id undefined
   */
  surveyResultDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/survey_result/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  surveyResultDelete(id: string): __Observable<null> {
    return this.surveyResultDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SurveyResultService {

  /**
   * Parameters for surveyResultListList
   */
  export interface SurveyResultListListParams {

    /**
     * survey
     */
    survey?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for surveyResultUpdate
   */
  export interface SurveyResultUpdateParams {
    id: string;
    data: SurveyResult;
  }

  /**
   * Parameters for surveyResultPartialUpdate
   */
  export interface SurveyResultPartialUpdateParams {
    id: string;
    data: SurveyResult;
  }
}

export { SurveyResultService }
