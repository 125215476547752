/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Place } from '../models/place';
@Injectable({
  providedIn: 'root',
})
class PlaceService extends __BaseService {
  static readonly placeListListPath = '/place/list';
  static readonly placeListCreatePath = '/place/list';
  static readonly placeReadPath = '/place/{id}';
  static readonly placeUpdatePath = '/place/{id}';
  static readonly placePartialUpdatePath = '/place/{id}';
  static readonly placeDeletePath = '/place/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PlaceService.PlaceListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  placeListListResponse(params: PlaceService.PlaceListListParams): __Observable<__StrictHttpResponse<Array<Place>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/place/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Place>>;
      })
    );
  }
  /**
   * @param params The `PlaceService.PlaceListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  placeListList(params: PlaceService.PlaceListListParams): __Observable<Array<Place>> {
    return this.placeListListResponse(params).pipe(
      __map(_r => _r.body as Array<Place>)
    );
  }

  /**
   * @param data undefined
   */
  placeListCreateResponse(data: Place): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/place/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param data undefined
   */
  placeListCreate(data: Place): __Observable<Place> {
    return this.placeListCreateResponse(data).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param id undefined
   */
  placeReadResponse(id: string): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/place/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param id undefined
   */
  placeRead(id: string): __Observable<Place> {
    return this.placeReadResponse(id).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param params The `PlaceService.PlaceUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  placeUpdateResponse(params: PlaceService.PlaceUpdateParams): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/place/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param params The `PlaceService.PlaceUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  placeUpdate(params: PlaceService.PlaceUpdateParams): __Observable<Place> {
    return this.placeUpdateResponse(params).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param params The `PlaceService.PlacePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  placePartialUpdateResponse(params: PlaceService.PlacePartialUpdateParams): __Observable<__StrictHttpResponse<Place>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/place/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Place>;
      })
    );
  }
  /**
   * @param params The `PlaceService.PlacePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  placePartialUpdate(params: PlaceService.PlacePartialUpdateParams): __Observable<Place> {
    return this.placePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Place)
    );
  }

  /**
   * @param id undefined
   */
  placeDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/place/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  placeDelete(id: string): __Observable<null> {
    return this.placeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PlaceService {

  /**
   * Parameters for placeListList
   */
  export interface PlaceListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for placeUpdate
   */
  export interface PlaceUpdateParams {
    id: string;
    data: Place;
  }

  /**
   * Parameters for placePartialUpdate
   */
  export interface PlacePartialUpdateParams {
    id: string;
    data: Place;
  }
}

export { PlaceService }
