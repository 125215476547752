/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Cart } from '../models/cart';
import { AddCartItem } from '../models/add-cart-item';
import { ApplyPromocode } from '../models/apply-promocode';
import { Order } from '../models/order';
import { UpdateCartItem } from '../models/update-cart-item';
@Injectable({
  providedIn: 'root',
})
class CartService extends __BaseService {
  static readonly cartAddItemCreatePath = '/cart/add_item';
  static readonly cartApplyPromocodeCreatePath = '/cart/apply_promocode';
  static readonly cartCreateOrderCreatePath = '/cart/create_order';
  static readonly cartListListPath = '/cart/list';
  static readonly cartListCreatePath = '/cart/list';
  static readonly cartMyListPath = '/cart/my';
  static readonly cartReleasePromocodeCreatePath = '/cart/release_promocode';
  static readonly cartRemoveItemDeletePath = '/cart/remove_item/{item_id}';
  static readonly cartUpdateItemPartialUpdatePath = '/cart/update_item/{item_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  cartAddItemCreateResponse(data: AddCartItem): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cart/add_item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }
  /**
   * @param data undefined
   */
  cartAddItemCreate(data: AddCartItem): __Observable<Cart> {
    return this.cartAddItemCreateResponse(data).pipe(
      __map(_r => _r.body as Cart)
    );
  }

  /**
   * @param data undefined
   */
  cartApplyPromocodeCreateResponse(data: ApplyPromocode): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cart/apply_promocode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }
  /**
   * @param data undefined
   */
  cartApplyPromocodeCreate(data: ApplyPromocode): __Observable<Cart> {
    return this.cartApplyPromocodeCreateResponse(data).pipe(
      __map(_r => _r.body as Cart)
    );
  }
  cartCreateOrderCreateResponse(): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cart/create_order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }  cartCreateOrderCreate(): __Observable<Order> {
    return this.cartCreateOrderCreateResponse().pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `CartService.CartListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  cartListListResponse(params: CartService.CartListListParams): __Observable<__StrictHttpResponse<Array<Cart>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cart/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Cart>>;
      })
    );
  }
  /**
   * @param params The `CartService.CartListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  cartListList(params: CartService.CartListListParams): __Observable<Array<Cart>> {
    return this.cartListListResponse(params).pipe(
      __map(_r => _r.body as Array<Cart>)
    );
  }

  /**
   * @param data undefined
   */
  cartListCreateResponse(data: Cart): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cart/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }
  /**
   * @param data undefined
   */
  cartListCreate(data: Cart): __Observable<Cart> {
    return this.cartListCreateResponse(data).pipe(
      __map(_r => _r.body as Cart)
    );
  }
  cartMyListResponse(): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cart/my`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }  cartMyList(): __Observable<Cart> {
    return this.cartMyListResponse().pipe(
      __map(_r => _r.body as Cart)
    );
  }
  cartReleasePromocodeCreateResponse(): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cart/release_promocode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }  cartReleasePromocodeCreate(): __Observable<Cart> {
    return this.cartReleasePromocodeCreateResponse().pipe(
      __map(_r => _r.body as Cart)
    );
  }

  /**
   * @param item_id undefined
   */
  cartRemoveItemDeleteResponse(itemId: string): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/cart/remove_item/${encodeURIComponent(String(itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }
  /**
   * @param item_id undefined
   */
  cartRemoveItemDelete(itemId: string): __Observable<Cart> {
    return this.cartRemoveItemDeleteResponse(itemId).pipe(
      __map(_r => _r.body as Cart)
    );
  }

  /**
   * @param params The `CartService.CartUpdateItemPartialUpdateParams` containing the following parameters:
   *
   * - `item_id`:
   *
   * - `data`:
   */
  cartUpdateItemPartialUpdateResponse(params: CartService.CartUpdateItemPartialUpdateParams): __Observable<__StrictHttpResponse<Cart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/cart/update_item/${encodeURIComponent(String(params.itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cart>;
      })
    );
  }
  /**
   * @param params The `CartService.CartUpdateItemPartialUpdateParams` containing the following parameters:
   *
   * - `item_id`:
   *
   * - `data`:
   */
  cartUpdateItemPartialUpdate(params: CartService.CartUpdateItemPartialUpdateParams): __Observable<Cart> {
    return this.cartUpdateItemPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Cart)
    );
  }
}

module CartService {

  /**
   * Parameters for cartListList
   */
  export interface CartListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for cartUpdateItemPartialUpdate
   */
  export interface CartUpdateItemPartialUpdateParams {
    itemId: string;
    data: UpdateCartItem;
  }
}

export { CartService }
