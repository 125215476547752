import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Partner, Booth } from '../../../swagger/models';
import { PartnerService, BoothService } from '../../../swagger/services';

@Component({
  selector: 'app-partners-section',
  templateUrl: './partners-section.component.html',
  styleUrls: ['./partners-section.component.scss'],
})
export class PartnersSectionComponent implements OnInit {
  themeId = environment.themeId;
  boothes$: Observable<Booth[]>;
  partners$: Observable<Partner[]>;
  partners: Partner[] = [];
  infoPartners: Partner[] = [];
  infoBestPartners: Partner[] = [];

  constructor(private partnerService: PartnerService, private boothService: BoothService) {}

  ngOnInit() {
    this.partners$ = this.partnerService.partnerListList({ theme: this.themeId }).pipe(
      tap((results) =>
        results.sort((a, b) => {
          return b.order > a.order ? -1 : 1;
        })
      ),
      map((result) => {
        console.log(result);
        this.partners = result.filter((partner) => partner.type === 1);
        this.infoPartners = result.filter((partner) => partner.type === 2);
        this.infoBestPartners = result.filter((partner) => partner.type === 6);
        return result;
      })
    );

    this.boothes$ = this.boothService.boothListList({ theme: this.themeId }).pipe(
      map((results) =>
        results.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
      )
    );
  }
}
