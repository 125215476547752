/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Order } from '../models/order';
import { OrderCreate } from '../models/order-create';
import { TicketData } from '../models/ticket-data';
import { OrderAddTicket } from '../models/order-add-ticket';
import { ApplyPromocode } from '../models/apply-promocode';
import { OrderBookingSubmit } from '../models/order-booking-submit';
import { Invoice } from '../models/invoice';
import { Notifications } from '../models/notifications';
import { ReturnRequest } from '../models/return-request';
import { CreateOrderReturnRequest } from '../models/create-order-return-request';
@Injectable({
  providedIn: 'root',
})
class OrderService extends __BaseService {
  static readonly orderCreateCreatePath = '/order/create';
  static readonly orderListListPath = '/order/list';
  static readonly orderYookassaEventsCreatePath = '/order/yookassa/events';
  static readonly orderYookassaListPaymentsListPath = '/order/yookassa/list_payments';
  static readonly orderReadPath = '/order/{id}';
  static readonly orderUpdatePath = '/order/{id}';
  static readonly orderPartialUpdatePath = '/order/{id}';
  static readonly orderAddTicketCreatePath = '/order/{id}/add_ticket';
  static readonly orderApplyPromocodeCreatePath = '/order/{id}/apply_promocode';
  static readonly orderBookingSubmitCreatePath = '/order/{id}/booking/submit';
  static readonly orderCancelListPath = '/order/{id}/cancel';
  static readonly orderConfirmPaymentListPath = '/order/{id}/confirm_payment';
  static readonly orderDeleteTicketDeletePath = '/order/{id}/delete_ticket/{ticket_id}';
  static readonly orderInvoiceListPath = '/order/{id}/invoice';
  static readonly orderInvoiceCreatePath = '/order/{id}/invoice';
  static readonly orderInvoicePartialUpdatePath = '/order/{id}/invoice';
  static readonly orderInvoiceSubmitListPath = '/order/{id}/invoice/submit';
  static readonly orderNotificationsCreatePath = '/order/{id}/notifications';
  static readonly orderPageDetailListPath = '/order/{id}/page/detail';
  static readonly orderPrintReadPath = '/order/{id}/print/{template_name}';
  static readonly orderProceedToPaymentCreatePath = '/order/{id}/proceed_to_payment';
  static readonly orderReleasePromocodeCreatePath = '/order/{id}/release_promocode';
  static readonly orderRenewCreatePath = '/order/{id}/renew';
  static readonly orderReturnRequestCreatePath = '/order/{id}/return_request';
  static readonly orderReturnRequestFormListPath = '/order/{id}/return_request/form';
  static readonly orderReturnRequestFormCreatePath = '/order/{id}/return_request/form';
  static readonly orderTicketListListPath = '/order/{id}/ticket/list';
  static readonly orderUpdateTicketPartialUpdatePath = '/order/{id}/update_ticket/{ticket_id}';
  static readonly orderYookassaCreatePaymentListPath = '/order/{id}/yookassa/create_payment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  orderCreateCreateResponse(data: OrderCreate): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param data undefined
   */
  orderCreateCreate(data: OrderCreate): __Observable<Order> {
    return this.orderCreateCreateResponse(data).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderListListParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `phone`: phone
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `number`: number
   *
   * - `limit`: Number of results to return per page.
   *
   * - `invoice_number`: invoice_number
   *
   * - `ids`: ids
   *
   * - `email`: email
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  orderListListResponse(params: OrderService.OrderListListParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.number != null) __params = __params.set('number', params.number.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoice_number', params.invoiceNumber.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.dateUpdatedTo != null) __params = __params.set('date_updated_to', params.dateUpdatedTo.toString());
    if (params.dateUpdatedFrom != null) __params = __params.set('date_updated_from', params.dateUpdatedFrom.toString());
    if (params.dateCreatedTo != null) __params = __params.set('date_created_to', params.dateCreatedTo.toString());
    if (params.dateCreatedFrom != null) __params = __params.set('date_created_from', params.dateCreatedFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderListListParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `phone`: phone
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `number`: number
   *
   * - `limit`: Number of results to return per page.
   *
   * - `invoice_number`: invoice_number
   *
   * - `ids`: ids
   *
   * - `email`: email
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  orderListList(params: OrderService.OrderListListParams): __Observable<Order> {
    return this.orderListListResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }
  orderYookassaEventsCreateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/yookassa/events`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  orderYookassaEventsCreate(): __Observable<null> {
    return this.orderYookassaEventsCreateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  orderYookassaListPaymentsListResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/yookassa/list_payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  orderYookassaListPaymentsList(): __Observable<null> {
    return this.orderYookassaListPaymentsListResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this order.
   */
  orderReadResponse(id: number): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this order.
   */
  orderRead(id: number): __Observable<Order> {
    return this.orderReadResponse(id).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this order.
   *
   * - `data`:
   */
  orderUpdateResponse(params: OrderService.OrderUpdateParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this order.
   *
   * - `data`:
   */
  orderUpdate(params: OrderService.OrderUpdateParams): __Observable<Order> {
    return this.orderUpdateResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this order.
   *
   * - `data`:
   */
  orderPartialUpdateResponse(params: OrderService.OrderPartialUpdateParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this order.
   *
   * - `data`:
   */
  orderPartialUpdate(params: OrderService.OrderPartialUpdateParams): __Observable<Order> {
    return this.orderPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderAddTicketCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderAddTicketCreateResponse(params: OrderService.OrderAddTicketCreateParams): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/add_ticket`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderAddTicketCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderAddTicketCreate(params: OrderService.OrderAddTicketCreateParams): __Observable<TicketData> {
    return this.orderAddTicketCreateResponse(params).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param params The `OrderService.OrderApplyPromocodeCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderApplyPromocodeCreateResponse(params: OrderService.OrderApplyPromocodeCreateParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/apply_promocode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderApplyPromocodeCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderApplyPromocodeCreate(params: OrderService.OrderApplyPromocodeCreateParams): __Observable<Order> {
    return this.orderApplyPromocodeCreateResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderBookingSubmitCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderBookingSubmitCreateResponse(params: OrderService.OrderBookingSubmitCreateParams): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/booking/submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderBookingSubmitCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderBookingSubmitCreate(params: OrderService.OrderBookingSubmitCreateParams): __Observable<Order> {
    return this.orderBookingSubmitCreateResponse(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param id undefined
   */
  orderCancelListResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderCancelList(id: string): __Observable<null> {
    return this.orderCancelListResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  orderConfirmPaymentListResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/confirm_payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderConfirmPaymentList(id: string): __Observable<null> {
    return this.orderConfirmPaymentListResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrderService.OrderDeleteTicketDeleteParams` containing the following parameters:
   *
   * - `ticket_id`:
   *
   * - `id`:
   */
  orderDeleteTicketDeleteResponse(params: OrderService.OrderDeleteTicketDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/delete_ticket/${encodeURIComponent(String(params.ticketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderDeleteTicketDeleteParams` containing the following parameters:
   *
   * - `ticket_id`:
   *
   * - `id`:
   */
  orderDeleteTicketDelete(params: OrderService.OrderDeleteTicketDeleteParams): __Observable<null> {
    return this.orderDeleteTicketDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  orderInvoiceListResponse(id: string): __Observable<__StrictHttpResponse<Invoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Invoice>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderInvoiceList(id: string): __Observable<Invoice> {
    return this.orderInvoiceListResponse(id).pipe(
      __map(_r => _r.body as Invoice)
    );
  }

  /**
   * @param params The `OrderService.OrderInvoiceCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderInvoiceCreateResponse(params: OrderService.OrderInvoiceCreateParams): __Observable<__StrictHttpResponse<Invoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Invoice>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderInvoiceCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderInvoiceCreate(params: OrderService.OrderInvoiceCreateParams): __Observable<Invoice> {
    return this.orderInvoiceCreateResponse(params).pipe(
      __map(_r => _r.body as Invoice)
    );
  }

  /**
   * @param params The `OrderService.OrderInvoicePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderInvoicePartialUpdateResponse(params: OrderService.OrderInvoicePartialUpdateParams): __Observable<__StrictHttpResponse<Invoice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Invoice>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderInvoicePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderInvoicePartialUpdate(params: OrderService.OrderInvoicePartialUpdateParams): __Observable<Invoice> {
    return this.orderInvoicePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Invoice)
    );
  }

  /**
   * @param id undefined
   */
  orderInvoiceSubmitListResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/invoice/submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderInvoiceSubmitList(id: string): __Observable<null> {
    return this.orderInvoiceSubmitListResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrderService.OrderNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderNotificationsCreateResponse(params: OrderService.OrderNotificationsCreateParams): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderNotificationsCreate(params: OrderService.OrderNotificationsCreateParams): __Observable<Notifications> {
    return this.orderNotificationsCreateResponse(params).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * @param id undefined
   */
  orderPageDetailListResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/page/detail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderPageDetailList(id: string): __Observable<null> {
    return this.orderPageDetailListResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrderService.OrderPrintReadParams` containing the following parameters:
   *
   * - `template_name`:
   *
   * - `id`:
   */
  orderPrintReadResponse(params: OrderService.OrderPrintReadParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/print/${encodeURIComponent(String(params.templateName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderPrintReadParams` containing the following parameters:
   *
   * - `template_name`:
   *
   * - `id`:
   */
  orderPrintRead(params: OrderService.OrderPrintReadParams): __Observable<null> {
    return this.orderPrintReadResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  orderProceedToPaymentCreateResponse(id: string): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/proceed_to_payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderProceedToPaymentCreate(id: string): __Observable<Order> {
    return this.orderProceedToPaymentCreateResponse(id).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param id undefined
   */
  orderReleasePromocodeCreateResponse(id: string): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/release_promocode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderReleasePromocodeCreate(id: string): __Observable<Order> {
    return this.orderReleasePromocodeCreateResponse(id).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param id undefined
   */
  orderRenewCreateResponse(id: string): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/renew`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderRenewCreate(id: string): __Observable<Order> {
    return this.orderRenewCreateResponse(id).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * @param params The `OrderService.OrderReturnRequestCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderReturnRequestCreateResponse(params: OrderService.OrderReturnRequestCreateParams): __Observable<__StrictHttpResponse<ReturnRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/return_request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReturnRequest>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderReturnRequestCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderReturnRequestCreate(params: OrderService.OrderReturnRequestCreateParams): __Observable<ReturnRequest> {
    return this.orderReturnRequestCreateResponse(params).pipe(
      __map(_r => _r.body as ReturnRequest)
    );
  }

  /**
   * @param id undefined
   */
  orderReturnRequestFormListResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/return_request/form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderReturnRequestFormList(id: string): __Observable<null> {
    return this.orderReturnRequestFormListResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  orderReturnRequestFormCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/return_request/form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderReturnRequestFormCreate(id: string): __Observable<null> {
    return this.orderReturnRequestFormCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  orderTicketListListResponse(id: string): __Observable<__StrictHttpResponse<Array<TicketData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(id))}/ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TicketData>>;
      })
    );
  }
  /**
   * @param id undefined
   */
  orderTicketListList(id: string): __Observable<Array<TicketData>> {
    return this.orderTicketListListResponse(id).pipe(
      __map(_r => _r.body as Array<TicketData>)
    );
  }

  /**
   * @param params The `OrderService.OrderUpdateTicketPartialUpdateParams` containing the following parameters:
   *
   * - `ticket_id`:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderUpdateTicketPartialUpdateResponse(params: OrderService.OrderUpdateTicketPartialUpdateParams): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/update_ticket/${encodeURIComponent(String(params.ticketId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderUpdateTicketPartialUpdateParams` containing the following parameters:
   *
   * - `ticket_id`:
   *
   * - `id`:
   *
   * - `data`:
   */
  orderUpdateTicketPartialUpdate(params: OrderService.OrderUpdateTicketPartialUpdateParams): __Observable<TicketData> {
    return this.orderUpdateTicketPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param params The `OrderService.OrderYookassaCreatePaymentListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `test`: returns html with configured widget
   */
  orderYookassaCreatePaymentListResponse(params: OrderService.OrderYookassaCreatePaymentListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.test != null) __params = __params.set('test', params.test.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/${encodeURIComponent(String(params.id))}/yookassa/create_payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrderService.OrderYookassaCreatePaymentListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `test`: returns html with configured widget
   */
  orderYookassaCreatePaymentList(params: OrderService.OrderYookassaCreatePaymentListParams): __Observable<null> {
    return this.orderYookassaCreatePaymentListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrderService {

  /**
   * Parameters for orderListList
   */
  export interface OrderListListParams {

    /**
     * status
     */
    status?: string;

    /**
     * phone
     */
    phone?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * number
     */
    number?: string;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * invoice_number
     */
    invoiceNumber?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * email
     */
    email?: string;

    /**
     * date_updated_to
     */
    dateUpdatedTo?: string;

    /**
     * date_updated_from
     */
    dateUpdatedFrom?: string;

    /**
     * date_created_to
     */
    dateCreatedTo?: string;

    /**
     * date_created_from
     */
    dateCreatedFrom?: string;
  }

  /**
   * Parameters for orderUpdate
   */
  export interface OrderUpdateParams {

    /**
     * A unique integer value identifying this order.
     */
    id: number;
    data: Order;
  }

  /**
   * Parameters for orderPartialUpdate
   */
  export interface OrderPartialUpdateParams {

    /**
     * A unique integer value identifying this order.
     */
    id: number;
    data: Order;
  }

  /**
   * Parameters for orderAddTicketCreate
   */
  export interface OrderAddTicketCreateParams {
    id: string;
    data: OrderAddTicket;
  }

  /**
   * Parameters for orderApplyPromocodeCreate
   */
  export interface OrderApplyPromocodeCreateParams {
    id: string;
    data: ApplyPromocode;
  }

  /**
   * Parameters for orderBookingSubmitCreate
   */
  export interface OrderBookingSubmitCreateParams {
    id: string;
    data: OrderBookingSubmit;
  }

  /**
   * Parameters for orderDeleteTicketDelete
   */
  export interface OrderDeleteTicketDeleteParams {
    ticketId: string;
    id: string;
  }

  /**
   * Parameters for orderInvoiceCreate
   */
  export interface OrderInvoiceCreateParams {
    id: string;
    data: Invoice;
  }

  /**
   * Parameters for orderInvoicePartialUpdate
   */
  export interface OrderInvoicePartialUpdateParams {
    id: string;
    data: Invoice;
  }

  /**
   * Parameters for orderNotificationsCreate
   */
  export interface OrderNotificationsCreateParams {
    id: string;
    data: Notifications;
  }

  /**
   * Parameters for orderPrintRead
   */
  export interface OrderPrintReadParams {
    templateName: string;
    id: string;
  }

  /**
   * Parameters for orderReturnRequestCreate
   */
  export interface OrderReturnRequestCreateParams {
    id: string;
    data: CreateOrderReturnRequest;
  }

  /**
   * Parameters for orderUpdateTicketPartialUpdate
   */
  export interface OrderUpdateTicketPartialUpdateParams {
    ticketId: string;
    id: string;
    data: TicketData;
  }

  /**
   * Parameters for orderYookassaCreatePaymentList
   */
  export interface OrderYookassaCreatePaymentListParams {
    id: string;

    /**
     * returns html with configured widget
     */
    test?: boolean;
  }
}

export { OrderService }
