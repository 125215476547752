/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AreaFilter } from '../models/area-filter';
import { Area } from '../models/area';
import { AreaDetailCreate } from '../models/area-detail-create';
import { AreaDetail } from '../models/area-detail';
@Injectable({
  providedIn: 'root',
})
class AreaService extends __BaseService {
  static readonly areaFilterListPath = '/area/filter';
  static readonly areaListListPath = '/area/list';
  static readonly areaListCreatePath = '/area/list';
  static readonly areaReadPath = '/area/{id}';
  static readonly areaUpdatePath = '/area/{id}';
  static readonly areaPartialUpdatePath = '/area/{id}';
  static readonly areaDeletePath = '/area/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AreaService.AreaFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `ids`: ids
   */
  areaFilterListResponse(params: AreaService.AreaFilterListParams): __Observable<__StrictHttpResponse<AreaFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/area/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AreaFilter>;
      })
    );
  }
  /**
   * @param params The `AreaService.AreaFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `ids`: ids
   */
  areaFilterList(params: AreaService.AreaFilterListParams): __Observable<AreaFilter> {
    return this.areaFilterListResponse(params).pipe(
      __map(_r => _r.body as AreaFilter)
    );
  }

  /**
   * @param params The `AreaService.AreaListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  areaListListResponse(params: AreaService.AreaListListParams): __Observable<__StrictHttpResponse<Array<Area>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/area/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Area>>;
      })
    );
  }
  /**
   * @param params The `AreaService.AreaListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  areaListList(params: AreaService.AreaListListParams): __Observable<Array<Area>> {
    return this.areaListListResponse(params).pipe(
      __map(_r => _r.body as Array<Area>)
    );
  }

  /**
   * @param data undefined
   */
  areaListCreateResponse(data: AreaDetailCreate): __Observable<__StrictHttpResponse<AreaDetailCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/area/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AreaDetailCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  areaListCreate(data: AreaDetailCreate): __Observable<AreaDetailCreate> {
    return this.areaListCreateResponse(data).pipe(
      __map(_r => _r.body as AreaDetailCreate)
    );
  }

  /**
   * @param id undefined
   */
  areaReadResponse(id: string): __Observable<__StrictHttpResponse<AreaDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/area/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AreaDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  areaRead(id: string): __Observable<AreaDetail> {
    return this.areaReadResponse(id).pipe(
      __map(_r => _r.body as AreaDetail)
    );
  }

  /**
   * @param params The `AreaService.AreaUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  areaUpdateResponse(params: AreaService.AreaUpdateParams): __Observable<__StrictHttpResponse<AreaDetailCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/area/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AreaDetailCreate>;
      })
    );
  }
  /**
   * @param params The `AreaService.AreaUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  areaUpdate(params: AreaService.AreaUpdateParams): __Observable<AreaDetailCreate> {
    return this.areaUpdateResponse(params).pipe(
      __map(_r => _r.body as AreaDetailCreate)
    );
  }

  /**
   * @param params The `AreaService.AreaPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  areaPartialUpdateResponse(params: AreaService.AreaPartialUpdateParams): __Observable<__StrictHttpResponse<AreaDetailCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/area/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AreaDetailCreate>;
      })
    );
  }
  /**
   * @param params The `AreaService.AreaPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  areaPartialUpdate(params: AreaService.AreaPartialUpdateParams): __Observable<AreaDetailCreate> {
    return this.areaPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as AreaDetailCreate)
    );
  }

  /**
   * @param id undefined
   */
  areaDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/area/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  areaDelete(id: string): __Observable<null> {
    return this.areaDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AreaService {

  /**
   * Parameters for areaFilterList
   */
  export interface AreaFilterListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'order' | '-order' | 'theme' | '-theme';

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for areaListList
   */
  export interface AreaListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'order' | '-order' | 'theme' | '-theme';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for areaUpdate
   */
  export interface AreaUpdateParams {
    id: string;
    data: AreaDetailCreate;
  }

  /**
   * Parameters for areaPartialUpdate
   */
  export interface AreaPartialUpdateParams {
    id: string;
    data: AreaDetailCreate;
  }
}

export { AreaService }
