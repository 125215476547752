/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FormDetail } from '../models/form-detail';
@Injectable({
  providedIn: 'root',
})
class FormService extends __BaseService {
  static readonly formReadPath = '/form/{survey_id}';
  static readonly formCreatePath = '/form/{survey_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param survey_id undefined
   */
  formReadResponse(surveyId: string): __Observable<__StrictHttpResponse<FormDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/form/${encodeURIComponent(String(surveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FormDetail>;
      })
    );
  }
  /**
   * @param survey_id undefined
   */
  formRead(surveyId: string): __Observable<FormDetail> {
    return this.formReadResponse(surveyId).pipe(
      __map(_r => _r.body as FormDetail)
    );
  }

  /**
   * @param params The `FormService.FormCreateParams` containing the following parameters:
   *
   * - `survey_id`:
   *
   * - `data`:
   */
  formCreateResponse(params: FormService.FormCreateParams): __Observable<__StrictHttpResponse<FormDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/form/${encodeURIComponent(String(params.surveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FormDetail>;
      })
    );
  }
  /**
   * @param params The `FormService.FormCreateParams` containing the following parameters:
   *
   * - `survey_id`:
   *
   * - `data`:
   */
  formCreate(params: FormService.FormCreateParams): __Observable<FormDetail> {
    return this.formCreateResponse(params).pipe(
      __map(_r => _r.body as FormDetail)
    );
  }
}

module FormService {

  /**
   * Parameters for formCreate
   */
  export interface FormCreateParams {
    surveyId: string;
    data: FormDetail;
  }
}

export { FormService }
