import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  API_URL = environment.apiV2_url;

  constructor(private http: HttpClient) {}

  getAlbum(id: string, page: number, perPage: number) {
    return this.http.get(`${this.API_URL}/api/v1/photo/album/${id}?page=${page}&per_page=${perPage}`);
  }

  getAlbumTree() {
    return this.http.get(`${this.API_URL}/api/v1/photo/collection`);
  }
}
