/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonFilter } from '../models/person-filter';
import { Person } from '../models/person';
import { PersonCreate } from '../models/person-create';
@Injectable({
  providedIn: 'root',
})
class PersonService extends __BaseService {
  static readonly personFilterListPath = '/person/filter';
  static readonly personListListPath = '/person/list';
  static readonly personListCreatePath = '/person/list';
  static readonly personReadPath = '/person/{id}';
  static readonly personUpdatePath = '/person/{id}';
  static readonly personPartialUpdatePath = '/person/{id}';
  static readonly personDeletePath = '/person/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PersonService.PersonFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `ids`: ids
   */
  personFilterListResponse(params: PersonService.PersonFilterListParams): __Observable<__StrictHttpResponse<PersonFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/person/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonFilter>;
      })
    );
  }
  /**
   * @param params The `PersonService.PersonFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `ids`: ids
   */
  personFilterList(params: PersonService.PersonFilterListParams): __Observable<PersonFilter> {
    return this.personFilterListResponse(params).pipe(
      __map(_r => _r.body as PersonFilter)
    );
  }

  /**
   * @param params The `PersonService.PersonListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  personListListResponse(params: PersonService.PersonListListParams): __Observable<__StrictHttpResponse<Array<Person>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/person/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Person>>;
      })
    );
  }
  /**
   * @param params The `PersonService.PersonListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  personListList(params: PersonService.PersonListListParams): __Observable<Array<Person>> {
    return this.personListListResponse(params).pipe(
      __map(_r => _r.body as Array<Person>)
    );
  }

  /**
   * @param data undefined
   */
  personListCreateResponse(data: PersonCreate): __Observable<__StrictHttpResponse<PersonCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/person/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  personListCreate(data: PersonCreate): __Observable<PersonCreate> {
    return this.personListCreateResponse(data).pipe(
      __map(_r => _r.body as PersonCreate)
    );
  }

  /**
   * @param id undefined
   */
  personReadResponse(id: string): __Observable<__StrictHttpResponse<Person>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/person/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Person>;
      })
    );
  }
  /**
   * @param id undefined
   */
  personRead(id: string): __Observable<Person> {
    return this.personReadResponse(id).pipe(
      __map(_r => _r.body as Person)
    );
  }

  /**
   * @param params The `PersonService.PersonUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  personUpdateResponse(params: PersonService.PersonUpdateParams): __Observable<__StrictHttpResponse<PersonCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/person/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonCreate>;
      })
    );
  }
  /**
   * @param params The `PersonService.PersonUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  personUpdate(params: PersonService.PersonUpdateParams): __Observable<PersonCreate> {
    return this.personUpdateResponse(params).pipe(
      __map(_r => _r.body as PersonCreate)
    );
  }

  /**
   * @param params The `PersonService.PersonPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  personPartialUpdateResponse(params: PersonService.PersonPartialUpdateParams): __Observable<__StrictHttpResponse<PersonCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/person/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonCreate>;
      })
    );
  }
  /**
   * @param params The `PersonService.PersonPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  personPartialUpdate(params: PersonService.PersonPartialUpdateParams): __Observable<PersonCreate> {
    return this.personPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as PersonCreate)
    );
  }

  /**
   * @param id undefined
   */
  personDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/person/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  personDelete(id: string): __Observable<null> {
    return this.personDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PersonService {

  /**
   * Parameters for personFilterList
   */
  export interface PersonFilterListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for personListList
   */
  export interface PersonListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for personUpdate
   */
  export interface PersonUpdateParams {
    id: string;
    data: PersonCreate;
  }

  /**
   * Parameters for personPartialUpdate
   */
  export interface PersonPartialUpdateParams {
    id: string;
    data: PersonCreate;
  }
}

export { PersonService }
