<div class="b-expert-section">
  <div class="b-expert-section__left-side-mobile"></div>
  <div class="b-expert-section__right-side-mobile"></div>
  <div class="b-expert-section__container">
    <div class="b-expert-section__content">
      <p class="b-expert-section__text-title">Лучшие в сладком</p>
      <p class="b-expert-section__text-title b-expert-section__text-title--bold">
        эксперты
      </p>
      <p class="b-expert-section__text">
        В 2020 году, в рамках программы MoscowCakeShow, были организованы 2 тематические площадки «Вкус» и «Точка
        роста», в рамках которой выступили35 профессионалов своей области, объединённых одной индустрией.
      </p>
      <p class="b-expert-section__text">В этом году программа была ориентирована не только на профессиональных и частных
        мастеров, но и на тех, кто только начинает или планирует свое развитие в кондитерском или пекарском направлении.
      </p>
      <p class="b-expert-section__text">Одной из задач форума мы считаем рассказывать вам о новых интересных и
        талантливых лицах. С этого года мы начали приглашать в качестве экспертов молодых специалистов и судя по отзывам
        аудитории этот опыт стал вполне успешным. Если вы считаете, что вам есть чем делиться и за плечами уже
        достаточный опыт. Если у вас есть интересный товар, технология, оборудование, упаковка и др. для нашей
        аудитории. Переходите в раздел «КАК ПРИНЯТЬ УЧАСТИЕ В ФОРУМЕ» и заполните соответствующую анкету. Мы с
        удовольствием свяжемся с вами.
      </p>
      <!-- <a
        class="b-expert-section__b-button"
        href="https://2019.pirexpo.com/events/speakers/exhibitions%255B%255D=d6da191e-eca4-11e8-8180-000c29f15ff6"
        target="_blank"
        >подробнее</a
      > -->
      <div class="b-expert-section__button-container">
        <a class="b-expert-section__b-button"
          href="https://cp.unisender.com/ru/v5/subscribe-form/view/6q1mzwzrzpxackatkcrwmtdisme6b3tjcs34m6na"
          target="_blank">подписаться на&nbsp;рассылку</a>
        <a class="b-expert-section__b-button"
          href="https://pirexpo.com/events/program/exhibitions%255B%255D=68386f91-e227-11ea-9f01-000c29f15ff6"
          target="_blank">программа 2020</a>
      </div>

      <div class="b-expert-section__expert-img"></div>
    </div>
  </div>
</div>