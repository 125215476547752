<section class="experts" id="experts">
    <div class="experts__container container">
        <div *ngIf="experts$ | async as experts">
                <h2 class="experts__title">Эксперты 2024</h2>
                <a routerLink="/all-experts" class="button button--orange" pageScroll [pageScrollDuration]="700"><span class="text">ВСЕ ЭКСПЕРТЫ</span></a>


                <ngx-slick-carousel #slickModal="slick-carousel" class="carousel photoSlider" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let expert of experts | slice: 0:slideIndex" class="slide">
                        <div class="slide-wrapper">
                            <div class="description">
                                <a  (click)="openPopup(expert)" class="experts__name">{{ expert.firstname }} {{ expert.secondname }}<i
                                        class="icon-right-open-big"></i></a>
                                <p class="experts__status">{{expert.status}}</p>
                                <div class="experts__info" [innerHTML]="expert.description"></div>
                                <a class="experts__more-info-button" (click)="openPopup(expert)">Подробнее</a>
                            </div>
                            <a (click)="openPopup(expert)" class="experts__img">
                                <img *ngIf="expert.photo.length" loading="lazy" src="{{ expert.photo[0]['image'] }}"
                                    alt="{{ expert.name }}" />
                            </a>
                        </div><!-- slide-wrapper-->
                    </div><!-- slide -->
                </ngx-slick-carousel>
                <div class="experts__arrows-container arrows-container">
                    <button mat-button class="speaker-prev-arrow">
                    </button>
                    <button mat-button class="speaker-next-arrow">
                    </button>
                </div><!-- arrows-container -->
            <div class="container">
                <div class="preview">
                    <ngx-slick-carousel #slickModal="slick-carousel" class="carousel dotsSlider" [config]="slideConfigNav"
                        (afterChange)="afterChange($event)">
                        <div ngxSlickItem *ngFor="let expert of experts | slice: 0:slideIndex" class="slide">
                            <img *ngIf="expert.photo.length" loading="lazy" src="{{ expert.photo[0]['image'] }}"
                                alt="{{ expert.name }}" width="100%" class="circle-img" />
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>