/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Promocode } from '../models/promocode';
@Injectable({
  providedIn: 'root',
})
class PromocodeService extends __BaseService {
  static readonly promocodeListListPath = '/promocode/list';
  static readonly promocodeListCreatePath = '/promocode/list';
  static readonly promocodeReadPath = '/promocode/{id}';
  static readonly promocodeUpdatePath = '/promocode/{id}';
  static readonly promocodePartialUpdatePath = '/promocode/{id}';
  static readonly promocodeDeletePath = '/promocode/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PromocodeService.PromocodeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  promocodeListListResponse(params: PromocodeService.PromocodeListListParams): __Observable<__StrictHttpResponse<Array<Promocode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/promocode/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Promocode>>;
      })
    );
  }
  /**
   * @param params The `PromocodeService.PromocodeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  promocodeListList(params: PromocodeService.PromocodeListListParams): __Observable<Array<Promocode>> {
    return this.promocodeListListResponse(params).pipe(
      __map(_r => _r.body as Array<Promocode>)
    );
  }

  /**
   * @param data undefined
   */
  promocodeListCreateResponse(data: Promocode): __Observable<__StrictHttpResponse<Promocode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/promocode/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Promocode>;
      })
    );
  }
  /**
   * @param data undefined
   */
  promocodeListCreate(data: Promocode): __Observable<Promocode> {
    return this.promocodeListCreateResponse(data).pipe(
      __map(_r => _r.body as Promocode)
    );
  }

  /**
   * @param id A unique integer value identifying this promocode.
   */
  promocodeReadResponse(id: number): __Observable<__StrictHttpResponse<Promocode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/promocode/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Promocode>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this promocode.
   */
  promocodeRead(id: number): __Observable<Promocode> {
    return this.promocodeReadResponse(id).pipe(
      __map(_r => _r.body as Promocode)
    );
  }

  /**
   * @param params The `PromocodeService.PromocodeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode.
   *
   * - `data`:
   */
  promocodeUpdateResponse(params: PromocodeService.PromocodeUpdateParams): __Observable<__StrictHttpResponse<Promocode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/promocode/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Promocode>;
      })
    );
  }
  /**
   * @param params The `PromocodeService.PromocodeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode.
   *
   * - `data`:
   */
  promocodeUpdate(params: PromocodeService.PromocodeUpdateParams): __Observable<Promocode> {
    return this.promocodeUpdateResponse(params).pipe(
      __map(_r => _r.body as Promocode)
    );
  }

  /**
   * @param params The `PromocodeService.PromocodePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode.
   *
   * - `data`:
   */
  promocodePartialUpdateResponse(params: PromocodeService.PromocodePartialUpdateParams): __Observable<__StrictHttpResponse<Promocode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/promocode/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Promocode>;
      })
    );
  }
  /**
   * @param params The `PromocodeService.PromocodePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this promocode.
   *
   * - `data`:
   */
  promocodePartialUpdate(params: PromocodeService.PromocodePartialUpdateParams): __Observable<Promocode> {
    return this.promocodePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Promocode)
    );
  }

  /**
   * @param id A unique integer value identifying this promocode.
   */
  promocodeDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/promocode/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this promocode.
   */
  promocodeDelete(id: number): __Observable<null> {
    return this.promocodeDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PromocodeService {

  /**
   * Parameters for promocodeListList
   */
  export interface PromocodeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for promocodeUpdate
   */
  export interface PromocodeUpdateParams {

    /**
     * A unique integer value identifying this promocode.
     */
    id: number;
    data: Promocode;
  }

  /**
   * Parameters for promocodePartialUpdate
   */
  export interface PromocodePartialUpdateParams {

    /**
     * A unique integer value identifying this promocode.
     */
    id: number;
    data: Promocode;
  }
}

export { PromocodeService }
