/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Survey } from '../models/survey';
@Injectable({
  providedIn: 'root',
})
class SurveyService extends __BaseService {
  static readonly surveyListListPath = '/survey/list';
  static readonly surveyListCreatePath = '/survey/list';
  static readonly surveyReadPath = '/survey/{id}';
  static readonly surveyUpdatePath = '/survey/{id}';
  static readonly surveyPartialUpdatePath = '/survey/{id}';
  static readonly surveyDeletePath = '/survey/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SurveyService.SurveyListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  surveyListListResponse(params: SurveyService.SurveyListListParams): __Observable<__StrictHttpResponse<Array<Survey>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/survey/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Survey>>;
      })
    );
  }
  /**
   * @param params The `SurveyService.SurveyListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  surveyListList(params: SurveyService.SurveyListListParams): __Observable<Array<Survey>> {
    return this.surveyListListResponse(params).pipe(
      __map(_r => _r.body as Array<Survey>)
    );
  }

  /**
   * @param data undefined
   */
  surveyListCreateResponse(data: Survey): __Observable<__StrictHttpResponse<Survey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/survey/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Survey>;
      })
    );
  }
  /**
   * @param data undefined
   */
  surveyListCreate(data: Survey): __Observable<Survey> {
    return this.surveyListCreateResponse(data).pipe(
      __map(_r => _r.body as Survey)
    );
  }

  /**
   * @param id undefined
   */
  surveyReadResponse(id: string): __Observable<__StrictHttpResponse<Survey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/survey/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Survey>;
      })
    );
  }
  /**
   * @param id undefined
   */
  surveyRead(id: string): __Observable<Survey> {
    return this.surveyReadResponse(id).pipe(
      __map(_r => _r.body as Survey)
    );
  }

  /**
   * @param params The `SurveyService.SurveyUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyUpdateResponse(params: SurveyService.SurveyUpdateParams): __Observable<__StrictHttpResponse<Survey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/survey/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Survey>;
      })
    );
  }
  /**
   * @param params The `SurveyService.SurveyUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyUpdate(params: SurveyService.SurveyUpdateParams): __Observable<Survey> {
    return this.surveyUpdateResponse(params).pipe(
      __map(_r => _r.body as Survey)
    );
  }

  /**
   * @param params The `SurveyService.SurveyPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyPartialUpdateResponse(params: SurveyService.SurveyPartialUpdateParams): __Observable<__StrictHttpResponse<Survey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/survey/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Survey>;
      })
    );
  }
  /**
   * @param params The `SurveyService.SurveyPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  surveyPartialUpdate(params: SurveyService.SurveyPartialUpdateParams): __Observable<Survey> {
    return this.surveyPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Survey)
    );
  }

  /**
   * @param id undefined
   */
  surveyDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/survey/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  surveyDelete(id: string): __Observable<null> {
    return this.surveyDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SurveyService {

  /**
   * Parameters for surveyListList
   */
  export interface SurveyListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for surveyUpdate
   */
  export interface SurveyUpdateParams {
    id: string;
    data: Survey;
  }

  /**
   * Parameters for surveyPartialUpdate
   */
  export interface SurveyPartialUpdateParams {
    id: string;
    data: Survey;
  }
}

export { SurveyService }
