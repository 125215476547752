/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BoothConstructionType } from '../models/booth-construction-type';
@Injectable({
  providedIn: 'root',
})
class BoothConstructionTypeService extends __BaseService {
  static readonly boothConstructionTypeListListPath = '/booth_construction_type/list';
  static readonly boothConstructionTypeListCreatePath = '/booth_construction_type/list';
  static readonly boothConstructionTypeReadPath = '/booth_construction_type/{id}';
  static readonly boothConstructionTypeUpdatePath = '/booth_construction_type/{id}';
  static readonly boothConstructionTypePartialUpdatePath = '/booth_construction_type/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  boothConstructionTypeListListResponse(params: BoothConstructionTypeService.BoothConstructionTypeListListParams): __Observable<__StrictHttpResponse<Array<BoothConstructionType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_construction_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BoothConstructionType>>;
      })
    );
  }
  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypeListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  boothConstructionTypeListList(params: BoothConstructionTypeService.BoothConstructionTypeListListParams): __Observable<Array<BoothConstructionType>> {
    return this.boothConstructionTypeListListResponse(params).pipe(
      __map(_r => _r.body as Array<BoothConstructionType>)
    );
  }

  /**
   * @param data undefined
   */
  boothConstructionTypeListCreateResponse(data: BoothConstructionType): __Observable<__StrictHttpResponse<BoothConstructionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/booth_construction_type/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothConstructionType>;
      })
    );
  }
  /**
   * @param data undefined
   */
  boothConstructionTypeListCreate(data: BoothConstructionType): __Observable<BoothConstructionType> {
    return this.boothConstructionTypeListCreateResponse(data).pipe(
      __map(_r => _r.body as BoothConstructionType)
    );
  }

  /**
   * @param id A unique integer value identifying this booth construction type.
   */
  boothConstructionTypeReadResponse(id: number): __Observable<__StrictHttpResponse<BoothConstructionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_construction_type/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothConstructionType>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this booth construction type.
   */
  boothConstructionTypeRead(id: number): __Observable<BoothConstructionType> {
    return this.boothConstructionTypeReadResponse(id).pipe(
      __map(_r => _r.body as BoothConstructionType)
    );
  }

  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth construction type.
   *
   * - `data`:
   */
  boothConstructionTypeUpdateResponse(params: BoothConstructionTypeService.BoothConstructionTypeUpdateParams): __Observable<__StrictHttpResponse<BoothConstructionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/booth_construction_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothConstructionType>;
      })
    );
  }
  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypeUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth construction type.
   *
   * - `data`:
   */
  boothConstructionTypeUpdate(params: BoothConstructionTypeService.BoothConstructionTypeUpdateParams): __Observable<BoothConstructionType> {
    return this.boothConstructionTypeUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothConstructionType)
    );
  }

  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth construction type.
   *
   * - `data`:
   */
  boothConstructionTypePartialUpdateResponse(params: BoothConstructionTypeService.BoothConstructionTypePartialUpdateParams): __Observable<__StrictHttpResponse<BoothConstructionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/booth_construction_type/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothConstructionType>;
      })
    );
  }
  /**
   * @param params The `BoothConstructionTypeService.BoothConstructionTypePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this booth construction type.
   *
   * - `data`:
   */
  boothConstructionTypePartialUpdate(params: BoothConstructionTypeService.BoothConstructionTypePartialUpdateParams): __Observable<BoothConstructionType> {
    return this.boothConstructionTypePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothConstructionType)
    );
  }
}

module BoothConstructionTypeService {

  /**
   * Parameters for boothConstructionTypeListList
   */
  export interface BoothConstructionTypeListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for boothConstructionTypeUpdate
   */
  export interface BoothConstructionTypeUpdateParams {

    /**
     * A unique integer value identifying this booth construction type.
     */
    id: number;
    data: BoothConstructionType;
  }

  /**
   * Parameters for boothConstructionTypePartialUpdate
   */
  export interface BoothConstructionTypePartialUpdateParams {

    /**
     * A unique integer value identifying this booth construction type.
     */
    id: number;
    data: BoothConstructionType;
  }
}

export { BoothConstructionTypeService }
