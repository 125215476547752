/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Participation } from '../models/participation';
import { ParticipationDetail } from '../models/participation-detail';
@Injectable({
  providedIn: 'root',
})
class ParticipationService extends __BaseService {
  static readonly participationListListPath = '/participation/list';
  static readonly participationListCreatePath = '/participation/list';
  static readonly participationReadPath = '/participation/{id}';
  static readonly participationUpdatePath = '/participation/{id}';
  static readonly participationPartialUpdatePath = '/participation/{id}';
  static readonly participationDeletePath = '/participation/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ParticipationService.ParticipationListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  participationListListResponse(params: ParticipationService.ParticipationListListParams): __Observable<__StrictHttpResponse<Array<Participation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/participation/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Participation>>;
      })
    );
  }
  /**
   * @param params The `ParticipationService.ParticipationListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  participationListList(params: ParticipationService.ParticipationListListParams): __Observable<Array<Participation>> {
    return this.participationListListResponse(params).pipe(
      __map(_r => _r.body as Array<Participation>)
    );
  }

  /**
   * @param data undefined
   */
  participationListCreateResponse(data: Participation): __Observable<__StrictHttpResponse<Participation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/participation/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Participation>;
      })
    );
  }
  /**
   * @param data undefined
   */
  participationListCreate(data: Participation): __Observable<Participation> {
    return this.participationListCreateResponse(data).pipe(
      __map(_r => _r.body as Participation)
    );
  }

  /**
   * @param id undefined
   */
  participationReadResponse(id: string): __Observable<__StrictHttpResponse<ParticipationDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/participation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParticipationDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  participationRead(id: string): __Observable<ParticipationDetail> {
    return this.participationReadResponse(id).pipe(
      __map(_r => _r.body as ParticipationDetail)
    );
  }

  /**
   * @param params The `ParticipationService.ParticipationUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  participationUpdateResponse(params: ParticipationService.ParticipationUpdateParams): __Observable<__StrictHttpResponse<ParticipationDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/participation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParticipationDetail>;
      })
    );
  }
  /**
   * @param params The `ParticipationService.ParticipationUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  participationUpdate(params: ParticipationService.ParticipationUpdateParams): __Observable<ParticipationDetail> {
    return this.participationUpdateResponse(params).pipe(
      __map(_r => _r.body as ParticipationDetail)
    );
  }

  /**
   * @param params The `ParticipationService.ParticipationPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  participationPartialUpdateResponse(params: ParticipationService.ParticipationPartialUpdateParams): __Observable<__StrictHttpResponse<ParticipationDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/participation/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParticipationDetail>;
      })
    );
  }
  /**
   * @param params The `ParticipationService.ParticipationPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  participationPartialUpdate(params: ParticipationService.ParticipationPartialUpdateParams): __Observable<ParticipationDetail> {
    return this.participationPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as ParticipationDetail)
    );
  }

  /**
   * @param id undefined
   */
  participationDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/participation/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  participationDelete(id: string): __Observable<null> {
    return this.participationDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ParticipationService {

  /**
   * Parameters for participationListList
   */
  export interface ParticipationListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for participationUpdate
   */
  export interface ParticipationUpdateParams {
    id: string;
    data: ParticipationDetail;
  }

  /**
   * Parameters for participationPartialUpdate
   */
  export interface ParticipationPartialUpdateParams {
    id: string;
    data: ParticipationDetail;
  }
}

export { ParticipationService }
