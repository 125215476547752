/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NotifyResponse } from '../models/notify-response';
import { NotifyRequest } from '../models/notify-request';
import { SBPOrder } from '../models/sbporder';
import { SBPOrderCancel } from '../models/sbporder-cancel';
@Injectable({
  providedIn: 'root',
})
class SbpService extends __BaseService {
  static readonly sbpNotifyCreatePath = '/sbp/notify';
  static readonly sbpOrderListListPath = '/sbp/order/list';
  static readonly sbpOrderReadPath = '/sbp/order/{order_id}';
  static readonly sbpOrderCancelCreatePath = '/sbp/order/{sbp_order_id}/cancel';
  static readonly sbpQrReadPath = '/sbp/qr/{sbp_order_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  sbpNotifyCreateResponse(data: NotifyRequest): __Observable<__StrictHttpResponse<NotifyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sbp/notify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotifyResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  sbpNotifyCreate(data: NotifyRequest): __Observable<NotifyResponse> {
    return this.sbpNotifyCreateResponse(data).pipe(
      __map(_r => _r.body as NotifyResponse)
    );
  }

  /**
   * @param params The `SbpService.SbpOrderListListParams` containing the following parameters:
   *
   * - `state`: state
   *
   * - `sbp_account`: sbp_account
   *
   * - `order_number`: order_number
   *
   * - `order_ids`: order_ids
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  sbpOrderListListResponse(params: SbpService.SbpOrderListListParams): __Observable<__StrictHttpResponse<Array<SBPOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.sbpAccount != null) __params = __params.set('sbp_account', params.sbpAccount.toString());
    if (params.orderNumber != null) __params = __params.set('order_number', params.orderNumber.toString());
    if (params.orderIds != null) __params = __params.set('order_ids', params.orderIds.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.dateUpdatedTo != null) __params = __params.set('date_updated_to', params.dateUpdatedTo.toString());
    if (params.dateUpdatedFrom != null) __params = __params.set('date_updated_from', params.dateUpdatedFrom.toString());
    if (params.dateCreatedTo != null) __params = __params.set('date_created_to', params.dateCreatedTo.toString());
    if (params.dateCreatedFrom != null) __params = __params.set('date_created_from', params.dateCreatedFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sbp/order/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SBPOrder>>;
      })
    );
  }
  /**
   * @param params The `SbpService.SbpOrderListListParams` containing the following parameters:
   *
   * - `state`: state
   *
   * - `sbp_account`: sbp_account
   *
   * - `order_number`: order_number
   *
   * - `order_ids`: order_ids
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   *
   * - `date_updated_to`: date_updated_to
   *
   * - `date_updated_from`: date_updated_from
   *
   * - `date_created_to`: date_created_to
   *
   * - `date_created_from`: date_created_from
   */
  sbpOrderListList(params: SbpService.SbpOrderListListParams): __Observable<Array<SBPOrder>> {
    return this.sbpOrderListListResponse(params).pipe(
      __map(_r => _r.body as Array<SBPOrder>)
    );
  }

  /**
   * @param order_id undefined
   */
  sbpOrderReadResponse(orderId: string): __Observable<__StrictHttpResponse<SBPOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sbp/order/${encodeURIComponent(String(orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SBPOrder>;
      })
    );
  }
  /**
   * @param order_id undefined
   */
  sbpOrderRead(orderId: string): __Observable<SBPOrder> {
    return this.sbpOrderReadResponse(orderId).pipe(
      __map(_r => _r.body as SBPOrder)
    );
  }

  /**
   * @param params The `SbpService.SbpOrderCancelCreateParams` containing the following parameters:
   *
   * - `sbp_order_id`:
   *
   * - `data`:
   */
  sbpOrderCancelCreateResponse(params: SbpService.SbpOrderCancelCreateParams): __Observable<__StrictHttpResponse<SBPOrderCancel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sbp/order/${encodeURIComponent(String(params.sbpOrderId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SBPOrderCancel>;
      })
    );
  }
  /**
   * @param params The `SbpService.SbpOrderCancelCreateParams` containing the following parameters:
   *
   * - `sbp_order_id`:
   *
   * - `data`:
   */
  sbpOrderCancelCreate(params: SbpService.SbpOrderCancelCreateParams): __Observable<SBPOrderCancel> {
    return this.sbpOrderCancelCreateResponse(params).pipe(
      __map(_r => _r.body as SBPOrderCancel)
    );
  }

  /**
   * @param sbp_order_id undefined
   */
  sbpQrReadResponse(sbpOrderId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sbp/qr/${encodeURIComponent(String(sbpOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sbp_order_id undefined
   */
  sbpQrRead(sbpOrderId: string): __Observable<null> {
    return this.sbpQrReadResponse(sbpOrderId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SbpService {

  /**
   * Parameters for sbpOrderListList
   */
  export interface SbpOrderListListParams {

    /**
     * state
     */
    state?: string;

    /**
     * sbp_account
     */
    sbpAccount?: string;

    /**
     * order_number
     */
    orderNumber?: string;

    /**
     * order_ids
     */
    orderIds?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;

    /**
     * date_updated_to
     */
    dateUpdatedTo?: string;

    /**
     * date_updated_from
     */
    dateUpdatedFrom?: string;

    /**
     * date_created_to
     */
    dateCreatedTo?: string;

    /**
     * date_created_from
     */
    dateCreatedFrom?: string;
  }

  /**
   * Parameters for sbpOrderCancelCreate
   */
  export interface SbpOrderCancelCreateParams {
    sbpOrderId: string;
    data: SBPOrderCancel;
  }
}

export { SbpService }
