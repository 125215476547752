import { Component, Input, OnInit } from '@angular/core';
import { Person } from '../../../../swagger/models';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() value: Person;

  constructor() {}

  ngOnInit(): void {}
}
