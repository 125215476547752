<div class="b-experts-all" *ngIf="experts$ | async as experts" id="b-experts-all">
    <div class="b-experts-all__container container">
        <div class="b-experts-all__title">
            <div class="b-experts-all__column">
                эксперты
            </div>
            <div class="b-experts-all__column">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="b-experts-all__back-button button button--orange">
                    <span>вернуться</span>
                </a>
            </div>
        </div>
        <!-- b-experts-all__header -->
        <div class="b-experts-all__list">
            <a class="b-experts-all__expert" *ngFor="let expert of experts" (click)="openPopup(expert)">
                <img *ngIf="expert.photo.length" loading="lazy" class="b-experts-all__image"
                    src="{{ expert.photo[0]['image'] }}" alt="{{ expert.name }}" />
                <div class="b-experts-all__name">{{ expert.firstname }} {{ expert.secondname }}</div>
            </a>
        </div><!-- b-experts-all__list -->
    </div><!-- b-experts-all__container -->
</div><!-- b-experts-all -->