import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgramService } from 'src/app/services/program.service';
import { environment } from 'src/environments/environment';
import { Event } from '../../swagger/models/event';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { map } from 'rxjs/operators';
dayjs.extend(utc);
dayjs.extend(timezone);
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
})
export class ProgramComponent implements OnInit {
  API_URL = environment.apiV2_url;
  public events$: Observable<Event[]>;
  public eventsByDays = {};
  public filteringEventsByDays = {};
  public days = [];
  public areas = [];
  public experts = [];
  public daysFiltered = [];
  themeId = environment.themeId;
  // query params
  public day;
  public description;
  public area = {
    id: '',
    name: '',
  };
  public expert = {
    id: '',
    name: '',
  };
  constructor(private programService: ProgramService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.events$ = this.programService.getEventsListByStage(this.themeId).pipe(
      map((res) => {
        return res.map((event) => {
          const preparedEvent = this.prepareEventForTimetable(event);
          if (this.eventsByDays[preparedEvent.day]) {
            this.eventsByDays[preparedEvent.day].push(preparedEvent);
          } else {
            this.eventsByDays[preparedEvent.day] = [preparedEvent];
          }
          if (this.days.indexOf(preparedEvent.day) === -1) {
            this.days.push(preparedEvent.day);
          }

          this.days.sort((a, b) => {
            return a - b;
          });

          this.filteringEventsByDays = { ...this.eventsByDays };
          this.daysFiltered = [...this.days];

          // сортируем
          Object.keys(this.filteringEventsByDays).forEach((day) => {
            this.filteringEventsByDays[day].map((el) => {
              // площадки для кнопки фильтрации
              this.areas.push(el.area);
              this.areas = this.areas.filter((item, pos, self) => self.findIndex((v) => v['id'] === item['id']) === pos);
              // эксперты для кнопки фильтрации
              el['speakers'].map((expert) => {
                if (this.experts.includes(expert) === false) {
                  this.experts.push(expert);
                }
              });
            });

            const dayEvents = this.filteringEventsByDays[day];

            dayEvents.sort((a, b) => {
              return a.time_start - b.time_start;
            });
          });

          // инициализация фильтрованных данных при загрузке страницы
          // TODO: сделать отдельной функцией
          // имя для выбранной площадки
          this.areas.map((x) => {
            if (x.id === parseInt(this.area.id)) {
              this.area.name = x.name;
            }
          });
          // имя выбранного эксперта
          this.experts.map((m) => {
            if (m.id === parseInt(this.expert.id)) {
              this.expert.name = `${m.firstname} ${m.secondname}`;
            }
          });
          // сортируем экспертов для кнопки фильтра'
          this.experts.sort((a, b) => {
            if (a.secondname < b.secondname) {
              return -1;
            }
          });
          // инициализация начальных query параметров
          let initParams = {
            day: this.day,
            area: parseInt(this.area.id),
            expert: parseInt(this.expert.id),
          };
          this.filter(initParams);
          return event;
        });
      })
    );

    this.route.queryParams.subscribe((params) => {
      this.day = params['day'];
      this.area.id = params['area'];
      this.expert.id = params['expert'];

      // имя для выбранной площадки
      this.areas.map((x) => {
        if (x.id === parseInt(this.area.id)) {
          this.area.name = x.name;
        }
      });
      // имя выбранного эксперта
      this.experts.map((m) => {
        if (m.id === parseInt(this.expert.id)) {
          this.expert.name = `${m.firstname} ${m.secondname}`;
        }
      });
      this.filter(params);
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  filter(filterBy) {
    this.filteringEventsByDays = { ...this.eventsByDays };
    if (filterBy.day) {
      this.days.forEach((day) => {
        if (day == filterBy.day) {
          this.daysFiltered = [day];
        }
      });
    } else if (filterBy.day === undefined) {
      this.daysFiltered = [...this.days];
    }
    if (filterBy.area) {
      this.daysFiltered.forEach((day) => {
        this.filteringEventsByDays[day] = this.filteringEventsByDays[day].filter((item) => item['area']['id'] === parseInt(filterBy.area));
      });
    }
    if (filterBy.expert) {
      this.daysFiltered.forEach((day) => {
        this.filteringEventsByDays[day] = this.filteringEventsByDays[day].filter((item) => {
          let result = false;
          item['speakers'].map((el) => {
            if (el.id === parseInt(filterBy.expert)) {
              result = true;
            }
          });
          return result;
        });
      });
    }
    // убираем пустые дни

    let daysArray = [];
    this.daysFiltered.forEach((day) => {
      if (this.filteringEventsByDays[day].length > 0) {
        daysArray.push(day);
      }
    });
    this.daysFiltered = daysArray;
  }

  prepareEventForTimetable(event) {
    // возвращаем более удобный объект
    let day: number;
    let month: string;
    let time_string: string;
    day = event['time_start'].getDate();
    month = dayjs(event['time_start']).tz('Europe/Moscow').format('D MMMM').toString().split(' ')[1];
    time_string = `
    ${dayjs(event['time_start']).tz('Europe/Moscow').format('HH:mm').toString()}–${dayjs(event['time_finish'])
      .tz('Europe/Moscow')
      .format('HH:mm')
      .toString()}`;

    event.time_string = time_string.trim();
    event.time_start = event['time_start'];
    event.day = day;
    event.month = month;
    return event;
  }
}
