/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Hotel } from '../models/hotel';
@Injectable({
  providedIn: 'root',
})
class HotelService extends __BaseService {
  static readonly hotelListListPath = '/hotel/list';
  static readonly hotelListCreatePath = '/hotel/list';
  static readonly hotelReadPath = '/hotel/{id}';
  static readonly hotelUpdatePath = '/hotel/{id}';
  static readonly hotelPartialUpdatePath = '/hotel/{id}';
  static readonly hotelDeletePath = '/hotel/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HotelService.HotelListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  hotelListListResponse(params: HotelService.HotelListListParams): __Observable<__StrictHttpResponse<Array<Hotel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/hotel/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Hotel>>;
      })
    );
  }
  /**
   * @param params The `HotelService.HotelListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  hotelListList(params: HotelService.HotelListListParams): __Observable<Array<Hotel>> {
    return this.hotelListListResponse(params).pipe(
      __map(_r => _r.body as Array<Hotel>)
    );
  }

  /**
   * @param data undefined
   */
  hotelListCreateResponse(data: Hotel): __Observable<__StrictHttpResponse<Hotel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/hotel/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotel>;
      })
    );
  }
  /**
   * @param data undefined
   */
  hotelListCreate(data: Hotel): __Observable<Hotel> {
    return this.hotelListCreateResponse(data).pipe(
      __map(_r => _r.body as Hotel)
    );
  }

  /**
   * @param id A unique integer value identifying this hotel.
   */
  hotelReadResponse(id: number): __Observable<__StrictHttpResponse<Hotel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/hotel/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotel>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this hotel.
   */
  hotelRead(id: number): __Observable<Hotel> {
    return this.hotelReadResponse(id).pipe(
      __map(_r => _r.body as Hotel)
    );
  }

  /**
   * @param params The `HotelService.HotelUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this hotel.
   *
   * - `data`:
   */
  hotelUpdateResponse(params: HotelService.HotelUpdateParams): __Observable<__StrictHttpResponse<Hotel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/hotel/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotel>;
      })
    );
  }
  /**
   * @param params The `HotelService.HotelUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this hotel.
   *
   * - `data`:
   */
  hotelUpdate(params: HotelService.HotelUpdateParams): __Observable<Hotel> {
    return this.hotelUpdateResponse(params).pipe(
      __map(_r => _r.body as Hotel)
    );
  }

  /**
   * @param params The `HotelService.HotelPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this hotel.
   *
   * - `data`:
   */
  hotelPartialUpdateResponse(params: HotelService.HotelPartialUpdateParams): __Observable<__StrictHttpResponse<Hotel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/hotel/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotel>;
      })
    );
  }
  /**
   * @param params The `HotelService.HotelPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this hotel.
   *
   * - `data`:
   */
  hotelPartialUpdate(params: HotelService.HotelPartialUpdateParams): __Observable<Hotel> {
    return this.hotelPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Hotel)
    );
  }

  /**
   * @param id A unique integer value identifying this hotel.
   */
  hotelDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/hotel/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this hotel.
   */
  hotelDelete(id: number): __Observable<null> {
    return this.hotelDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HotelService {

  /**
   * Parameters for hotelListList
   */
  export interface HotelListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for hotelUpdate
   */
  export interface HotelUpdateParams {

    /**
     * A unique integer value identifying this hotel.
     */
    id: number;
    data: Hotel;
  }

  /**
   * Parameters for hotelPartialUpdate
   */
  export interface HotelPartialUpdateParams {

    /**
     * A unique integer value identifying this hotel.
     */
    id: number;
    data: Hotel;
  }
}

export { HotelService }
