/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FormlyResult } from '../models/formly-result';
import { FormlyPost } from '../models/formly-post';
import { Formly } from '../models/formly';
@Injectable({
  providedIn: 'root',
})
class FormlyService extends __BaseService {
  static readonly formlyResultReadPath = '/formly/result/{result_id}';
  static readonly formlyResultCreatePath = '/formly/result/{result_id}';
  static readonly formlyReadPath = '/formly/{survey_id}';
  static readonly formlyCreatePath = '/formly/{survey_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param result_id undefined
   */
  formlyResultReadResponse(resultId: string): __Observable<__StrictHttpResponse<FormlyResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/formly/result/${encodeURIComponent(String(resultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FormlyResult>;
      })
    );
  }
  /**
   * @param result_id undefined
   */
  formlyResultRead(resultId: string): __Observable<FormlyResult> {
    return this.formlyResultReadResponse(resultId).pipe(
      __map(_r => _r.body as FormlyResult)
    );
  }

  /**
   * @param params The `FormlyService.FormlyResultCreateParams` containing the following parameters:
   *
   * - `result_id`:
   *
   * - `data`:
   */
  formlyResultCreateResponse(params: FormlyService.FormlyResultCreateParams): __Observable<__StrictHttpResponse<FormlyPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/formly/result/${encodeURIComponent(String(params.resultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FormlyPost>;
      })
    );
  }
  /**
   * @param params The `FormlyService.FormlyResultCreateParams` containing the following parameters:
   *
   * - `result_id`:
   *
   * - `data`:
   */
  formlyResultCreate(params: FormlyService.FormlyResultCreateParams): __Observable<FormlyPost> {
    return this.formlyResultCreateResponse(params).pipe(
      __map(_r => _r.body as FormlyPost)
    );
  }

  /**
   * @param survey_id undefined
   */
  formlyReadResponse(surveyId: string): __Observable<__StrictHttpResponse<Formly>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/formly/${encodeURIComponent(String(surveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Formly>;
      })
    );
  }
  /**
   * @param survey_id undefined
   */
  formlyRead(surveyId: string): __Observable<Formly> {
    return this.formlyReadResponse(surveyId).pipe(
      __map(_r => _r.body as Formly)
    );
  }

  /**
   * @param params The `FormlyService.FormlyCreateParams` containing the following parameters:
   *
   * - `survey_id`:
   *
   * - `data`:
   */
  formlyCreateResponse(params: FormlyService.FormlyCreateParams): __Observable<__StrictHttpResponse<FormlyPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/formly/${encodeURIComponent(String(params.surveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FormlyPost>;
      })
    );
  }
  /**
   * @param params The `FormlyService.FormlyCreateParams` containing the following parameters:
   *
   * - `survey_id`:
   *
   * - `data`:
   */
  formlyCreate(params: FormlyService.FormlyCreateParams): __Observable<FormlyPost> {
    return this.formlyCreateResponse(params).pipe(
      __map(_r => _r.body as FormlyPost)
    );
  }
}

module FormlyService {

  /**
   * Parameters for formlyResultCreate
   */
  export interface FormlyResultCreateParams {
    resultId: string;
    data: FormlyPost;
  }

  /**
   * Parameters for formlyCreate
   */
  export interface FormlyCreateParams {
    surveyId: string;
    data: FormlyPost;
  }
}

export { FormlyService }
