import { Component, OnInit } from '@angular/core';
import { MediaService } from '../../services/media.service';
import { Photo } from '../../interfaces/photo';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
})
export class PhotoGalleryComponent implements OnInit {
  queryParam: string;
  // Flickr settings
  page = 1;
  perPage = 12;
  numberPagesInPhotoset: number;

  collectionSet;
  public album: Photo[];

  tabs = [
    { year: 2019, collection_key: '155267350-72157719503019976' },
    { year: 2020, collection_key: '155267350-72157719552585295' },
    { year: 2021, collection_key: '155267350-72157719496471350' },
    { year: 2022, collection_key: '155267350-72157720745243621' },
    { year: 2023, collection_key: '155267350-72157721822193511' },
    { year: 2024, collection_key: '155267350-72157722837637259' }
  ];

  constructor(private mediaService: MediaService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.loadAlbum(this.tabs[5].collection_key);
    this.route.queryParams.subscribe((params) => {
      this.queryParam = params['year'];
    });
  }

  loadAlbum(key: string) {
    this.album = [];
    this.page = 1;
    this.mediaService.getAlbumTree().subscribe((result: object[]) => {
      result.map((res) => {
        if (res['id'] === key) {
          this.collectionSet = res['set'];
          this.collectionSet.map((el) => {
            this.mediaService.getAlbum(el['id'], this.page, this.perPage).subscribe((data) => {
              this.numberPagesInPhotoset = data['pages'];
              data['photo'].map((x) => {
                this.album.push(x);
              });
            });
          });
        }
      });
    });
  }

  pageNow(e) {
    if ((e==this.queryParam)||((this.queryParam==undefined)&&(e=='2024'))) return true
    else return false;
  }

  loadMore() {
    if (this.page >= this.numberPagesInPhotoset) return;
    this.page++;
    this.collectionSet.map((el) => {
      this.mediaService.getAlbum(el['id'], this.page, this.perPage).subscribe((data) => {
        data['photo'].map((x) => {
          this.album.push(x);
        });
      });
    });
  }

  onTabChange(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.loadAlbum(this.tabs[0].collection_key);
        break;
      case 1:
        this.loadAlbum(this.tabs[1].collection_key);
        break;
      case 2:
        this.loadAlbum(this.tabs[2].collection_key);
        break;
      case 3:
        this.loadAlbum(this.tabs[3].collection_key);
        break;
      case 4:
        this.loadAlbum(this.tabs[4].collection_key);
        break;
      case 5:
        this.loadAlbum(this.tabs[5].collection_key);
        break;
    }
  }
}
