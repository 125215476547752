import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PersonService } from '../../swagger/services/person.service';
import { Location } from '@angular/common';
import { Person } from '../../swagger/models/person';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ExpertPopupComponent } from '../../components/ui/popup/expert-popup/expert-popup.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-all-experts',
  templateUrl: './all-experts.component.html',
  styleUrls: ['./all-experts.component.scss'],
})
export class AllExpertsComponent implements OnInit {
  API_URL = environment.api_url;
  exhibition = environment.exhibition_id;
  themeId = environment.themeId;
  experts$: Observable<Person[]>;

  constructor(private personService: PersonService, private location: Location, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.experts$ = this.personService.personListList({ theme: this.themeId }).pipe(
      map((experts) => {
        experts.sort((a, b) => {
          if (a.secondname < b.secondname) {
            return -1;
          }
        });
        return experts;
      })
    );
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  openPopup(expert: Person) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = expert;
    dialogConfig.panelClass = 'expert-popup';
    let dialogRef = this.dialog.open(ExpertPopupComponent, dialogConfig);
  }

  backButton(): void {
    document.location.href=('');
  }
}
