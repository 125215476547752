<mat-dialog-content class="popup">
    <button mat-dialog-close class="mat-button-close">
        <svg enable-background="new 0 0 512.001 512.001" version="1.1" viewBox="0 0 512 512" xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m284.29 256 221.86-221.86c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0l-221.86 221.86-221.86-221.86c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.86 221.86-221.86 221.86c-7.811 7.811-7.811 20.475 0 28.285 3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857l221.86-221.86 221.86 221.86c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285l-221.86-221.86z" />
        </svg>
    </button>
    <div class="popup-content">
        Посмотреть программу мероприятий в рамках выставки ПИР Экспо можно будет начиная с <b>15 августа</b>. <br>Следите
        за&nbsp;нашими
        новостями в&nbsp;наших социальных сетях( <a class="popup-link" rel="noopener" href="https://vk.com/moscowcakeshow"
            target="_blank">ВК</a>, <a class="popup-link" rel="noopener" href="https://zen.yandex.ru/moscowcakeshow"
            target="_blank">Дзен</a>, <a class="popup-link" rel="noopener" href="https://ok.ru/group/64253252862200"
            target="_blank">ОК</a>) и <a class="popup-link" rel="noopener"
            href="https://cp.unisender.com/ru/v5/subscribe-form/view/6q1mzwzrzpxackatkcrwmtdisme6b3tjcs34m6na"
            target="_blank">рассылке</a>
    </div>
</mat-dialog-content>