<div class="ticket-price-block" [id]="{'ticket-price-block--active': active}"  [ngClass]="{ 'ticket-price-block--active': active, 'ticket-price-block__aside-mobile': asideOnMobile }">
  <button class="ticket-price-block__button" data-pirexpo-widget-shop-id="2" *ngIf="active" id="pirexpo-ticket-widget">Купить билеты</button>
  <div class="ticket-price-block__text">{{ text }}</div>
  <div
    class="ticket-price-block__img"
    [ngClass]="{
      price1: price === 'price1',
      price2: price === 'price2',
      price3: price === 'price3',
      price4: price === 'price4',
      price5: price === 'price5',
      'price--old': old,
      'ticket-price-block__aside-mobile': asideOnMobile
    }"
  ></div>
</div>
