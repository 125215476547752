/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CashboxOrder } from '../models/cashbox-order';
import { CashboxOrderCreate } from '../models/cashbox-order-create';
import { CashboxTicketExchangeResponse } from '../models/cashbox-ticket-exchange-response';
import { CashboxTicketExchangeRequest } from '../models/cashbox-ticket-exchange-request';
import { CashboxOrderAddTicket } from '../models/cashbox-order-add-ticket';
import { CashboxOrderRemoveTicket } from '../models/cashbox-order-remove-ticket';
@Injectable({
  providedIn: 'root',
})
class CashboxService extends __BaseService {
  static readonly cashboxEventListListPath = '/cashbox/event/list';
  static readonly cashboxOrderCreateCreatePath = '/cashbox/order/create';
  static readonly cashboxOrderTicketExchangeCreatePath = '/cashbox/order/ticket_exchange';
  static readonly cashboxOrderAddTicketCreatePath = '/cashbox/order/{id}/add_ticket';
  static readonly cashboxOrderCancelCreatePath = '/cashbox/order/{id}/cancel';
  static readonly cashboxOrderConfirmPaymentCreatePath = '/cashbox/order/{id}/confirm_payment';
  static readonly cashboxOrderRemoveTicketDeletePath = '/cashbox/order/{id}/remove_ticket';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CashboxService.CashboxEventListListParams` containing the following parameters:
   *
   * - `theme_id`: theme ids comma separated
   *
   * - `event_id`: event id
   */
  cashboxEventListListResponse(params: CashboxService.CashboxEventListListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.themeId != null) __params = __params.set('theme_id', params.themeId.toString());
    if (params.eventId != null) __params = __params.set('event_id', params.eventId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cashbox/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CashboxService.CashboxEventListListParams` containing the following parameters:
   *
   * - `theme_id`: theme ids comma separated
   *
   * - `event_id`: event id
   */
  cashboxEventListList(params: CashboxService.CashboxEventListListParams): __Observable<null> {
    return this.cashboxEventListListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  cashboxOrderCreateCreateResponse(data: CashboxOrderCreate): __Observable<__StrictHttpResponse<CashboxOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cashbox/order/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CashboxOrder>;
      })
    );
  }
  /**
   * @param data undefined
   */
  cashboxOrderCreateCreate(data: CashboxOrderCreate): __Observable<CashboxOrder> {
    return this.cashboxOrderCreateCreateResponse(data).pipe(
      __map(_r => _r.body as CashboxOrder)
    );
  }

  /**
   * @param data undefined
   */
  cashboxOrderTicketExchangeCreateResponse(data: CashboxTicketExchangeRequest): __Observable<__StrictHttpResponse<CashboxTicketExchangeResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cashbox/order/ticket_exchange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CashboxTicketExchangeResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  cashboxOrderTicketExchangeCreate(data: CashboxTicketExchangeRequest): __Observable<CashboxTicketExchangeResponse> {
    return this.cashboxOrderTicketExchangeCreateResponse(data).pipe(
      __map(_r => _r.body as CashboxTicketExchangeResponse)
    );
  }

  /**
   * @param params The `CashboxService.CashboxOrderAddTicketCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  cashboxOrderAddTicketCreateResponse(params: CashboxService.CashboxOrderAddTicketCreateParams): __Observable<__StrictHttpResponse<CashboxOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cashbox/order/${encodeURIComponent(String(params.id))}/add_ticket`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CashboxOrder>;
      })
    );
  }
  /**
   * @param params The `CashboxService.CashboxOrderAddTicketCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  cashboxOrderAddTicketCreate(params: CashboxService.CashboxOrderAddTicketCreateParams): __Observable<CashboxOrder> {
    return this.cashboxOrderAddTicketCreateResponse(params).pipe(
      __map(_r => _r.body as CashboxOrder)
    );
  }

  /**
   * @param id undefined
   */
  cashboxOrderCancelCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cashbox/order/${encodeURIComponent(String(id))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  cashboxOrderCancelCreate(id: string): __Observable<null> {
    return this.cashboxOrderCancelCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CashboxService.CashboxOrderConfirmPaymentCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `payment_type`: payment_type valid 1,2 or 3
   */
  cashboxOrderConfirmPaymentCreateResponse(params: CashboxService.CashboxOrderConfirmPaymentCreateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.paymentType != null) __params = __params.set('payment_type', params.paymentType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cashbox/order/${encodeURIComponent(String(params.id))}/confirm_payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CashboxService.CashboxOrderConfirmPaymentCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `payment_type`: payment_type valid 1,2 or 3
   */
  cashboxOrderConfirmPaymentCreate(params: CashboxService.CashboxOrderConfirmPaymentCreateParams): __Observable<null> {
    return this.cashboxOrderConfirmPaymentCreateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CashboxService.CashboxOrderRemoveTicketDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  cashboxOrderRemoveTicketDeleteResponse(params: CashboxService.CashboxOrderRemoveTicketDeleteParams): __Observable<__StrictHttpResponse<CashboxOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/cashbox/order/${encodeURIComponent(String(params.id))}/remove_ticket`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CashboxOrder>;
      })
    );
  }
  /**
   * @param params The `CashboxService.CashboxOrderRemoveTicketDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  cashboxOrderRemoveTicketDelete(params: CashboxService.CashboxOrderRemoveTicketDeleteParams): __Observable<CashboxOrder> {
    return this.cashboxOrderRemoveTicketDeleteResponse(params).pipe(
      __map(_r => _r.body as CashboxOrder)
    );
  }
}

module CashboxService {

  /**
   * Parameters for cashboxEventListList
   */
  export interface CashboxEventListListParams {

    /**
     * theme ids comma separated
     */
    themeId?: string;

    /**
     * event id
     */
    eventId?: number;
  }

  /**
   * Parameters for cashboxOrderAddTicketCreate
   */
  export interface CashboxOrderAddTicketCreateParams {
    id: string;
    data: CashboxOrderAddTicket;
  }

  /**
   * Parameters for cashboxOrderConfirmPaymentCreate
   */
  export interface CashboxOrderConfirmPaymentCreateParams {
    id: string;

    /**
     * payment_type valid 1,2 or 3
     */
    paymentType?: number;
  }

  /**
   * Parameters for cashboxOrderRemoveTicketDelete
   */
  export interface CashboxOrderRemoveTicketDeleteParams {
    id: string;
    data: CashboxOrderRemoveTicket;
  }
}

export { CashboxService }
