/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Company } from '../models/company';
import { CompanyDetail } from '../models/company-detail';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly companyListListPath = '/company/list';
  static readonly companyListCreatePath = '/company/list';
  static readonly companyReadPath = '/company/{id}';
  static readonly companyUpdatePath = '/company/{id}';
  static readonly companyPartialUpdatePath = '/company/{id}';
  static readonly companyDeletePath = '/company/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CompanyService.CompanyListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  companyListListResponse(params: CompanyService.CompanyListListParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/company/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `CompanyService.CompanyListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  companyListList(params: CompanyService.CompanyListListParams): __Observable<Array<Company>> {
    return this.companyListListResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param data undefined
   */
  companyListCreateResponse(data: Company): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/company/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * @param data undefined
   */
  companyListCreate(data: Company): __Observable<Company> {
    return this.companyListCreateResponse(data).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * @param id undefined
   */
  companyReadResponse(id: string): __Observable<__StrictHttpResponse<CompanyDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/company/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  companyRead(id: string): __Observable<CompanyDetail> {
    return this.companyReadResponse(id).pipe(
      __map(_r => _r.body as CompanyDetail)
    );
  }

  /**
   * @param params The `CompanyService.CompanyUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  companyUpdateResponse(params: CompanyService.CompanyUpdateParams): __Observable<__StrictHttpResponse<CompanyDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDetail>;
      })
    );
  }
  /**
   * @param params The `CompanyService.CompanyUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  companyUpdate(params: CompanyService.CompanyUpdateParams): __Observable<CompanyDetail> {
    return this.companyUpdateResponse(params).pipe(
      __map(_r => _r.body as CompanyDetail)
    );
  }

  /**
   * @param params The `CompanyService.CompanyPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  companyPartialUpdateResponse(params: CompanyService.CompanyPartialUpdateParams): __Observable<__StrictHttpResponse<CompanyDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/company/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyDetail>;
      })
    );
  }
  /**
   * @param params The `CompanyService.CompanyPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  companyPartialUpdate(params: CompanyService.CompanyPartialUpdateParams): __Observable<CompanyDetail> {
    return this.companyPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as CompanyDetail)
    );
  }

  /**
   * @param id undefined
   */
  companyDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/company/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  companyDelete(id: string): __Observable<null> {
    return this.companyDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CompanyService {

  /**
   * Parameters for companyListList
   */
  export interface CompanyListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for companyUpdate
   */
  export interface CompanyUpdateParams {
    id: string;
    data: CompanyDetail;
  }

  /**
   * Parameters for companyPartialUpdate
   */
  export interface CompanyPartialUpdateParams {
    id: string;
    data: CompanyDetail;
  }
}

export { CompanyService }
