/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { UserCreate } from '../models/user-create';
import { Activation } from '../models/activation';
import { SendEmailReset } from '../models/send-email-reset';
import { PasswordResetConfirm } from '../models/password-reset-confirm';
import { UsernameResetConfirm } from '../models/username-reset-confirm';
import { SetPassword } from '../models/set-password';
import { SetUsername } from '../models/set-username';
@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly authUsersListPath = '/auth/users/';
  static readonly authUsersCreatePath = '/auth/users/';
  static readonly authUsersActivationPath = '/auth/users/activation/';
  static readonly authUsersMeReadPath = '/auth/users/me/';
  static readonly authUsersMeUpdatePath = '/auth/users/me/';
  static readonly authUsersMePartialUpdatePath = '/auth/users/me/';
  static readonly authUsersMeDeletePath = '/auth/users/me/';
  static readonly authUsersResendActivationPath = '/auth/users/resend_activation/';
  static readonly authUsersResetPasswordPath = '/auth/users/reset_password/';
  static readonly authUsersResetPasswordConfirmPath = '/auth/users/reset_password_confirm/';
  static readonly authUsersResetUsernamePath = '/auth/users/reset_username/';
  static readonly authUsersResetUsernameConfirmPath = '/auth/users/reset_username_confirm/';
  static readonly authUsersSetPasswordPath = '/auth/users/set_password/';
  static readonly authUsersSetUsernamePath = '/auth/users/set_username/';
  static readonly authUsersReadPath = '/auth/users/{id}/';
  static readonly authUsersUpdatePath = '/auth/users/{id}/';
  static readonly authUsersPartialUpdatePath = '/auth/users/{id}/';
  static readonly authUsersDeletePath = '/auth/users/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AuthService.AuthUsersListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  authUsersListResponse(params: AuthService.AuthUsersListParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `AuthService.AuthUsersListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  authUsersList(params: AuthService.AuthUsersListParams): __Observable<Array<User>> {
    return this.authUsersListResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param data undefined
   */
  authUsersCreateResponse(data: UserCreate): __Observable<__StrictHttpResponse<UserCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersCreate(data: UserCreate): __Observable<UserCreate> {
    return this.authUsersCreateResponse(data).pipe(
      __map(_r => _r.body as UserCreate)
    );
  }

  /**
   * @param data undefined
   */
  authUsersActivationResponse(data: Activation): __Observable<__StrictHttpResponse<Activation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/activation/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Activation>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersActivation(data: Activation): __Observable<Activation> {
    return this.authUsersActivationResponse(data).pipe(
      __map(_r => _r.body as Activation)
    );
  }

  /**
   * @param params The `AuthService.AuthUsersMeReadParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  authUsersMeReadResponse(params: AuthService.AuthUsersMeReadParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param params The `AuthService.AuthUsersMeReadParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  authUsersMeRead(params: AuthService.AuthUsersMeReadParams): __Observable<Array<User>> {
    return this.authUsersMeReadResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param data undefined
   */
  authUsersMeUpdateResponse(data: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/auth/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersMeUpdate(data: User): __Observable<User> {
    return this.authUsersMeUpdateResponse(data).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param data undefined
   */
  authUsersMePartialUpdateResponse(data: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/auth/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersMePartialUpdate(data: User): __Observable<User> {
    return this.authUsersMePartialUpdateResponse(data).pipe(
      __map(_r => _r.body as User)
    );
  }
  authUsersMeDeleteResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/auth/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  authUsersMeDelete(): __Observable<null> {
    return this.authUsersMeDeleteResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  authUsersResendActivationResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/resend_activation/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersResendActivation(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.authUsersResendActivationResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  authUsersResetPasswordResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/reset_password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersResetPassword(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.authUsersResetPasswordResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  authUsersResetPasswordConfirmResponse(data: PasswordResetConfirm): __Observable<__StrictHttpResponse<PasswordResetConfirm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/reset_password_confirm/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PasswordResetConfirm>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersResetPasswordConfirm(data: PasswordResetConfirm): __Observable<PasswordResetConfirm> {
    return this.authUsersResetPasswordConfirmResponse(data).pipe(
      __map(_r => _r.body as PasswordResetConfirm)
    );
  }

  /**
   * @param data undefined
   */
  authUsersResetUsernameResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/reset_username/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersResetUsername(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.authUsersResetUsernameResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  authUsersResetUsernameConfirmResponse(data: UsernameResetConfirm): __Observable<__StrictHttpResponse<UsernameResetConfirm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/reset_username_confirm/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsernameResetConfirm>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersResetUsernameConfirm(data: UsernameResetConfirm): __Observable<UsernameResetConfirm> {
    return this.authUsersResetUsernameConfirmResponse(data).pipe(
      __map(_r => _r.body as UsernameResetConfirm)
    );
  }

  /**
   * @param data undefined
   */
  authUsersSetPasswordResponse(data: SetPassword): __Observable<__StrictHttpResponse<SetPassword>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/set_password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetPassword>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersSetPassword(data: SetPassword): __Observable<SetPassword> {
    return this.authUsersSetPasswordResponse(data).pipe(
      __map(_r => _r.body as SetPassword)
    );
  }

  /**
   * @param data undefined
   */
  authUsersSetUsernameResponse(data: SetUsername): __Observable<__StrictHttpResponse<SetUsername>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/users/set_username/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetUsername>;
      })
    );
  }
  /**
   * @param data undefined
   */
  authUsersSetUsername(data: SetUsername): __Observable<SetUsername> {
    return this.authUsersSetUsernameResponse(data).pipe(
      __map(_r => _r.body as SetUsername)
    );
  }

  /**
   * @param id A unique integer value identifying this user.
   */
  authUsersReadResponse(id: number): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/users/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this user.
   */
  authUsersRead(id: number): __Observable<User> {
    return this.authUsersReadResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `AuthService.AuthUsersUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  authUsersUpdateResponse(params: AuthService.AuthUsersUpdateParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/auth/users/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `AuthService.AuthUsersUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  authUsersUpdate(params: AuthService.AuthUsersUpdateParams): __Observable<User> {
    return this.authUsersUpdateResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `AuthService.AuthUsersPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  authUsersPartialUpdateResponse(params: AuthService.AuthUsersPartialUpdateParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/auth/users/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `AuthService.AuthUsersPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  authUsersPartialUpdate(params: AuthService.AuthUsersPartialUpdateParams): __Observable<User> {
    return this.authUsersPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param id A unique integer value identifying this user.
   */
  authUsersDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/auth/users/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this user.
   */
  authUsersDelete(id: number): __Observable<null> {
    return this.authUsersDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AuthService {

  /**
   * Parameters for authUsersList
   */
  export interface AuthUsersListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for authUsersMeRead
   */
  export interface AuthUsersMeReadParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for authUsersUpdate
   */
  export interface AuthUsersUpdateParams {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
    data: User;
  }

  /**
   * Parameters for authUsersPartialUpdate
   */
  export interface AuthUsersPartialUpdateParams {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
    data: User;
  }
}

export { AuthService }
