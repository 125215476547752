/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BoothEvent } from '../models/booth-event';
import { BoothEventCreate } from '../models/booth-event-create';
import { BoothEventScheduleBulk } from '../models/booth-event-schedule-bulk';
@Injectable({
  providedIn: 'root',
})
class BoothEventService extends __BaseService {
  static readonly boothEventListListPath = '/booth_event/list';
  static readonly boothEventListCreatePath = '/booth_event/list';
  static readonly boothEventReadPath = '/booth_event/{id}';
  static readonly boothEventUpdatePath = '/booth_event/{id}';
  static readonly boothEventPartialUpdatePath = '/booth_event/{id}';
  static readonly boothEventDeletePath = '/booth_event/{id}';
  static readonly boothEventSchedulePartialUpdatePath = '/booth_event/{id}/schedule';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoothEventService.BoothEventListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  boothEventListListResponse(params: BoothEventService.BoothEventListListParams): __Observable<__StrictHttpResponse<Array<BoothEvent>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BoothEvent>>;
      })
    );
  }
  /**
   * @param params The `BoothEventService.BoothEventListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  boothEventListList(params: BoothEventService.BoothEventListListParams): __Observable<Array<BoothEvent>> {
    return this.boothEventListListResponse(params).pipe(
      __map(_r => _r.body as Array<BoothEvent>)
    );
  }

  /**
   * @param data undefined
   */
  boothEventListCreateResponse(data: BoothEventCreate): __Observable<__StrictHttpResponse<BoothEventCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/booth_event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothEventCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  boothEventListCreate(data: BoothEventCreate): __Observable<BoothEventCreate> {
    return this.boothEventListCreateResponse(data).pipe(
      __map(_r => _r.body as BoothEventCreate)
    );
  }

  /**
   * @param id undefined
   */
  boothEventReadResponse(id: string): __Observable<__StrictHttpResponse<BoothEvent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/booth_event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothEvent>;
      })
    );
  }
  /**
   * @param id undefined
   */
  boothEventRead(id: string): __Observable<BoothEvent> {
    return this.boothEventReadResponse(id).pipe(
      __map(_r => _r.body as BoothEvent)
    );
  }

  /**
   * @param params The `BoothEventService.BoothEventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventUpdateResponse(params: BoothEventService.BoothEventUpdateParams): __Observable<__StrictHttpResponse<BoothEvent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/booth_event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothEvent>;
      })
    );
  }
  /**
   * @param params The `BoothEventService.BoothEventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventUpdate(params: BoothEventService.BoothEventUpdateParams): __Observable<BoothEvent> {
    return this.boothEventUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothEvent)
    );
  }

  /**
   * @param params The `BoothEventService.BoothEventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventPartialUpdateResponse(params: BoothEventService.BoothEventPartialUpdateParams): __Observable<__StrictHttpResponse<BoothEventCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/booth_event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoothEventCreate>;
      })
    );
  }
  /**
   * @param params The `BoothEventService.BoothEventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventPartialUpdate(params: BoothEventService.BoothEventPartialUpdateParams): __Observable<BoothEventCreate> {
    return this.boothEventPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as BoothEventCreate)
    );
  }

  /**
   * @param id undefined
   */
  boothEventDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/booth_event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  boothEventDelete(id: string): __Observable<null> {
    return this.boothEventDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `BoothEventService.BoothEventSchedulePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventSchedulePartialUpdateResponse(params: BoothEventService.BoothEventSchedulePartialUpdateParams): __Observable<__StrictHttpResponse<Array<BoothEventScheduleBulk>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/booth_event/${encodeURIComponent(String(params.id))}/schedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BoothEventScheduleBulk>>;
      })
    );
  }
  /**
   * @param params The `BoothEventService.BoothEventSchedulePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  boothEventSchedulePartialUpdate(params: BoothEventService.BoothEventSchedulePartialUpdateParams): __Observable<Array<BoothEventScheduleBulk>> {
    return this.boothEventSchedulePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Array<BoothEventScheduleBulk>)
    );
  }
}

module BoothEventService {

  /**
   * Parameters for boothEventListList
   */
  export interface BoothEventListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for boothEventUpdate
   */
  export interface BoothEventUpdateParams {
    id: string;
    data: BoothEvent;
  }

  /**
   * Parameters for boothEventPartialUpdate
   */
  export interface BoothEventPartialUpdateParams {
    id: string;
    data: BoothEventCreate;
  }

  /**
   * Parameters for boothEventSchedulePartialUpdate
   */
  export interface BoothEventSchedulePartialUpdateParams {
    id: string;
    data: Array<BoothEventScheduleBulk>;
  }
}

export { BoothEventService }
