<mat-dialog-content class="mat-typography popup">
    <button mat-dialog-close class="mat-button-close">
        <svg enable-background="new 0 0 512.001 512.001" version="1.1" viewBox="0 0 512 512" xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m284.29 256 221.86-221.86c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0l-221.86 221.86-221.86-221.86c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.86 221.86-221.86 221.86c-7.811 7.811-7.811 20.475 0 28.285 3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857l221.86-221.86 221.86 221.86c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285l-221.86-221.86z" />
        </svg>
    </button>
    <div class="popup-content">
        <div class="column">
            <p class="expert-name">{{expert.name}}</p>
            <p class="expert-status">{{expert.status}}</p>
            <p class="expert-info" [innerHTML]="expert.description"></p>
        </div>
        <div class="column">
            <div class="expert-img">
                <img *ngIf="expert.photo.length" loading="lazy" src="{{ expert.photo[0]['image'] }}"
                    alt="{{ expert.firstname }} {{ expert.secondname }}" />
            </div>
        </div>
        <div class="events" *ngIf="expert.event_speakers.length">
            <h3 class="events__title">
                Мероприятия
            </h3>
            <div *ngFor="let event of expert.event_speakers" class="event">
                <div class="dates">
                    {{ event.time_start | date: 'dd' :'ru'}}
                    мая
                    {{ event.time_start | date: 'HH:mm' :'ru'}}
                    -
                    {{ event.time_finish | date: 'HH:mm' :'ru'}}
                </div>
                <div class="name">
                    <b>{{ event.theme.name }}</b>
                </div>
                <div class="name">
                    {{ event['name'] }}
                </div>
            </div>
        </div>
        
        <!--<span>{{expert.event_speakers}}</span>-->
    </div>
</mat-dialog-content>