import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from '../../../../swagger/models/person';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-expert-popup',
  templateUrl: './expert-popup.component.html',
  styleUrls: ['./expert-popup.component.scss'],
})
export class ExpertPopupComponent implements OnInit {
  constructor(private http: HttpClient, public dialogRed: MatDialogRef<ExpertPopupComponent>, @Inject(MAT_DIALOG_DATA) public expert: Person) {}

  API_URL = environment.apiV2_url;
  themes = new Array;
  area_events: any;
  

  ngOnInit(): void {
    this.area_events = this.expert.event_speakers;
    this.area_events.forEach(element => {
      if (element.area_events)
        this.http.get(`${this.API_URL}/api/v1/area/${element.area_events[0]}`)
        .subscribe((response)=>{element.theme = response});
    });
  }
}

