import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ticket-price-block',
  templateUrl: './ticket-price-block.component.html',
  styleUrls: ['./ticket-price-block.component.scss'],
})
export class TicketPriceBlockComponent  {
  @Input() text: string;
  @Input() price: 'price1' | 'price2' | 'price3'| 'price4' | 'price5';
  @Input() old: boolean;
  @Input() active: boolean;
  @Input() asideOnMobile: boolean;
}
